import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const familyBiblioApi = createApi({
  reducerPath: "familyBiblioApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getFamilyBiblio: builder.query({
      query: ({ type, format, number }) =>
        `family-biblio/family/${type}/${format}/${number}/biblio`,
    }),
    createManyFamilyBiblio: builder.mutation({
      query: (data) => ({
        url: 'family-biblio',
        method: 'POST',
        body: data,
      }),
    }),
    getExtractedRefData: builder.query({
      query: ({ type, format, number }) =>
        `family-biblio/published-data/${type}/${format}/${number}/biblio`,
    }),
    fetchAuthToken: builder.query({
      query: () => `family-biblio/accesstoken`,
    }),
    getSavedFamilyBiblios: builder.query({
      query: () => `family-biblio`,
    }),
    getSavedFamilyByUser: builder.query({
      query: ({ userId, num }) => `family-biblio/saved-families/${userId}/${num}`,
      keepUnusedDataFor: 0
    }),
    deleteSavedFamilyByUser: builder.mutation({
      query: ({ userId, num }) => ({
        url: `family-biblio/saved-families-delete/${userId}/${num}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetFamilyBiblioQuery,
  useCreateManyFamilyBiblioMutation,
  useGetExtractedRefDataQuery,
  useFetchAuthTokenQuery,
  useLazyGetExtractedRefDataQuery,
  useGetSavedFamilyBibliosQuery,
  useGetSavedFamilyByUserQuery,
  useDeleteSavedFamilyByUserMutation
} = familyBiblioApi;

export default familyBiblioApi;
