import { React, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setCanAddRow } from "../../store/reducer/searchSlice";
import { setFormattedIdsSortedData } from "../../store/reducer/searchSlice";
import "./index.scss";

const Table = ({
  headers,
  data,
  renderAsHtml,
  handleAddRowIDSData,
  handleUpdateIDSRowData,
}) => {
  const dispatch = useDispatch();
  const canEdit = useSelector((state) => state.search.canEdit);
  const canAddRow = useSelector((state) => state.search.canAddRow);
  const isNPLFileUploaded = useSelector(
    (state) => state.search.isNPLFileUploaded
  );
  // Sorting on 'IDS Form Section Heading'
  const sortOrder = [
    "U.S.PATENTS",
    "U.S.PATENT APPLICATION PUBLICATIONS",
    "FOREIGN PATENT DOCUMENTS",
  ];

  // Unique rows
  const getUniqueData = (data) => {
    const seen = new Set();
    return data.filter((row) => {
      const uniqueIdentifier = JSON.stringify(
        Object.fromEntries(
          Object.entries(row).filter(
            ([key]) => key !== "Sr.No" && key !== "Serial #"
          )
        )
      );
      if (seen.has(uniqueIdentifier)) {
        return false;
      }

      seen.add(uniqueIdentifier);
      return true;
    });
  };

  // To sort data and update 'Sr.No'
  const sortData = (data) => {
    const uniqueData = getUniqueData(data);
    const sorted = uniqueData.sort((a, b) => {
      const indexA = sortOrder.indexOf(a["IDS Form Section Heading"]);
      const indexB = sortOrder.indexOf(b["IDS Form Section Heading"]);

      if (indexA !== indexB) {
        return indexA - indexB;
      }

      const refA = a["References"] ? parseInt(a["References"].slice(2), 10) : 0;
      const refB = b["References"] ? parseInt(b["References"].slice(2), 10) : 0;

      return refA - refB;
    });

    return sorted.map((item, index) => ({
      ...item,
      "Sr.No": index + 1,
    }));
  };
  const sortedData = data.length > 0 ? sortData([...data]) : [];
  useEffect(() => {
    if (canEdit === true) {
      var element = document.getElementById(`editable_span_0_References`);
      element.focus();
    }
  }, [canEdit]);
  useEffect(() => {
    if (sortedData.length > 0) {
      dispatch(setFormattedIdsSortedData(sortedData));
    }
  }, [dispatch]);

  const setCursorPosition = (rowIndex, header) => {
    if (canEdit === true) {
      var element = document.getElementById(
        `editable_span_${rowIndex}_${header}`
      );
      if (element.childNodes.length === 0) {
        const newInput = document.createElement("input");
        newInput.className = "editable-input";
        newInput.textContent = "";
        element.appendChild(newInput);
        element.focus();
      }
    }
  };

  const handleCellChange = (rowIndex, header, event) => {
    const newValue = event.target.textContent;
    const updatedData = sortedData.map((row, index) =>
      index === rowIndex ? { ...row, [header]: newValue } : row
    );
    handleUpdateIDSRowData(updatedData);
    handleAddRowIDSData([]);
    return;
  };
  const addBlankIDSRow = () => {
    const newRow = headers.reduce((acc, header) => {
      acc[header] = "";
      return acc;
    }, {});
    handleAddRowIDSData((prevData) => [...prevData, newRow]);
    dispatch(setCanAddRow(""));
    return;
  };
  useEffect(() => {
    if (canAddRow === "add-new-ids") {
      addBlankIDSRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddRow]);
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td
                  id={`editable_span_${rowIndex}_${header}`}
                  spellCheck="false"
                  key={colIndex}
                  dangerouslySetInnerHTML={
                    renderAsHtml && header === "Link"
                      ? { __html: row[header] }
                      : undefined
                  }
                  contentEditable={canEdit === true ? "true" : "false"}
                  onFocus={() => setCursorPosition(rowIndex, header)}
                  suppressContentEditableWarning={true}
                  onBlur={(event) => {
                    if (canEdit) handleCellChange(rowIndex, header, event);
                  }}
                >
                  {!renderAsHtml || header !== "Link" ? row[header] : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
