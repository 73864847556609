import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import Table from '../../common/references-table';
import './citation-matrix.scss';
import { formatDate, PRIORITY_ORDER, formatSavedDate } from '../../../util/utils';
import { setFormattedRefData } from '../../../store/reducer/referencesSlice';
import { useLazyGetExtractedRefDataQuery } from '../../../store/action/familyBiblioApi';
import { useGetSavedRefByUserQuery } from '../../../store/action/referencesApi';
import { highlightText } from '../../../utils/highlight';
import { useLocation } from "react-router-dom";
const CitationMatrix = ({ downloadReferences, searchTerm, selectedColumn }) => {
  const { state } = useLocation();
  const { number: pubNum } = state || {};
  const { familyBiblio } = useSelector((state) => state.family);
  const { extractedExcelData } = useSelector((state) => state.search);
  const { addRefData } = useSelector((state) => state.search);
  const number = useSelector((state) => state.search.number)
  const userNumber = sessionStorage.getItem("userId")
  const { data } = useGetSavedRefByUserQuery({ userId: userNumber, num: number !== '' ? number : pubNum })
  const savedReferencesArray = data ? [...data] : []
  const [addRowData, setAddRowData] = useState([])
  const [updateRowData, setUpdateRowData] = useState([])
  const formattedFamilyData = useSelector((state) => state.family.formattedFamilyData)
  const formattedSerialNumbers = formattedFamilyData.map((member) => `${member['Country Code']} ${member['plainSerial#']}`)
  const handleUpdateRowData = (data) => {
    setUpdateRowData(data)
  }
  const handleAddRowData = (data) => {
    setAddRowData(data)
  }
  const dispatch = useDispatch();

  const getHighestPriorityDocId = (docIds) => {
    if (!Array.isArray(docIds)) {
      return {};
    }
    const sortedDocIds = [...docIds].sort((a, b) => {
      const priorityA = PRIORITY_ORDER.indexOf(a['@document-id-type']);
      const priorityB = PRIORITY_ORDER.indexOf(b['@document-id-type']);
      return priorityA - priorityB;
    });
    return sortedDocIds[0] || {};
  };
  const additionalReferencesArray = addRefData ? addRefData.map((number) => {
    return number.slice(0, -2)
  }) : [];
  const additonalRefKindCodeData = addRefData ? addRefData.map((number) => {
    return number.slice(-2)
  }) : [];
  const additionalRefObject = addRefData.map((item, index) => ({
    reference: item,
    kind: additonalRefKindCodeData[index]
  }))
  const familyMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member'];

  const familyMembersArray = Array.isArray(familyMembers) ? familyMembers : (familyMembers ? [familyMembers] : []);

  const extractedFormattedExcelData = extractedExcelData ? extractedExcelData.map((number) => {
    return number.slice(0, -2)
  }) : [];
  const extractedKindCodeData = extractedExcelData ? extractedExcelData.map((number) => {
    return number.slice(-2)
  }) : [];
  const extractedExcelObject = extractedExcelData.map((item, index) => ({
    reference: item,
    kind: extractedKindCodeData[index]
  }))
  const [excelData, setExcelData] = useState([])
  const [addRef, setAddRef] = useState([])
  const [getExtractedRefData] = useLazyGetExtractedRefDataQuery()
  useEffect(() => {
    const fetchExtractedRefData = async () => {
      const requests = extractedFormattedExcelData.map(async (member) => {
        const { data } = await getExtractedRefData({ type: "publication", format: "docdb", number: member })
        return data;
      })
      try {
        const results = await Promise.all(requests);
        setExcelData(results);
      } catch (err) {
        console.error('Error fetching extracted references data:', err);
      }
    }
    const fetchAdditionalRefData = async () => {
      const requests = additionalReferencesArray.map(async (member) => {
        const { data } = await getExtractedRefData({ type: "publication", format: "docdb", number: member })
        return data;
      })
      try {
        await Promise.all(requests).then((results) => {
          setAddRef(results)
        })
      } catch (err) {
        console.error('Error fetching extracted references data:', err);
      }
    }
    fetchExtractedRefData()
    fetchAdditionalRefData()
  }, [])
  const formattedAddRefData = additionalRefObject.map((member, index) => {
    const addRefDataArray = addRef[index]?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]
    const addRefDataDetails = Array.isArray(addRefDataArray) ? addRefDataArray.filter((item) => item['@kind'] === member['kind']) : addRefDataArray

    const addRefDataFormatted = Array.isArray(addRefDataDetails) ? addRefDataDetails[0] : addRefDataDetails

    const applicationReference = addRefDataFormatted?.["bibliographic-data"]?.['application-reference']?.['document-id'] || [];

    const publicationRef = addRefDataFormatted?.["bibliographic-data"]?.['publication-reference']?.['document-id'] || [];

    const parties = addRefDataFormatted?.["bibliographic-data"]?.['parties'] || []

    const countryCode = addRefDataFormatted?.["@country"] || "";

    const inventors = parties?.['inventors']?.['inventor'] || [];

    const inventorObject = Array.isArray(inventors) === false ? inventors : {}

    const applicants = parties?.['applicants']?.['applicant'] || []

    const applicantObject = Array.isArray(applicants) === false ? applicants : {}

    const inventorName = Object.keys(inventorObject).length === 0 ? inventors?.find((doc) => doc['@sequence'] === '1' && doc['@data-format'] === 'original')?.['inventor-name']?.['name']?.["$"] : inventorObject?.['inventor-name']?.['name']?.["$"]

    const applicantName = Object.keys(applicantObject).length === 0 ? applicants?.find((doc) => doc['@sequence'] === '1' && doc['@data-format'] === 'original')?.['applicant-name']?.['name']?.["$"] : applicantObject?.['applicant-name']?.['name']?.["$"]

    const patenteeName = countryCode === "US" ? inventorName : applicantName

    const publicationDate = publicationRef?.find((doc) => doc["@document-id-type"] === "docdb")?.["date"]?.["$"];

    const formatPubDate = formatDate(publicationDate)

    const originalDocNumber = applicationReference?.find(
      (doc) => doc["@document-id-type"] === "docdb"
    )?.["doc-number"]?.["$"]

    return {
      "Selected": false,
      "Serial #": originalDocNumber || "N.A#",
      "References": member['reference'] || "N.A#",
      "Kind Code": member['kind'] || "N.A",
      "Issued / Pub. Date": formatPubDate || "N.A",
      "Patentee Name": patenteeName || "N.A",
    };

  })
  const formattedExcelData = extractedExcelObject.map((member, index) => {
    const excelDataArray = excelData[index]?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]
    const excelDataDetails = Array.isArray(excelDataArray) ? excelDataArray.filter((item) => item['@kind'] === member['kind']) : excelDataArray

    const excelDataFormatted = Array.isArray(excelDataDetails) ? excelDataDetails[0] : excelDataDetails

    const applicationReference = excelDataFormatted?.["bibliographic-data"]?.['application-reference']?.['document-id'] || [];

    const publicationRef = excelDataFormatted?.["bibliographic-data"]?.['publication-reference']?.['document-id'] || [];

    const parties = excelDataFormatted?.["bibliographic-data"]?.['parties'] || []

    const countryCode = excelDataFormatted?.["@country"] || "";

    const inventors = parties?.['inventors']?.['inventor'] || [];

    const inventorObject = Array.isArray(inventors) === false ? inventors : {}

    const applicants = parties?.['applicants']?.['applicant'] || []

    const applicantObject = Array.isArray(applicants) === false ? applicants : {}

    const inventorName = Object.keys(inventorObject).length === 0 ? inventors?.find((doc) => doc['@sequence'] === '1' && doc['@data-format'] === 'original')?.['inventor-name']?.['name']?.["$"] : inventorObject?.['inventor-name']?.['name']?.["$"]

    const applicantName = Object.keys(applicantObject).length === 0 ? applicants?.find((doc) => doc['@sequence'] === '1' && doc['@data-format'] === 'original')?.['applicant-name']?.['name']?.["$"] : applicantObject?.['applicant-name']?.['name']?.["$"]

    const patenteeName = countryCode === "US" ? inventorName : applicantName

    const publicationDate = publicationRef?.find((doc) => doc["@document-id-type"] === "docdb")?.["date"]?.["$"];

    const formatPubDate = formatDate(publicationDate)

    const originalDocNumber = applicationReference?.find(
      (doc) => doc["@document-id-type"] === "docdb"
    )?.["doc-number"]?.["$"]

    return {
      // "Serial #": originalDocNumber || "N.A#",
      "References": member['reference'] || "N.A#",
      "Kind Code": member['kind'] || "N.A",
      "Issued / Pub. Date": formatPubDate || "N.A",
      "Patentee Name": patenteeName || "N.A",
    };
  })
  const classifyPatentNumber = (patentNumber) => {
    const patentStr = patentNumber.toString().slice(0, -2).slice(2);
    if (patentStr.length === 10) {
      return "U.S.PATENT APPLICATION PUBLICATIONS"
    } else {
      return "U.S.PATENTS"
    }
  }
  const formattedData = useMemo(() => {
    const finalData = familyMembersArray.flatMap((member) => {
      const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited'];
      if (!referencesCited || !Array.isArray(referencesCited['citation'])) {
        return [];
      }

      const applicationReference = member['application-reference']?.['document-id'] || {};
      const applicationNumber = applicationReference['doc-number']?.['$'] || "N.A#";

      return referencesCited['citation'].map((citation) => {
        const docIds = Array.isArray(citation.patcit?.['document-id']) ? citation.patcit?.['document-id'] : [];
        const docId = getHighestPriorityDocId(docIds);

        const country = docId?.['country']?.['$'] || "";
        const docNumber = docId?.['doc-number']?.['$'] || "";
        const kind = docId?.['kind']?.['$'] || "";
        const publicationDate = formatDate(docId?.['date']?.['$'] || "");
        const publicationNumber = `${country}${docNumber}${kind}`;
        const publicationType = country === "US"
          ? classifyPatentNumber(publicationNumber)
          : "FOREIGN PATENT DOCUMENTS";
        return {
          "Selected": false,
          "Serial #": applicationNumber || "N.A#",
          "References": publicationNumber || "N.A#",
          "Kind Code": kind || "N.A",
          "Issued / Pub. Date": publicationDate || "N.A",
          "Patentee Name": docId?.['name']?.['$'] || "N.A",
          "Type": publicationType,
        };
      });
    });

    return finalData.filter(row => {
      return !(
        (row['References'] === 'N.A' || row['References'] === 'N.A#') &&
        (row['Kind Code'] === 'N.A' || row['Kind Code'] === 'N.A#') &&
        (row['Issued / Pub. Date'] === 'N.A' || row['Issued / Pub. Date'] === 'N.A#') &&
        (row['Patentee Name'] === 'N.A' || row['Patentee Name'] === 'N.A#')
      );
    });
  }, [familyMembersArray]);

  const formattedSavedReferencesData = savedReferencesArray.map((member) => {
    return {
      "Selected": false,
      "Serial #": member.serial_num,
      "References": member.ref_num,
      "Kind Code": member.kind_code,
      "Issued / Pub. Date": formatSavedDate(member.ip_date),
      "Patentee Name": member.patentee_name,
      "Type": member.ref_num.slice(0, 2) === "US"
        ? classifyPatentNumber(member.ref_num)
        : "FOREIGN PATENT DOCUMENTS",
    };
  })
  const headers = [
    "Sr.No",
    "References",
    "Kind Code",
    "Issued / Pub. Date",
    "Patentee Name",
    ...formattedSerialNumbers
    // "Serial #",
  ];
  const combinedData = updateRowData.length > 0 ? [...updateRowData] : formattedSavedReferencesData.length > 0 ? [...formattedSavedReferencesData, ...addRowData] : [...formattedData, ...formattedAddRefData, ...formattedExcelData, ...addRowData];
  const highlightedData = combinedData.map((item, index) => ({
    ...item,
    "Sr.No": index + 1,
    "References": highlightText(item["References"] || '', searchTerm, selectedColumn, 'Serial #'),
  }));

  useEffect(() => {
    dispatch(setFormattedRefData(highlightedData));
  }, [highlightedData, updateRowData, dispatch]);

  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const defaultData = Array.from({ length: 10 }, () => defaultRow);
  return (
    <div className="citation-matrix">
      <Table headers={headers} formattedData={highlightedData.length > 0 ? highlightedData : defaultData} handleUserRowData={handleAddRowData} handleUpdateRowData={handleUpdateRowData} downloadReferences={downloadReferences} />
    </div>
  );
};

export default CitationMatrix;