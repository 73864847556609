import React from "react";

const RsrMarkSection = () => {
  return (
    <>
      <div className="d-flex flex-column email-content-section align-items-start">
        <div className="content-text">
          If the mark is not currently being in association with the goods
          without an acceptable excuse for non-use, we cannot renew this
          registration.
        </div>
        <div className="content-text">
          If the mark is in use in interstate commerce and the registration is
          to be renewed, we will need a specimen illustrating the manner in
          which the mark is used in conjunction with at the goods. A copy of the
          specimen that was filed with the Combined Declaration of Use and/or
          Excusable Nonuse/Application for Renewal of Registration of a Mark
          under Sections 8 & 9 is attached for your information.
        </div>
      </div>
    </>
  );
};

export default RsrMarkSection;
