import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./nafd.scss";
import NafdParamSection from "../../../components/RL/nafd-sections/nafd-param-section";
import NafdEmailSection from "../../../components/RL/nafd-sections/nafd-email-section";

const NAFD = () => {
  return (
    <div className="nafd-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">
          New Application Filing Direct Client
        </div>
        <div className="d-flex flex-row">
          <NafdParamSection />
          <NafdEmailSection />
        </div>
      </div>
    </div>
  );
};

export default NAFD;
