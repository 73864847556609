export const highlightText = (text, searchTerm, selectedColumn, columnName) => {
    if (!searchTerm || selectedColumn !== columnName) return text;
    
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} className="highlight">{part}</span>
      ) : (
        part
      )
    );
  };