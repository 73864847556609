import React from "react";

const RtrSpecimenSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <span className="content-text">
          Acceptable specimens include:
          <br></br>
          a) Tags or labels bearing the trademark which are affixed directly to
          the goods, to the packaging in which the goods are sold; or
          <br></br>
          b) Photographs of the goods bearing the trademark, or photographs of
          the packaging bearing the trademark; or
          <br></br>
          c) A webpage that displays the product and (1) contains a picture,
          photograph, or textual description of the identified goods, (2) shows
          the mark in association with the goods, and (3) provides a means for
          ordering the identified goods.
        </span>
      </div>
    </>
  );
};

export default RtrSpecimenSection;
