import React from "react";
import Button from "../../common/button";
import { useDispatch, useSelector } from "react-redux";
import { setIsEditLetterPopupOpen } from "../../../store/reducer/createLetterSlice";
import EditLetterPopup from "../popup/edit-letter-popup";

const RLTemplateParamButtons = (props) => {
  const { isLoading, handleFetch, handleSave } = props;
  const dispatch = useDispatch();
  const isEditLetterPopupOpen = useSelector(
    (state) => state.createLetter.isEditLetterPopupOpen
  );
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleEditLetter = () => {
    handleScrollToTop();
    dispatch(setIsEditLetterPopupOpen(true));
  };

  const onCloseEditLetterPopup = () => {
    dispatch(setIsEditLetterPopupOpen(false));
  };

  return (
    <>
      <div className="d-flex px-2 py-3 flex-column align-items-center param-btn-container">
        <div className="d-flex flex-row ref-save-container">
          <Button className={`param-btn mx-1`} onClick={handleFetch}>
            {isLoading === true ? "Refreshing" : "Refresh"}
          </Button>
          <Button className={`param-btn mx-1`} onClick={handleEditLetter}>
            {"Edit"}
          </Button>
        </div>
        {/* <div className="d-flex flex-row edit-btn-container mt-1 ms-2">
          <Button className={`param-edit-btn`} onClick={handleEditLetter}>
            {"Edit"}
          </Button>
        </div> */}
      </div>
      {isEditLetterPopupOpen === true && (
        <EditLetterPopup onClose={onCloseEditLetterPopup} />
      )}
    </>
  );
};

export default RLTemplateParamButtons;
