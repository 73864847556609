export const generateEML = async (
  contentRef,
  caseTemplate,
  templateFiles,
  templateSignFiles
) => {
  const element = contentRef.current;

  const encodeFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const addAttachments = async (files) => {
    let attachmentsContent = "";

    for (const file of files) {
      const base64File = await encodeFileToBase64(file);

      const attachmentHeaders = `${boundaryDelimiter}\r\nContent-Type: ${file.type}; name="${file.name}"\r\nContent-Transfer-Encoding: base64\r\nContent-Disposition: attachment; filename="${file.name}"\r\n\r\n`;

      attachmentsContent += attachmentHeaders + base64File + "\r\n";
    }

    return attachmentsContent;
  };

  const highlightedElements = element.querySelectorAll(
    ".highlight-field-class"
  );
  const tableElements = element.querySelectorAll(".table");

  highlightedElements.forEach((el) => {
    el.classList.remove("highlight-field-class");
  });

  if (tableElements) {
    tableElements.forEach((el) => {
      el.classList.remove("table");
      el.classList.remove("table-bordered");
    });
  }

  const excludedElements = element.querySelectorAll(".exclude-from-pdf");

  const excludedCopyImage = element.querySelectorAll(".copy-icon-container");

  const targetDiv = element.querySelector(".last-text-section");

  const targetCopyDiv = element.querySelector(".sub-cert-section");

  const excludedElementFiles = element.querySelectorAll(
    ".exclude-from-pdf-files"
  );

  excludedElements.forEach((el) => {
    el.remove();
  });

  excludedElementFiles.forEach((el) => {
    el.remove();
  });

  excludedCopyImage.forEach((el) => {
    el.remove();
  });

  const elementsToReAdd = Array.from(excludedElements);

  const elementsToReAddFile = Array.from(excludedElementFiles);

  const elementsToReAddImage = Array.from(excludedCopyImage);

  const htmlTemplateContent = element.innerHTML;

  const headers = [
    "From: example@example.com",
    "To: recipient@example.com",
    `Subject:${caseTemplate}`,
    "Content-Type: multipart/mixed; boundary=Eml_Boundary",
    "MIME-Version: 1.0",
  ];

  const boundary = "Eml_Boundary";
  const boundaryDelimiter = "--" + boundary;
  const boundaryEndDelimiter = "--" + boundary + "--";

  const bodyContent = `${boundaryDelimiter}\r\nContent-Type: text/html; charset=UTF-8\r\nContent-Transfer-Encoding: 7bit\r\n\r\n${htmlTemplateContent}`;

  const addAttachmentsContent = templateFiles
    ? await addAttachments(templateFiles)
    : "";
  const addAttachmentSignContent = templateSignFiles
    ? await addAttachments(templateSignFiles)
    : "";

  const emlContent =
    headers.join("\r\n") +
    "\r\n\r\n" +
    bodyContent +
    "\r\n\r\n" +
    addAttachmentsContent +
    "\r\n\r\n" +
    addAttachmentSignContent +
    `\r\n${boundaryEndDelimiter}\r\n`;

  const blob = new Blob([emlContent], { type: "message/rfc822" });

  setTimeout(() => {
    highlightedElements.forEach((el) => {
      el.classList.add("highlight-field-class");
    });
    elementsToReAdd.forEach((el) => {
      element.appendChild(el);
    });
    elementsToReAddFile.forEach((el) => {
      targetDiv.appendChild(el);
    });
    elementsToReAddImage.forEach((el) => {
      targetCopyDiv.appendChild(el);
    });
    if (tableElements) {
      tableElements.forEach((el) => {
        el.classList.add("table");
        el.classList.add("table-bordered");
      });
    }
  }, 2000);

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "email.eml";

  link.click();
};
