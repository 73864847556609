import React, { useState, useEffect, useRef } from "react";
import Button from "../../common/button";
import Input from "../../common/input";
import { useDispatch, useSelector } from "react-redux";
import { saveRlTemplateData } from "../../../store/reducer/rlAdminSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { setIsDownloadPopupOpen } from "../../../store/reducer/templateSlice";
import { handleDownloadWord } from "../common/download-docx";
import { generateEML } from "../common/download-eml";

const RlTemplateButtons = (props) => {
  const {
    handleDiscard,
    BackImage,
    UploadImage,
    handleSignatureFileChange,
    handleFileChange,
    handleDownloadPdf,
    SendImage,
    SaveImage,
    contentRef,
    buttonRef,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const caseClient = useSelector((state) => state.createLetter.caseClient);
  const caseTemplate = useSelector((state) => state.createLetter.caseTemplate);
  const caseNumber = useSelector((state) => state.createLetter.caseNumber);
  const caseNumberType = useSelector(
    (state) => state.createLetter.caseNumberType
  );
  const userNumber = parseInt(sessionStorage.getItem("userId"), 10);
  const templateFiles = useSelector((state) => state.template.templateFiles);
  const templateSignFiles = useSelector(
    (state) => state.template.templateSignFiles
  );
  const optionRef = useRef(null);
  const [isDownloadOpen, setDownloadOpen] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSaveEmail = async () => {
    const currDate = new Date().toISOString();
    const emailSaveObject = {
      ref_id: caseNumber,
      client_name: caseClient,
      template: caseTemplate,
      number_type: caseNumberType,
      curr_date_time: currDate,
      userId: userNumber,
    };
    try {
      dispatch(saveRlTemplateData(emailSaveObject));
      alert("Email Saved Successfully.");
      navigate("/rl-dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = () => {
    setDownloadOpen(true);
  };

  const handlePdfClick = () => {
    setDownloadOpen(false);
    handleDownloadPdf(contentRef, caseTemplate);
  };

  const handleWordClick = () => {
    setDownloadOpen(false);
    handleDownloadWord(contentRef, caseTemplate);
  };

  const handleEmlClick = () => {
    setDownloadOpen(false);
    generateEML(contentRef, caseTemplate, templateFiles, templateSignFiles);
  };

  const handleOutsideOption = (event) => {
    if (optionRef.current && !optionRef.current.contains(event.target)) {
      setDownloadOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideOption);

    return () => {
      document.removeEventListener("click", handleOutsideOption);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`d-flex flex-row email-btn-section align-items-center pt-2 justify-content-between exclude-from-pdf ${
        pathname.includes("/aer") || pathname.includes("/sgp")
          ? "aer-margin"
          : ""
      }`}
      ref={buttonRef}
    >
      <div className="d-flex flex-row">
        <div>
          <Button className={`email-btn-class mx-1`} onClick={handleDiscard}>
            <div className="d-flex flex-row align-items-center">
              <img src={BackImage} className="email-btn-img mx-1" alt="Back" />
              Discard
            </div>
          </Button>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-row">
          <div className="mx-1">
            <div className="template-file-wrapper">
              <Button className={`email-btn-class mx-1`}>
                <div className="d-flex flex-row align-items-center">
                  <img
                    src={UploadImage}
                    className="email-btn-img mx-1"
                    alt="Upload"
                  />
                  <span className="file-name-span">{"Attach Signature"}</span>
                </div>
              </Button>
              <Input
                type="file"
                id="template-attach-signature-file"
                className={`template-attach-input`}
                onChange={handleSignatureFileChange}
                accept=".rtf,.txt"
              />
            </div>
          </div>
          <div className="mx-1">
            <div className="template-file-wrapper">
              <Button className={`email-btn-class mx-1`}>
                <div className="d-flex flex-row align-items-center">
                  <img
                    src={UploadImage}
                    className="email-btn-img mx-1"
                    alt="Upload"
                  />
                  Attach file
                </div>
              </Button>
              <Input
                type="file"
                id="template-attach-file"
                className={`template-attach-input`}
                onChange={handleFileChange}
                accept=".pdf, application/pdf"
              />
            </div>
          </div>
          <div className="mx-1" ref={optionRef}>
            {isDownloadOpen && (
              <div className="d-flex flex-column align-items-start download-options px-1 py-1">
                <div className="download-option" onClick={handlePdfClick}>
                  Download PDF
                </div>
                <div className="download-option" onClick={handleWordClick}>
                  Download Word
                </div>
                <div className="download-option" onClick={handleEmlClick}>
                  Download EML
                </div>
              </div>
            )}
            <Button className={`email-btn-class mx-1`} onClick={handleDownload}>
              <div className="d-flex flex-row align-items-center">
                <img
                  src={SendImage}
                  className="email-btn-img download-img mx-1"
                  alt="Send"
                />
                Download email
              </div>
            </Button>
          </div>
          <div className="mx-1">
            <Button
              className={`email-btn-class mx-1`}
              onClick={handleSaveEmail}
            >
              <div className="d-flex flex-row align-items-center">
                <img
                  src={SaveImage}
                  className="email-btn-img mx-1"
                  alt="Save"
                />
                Save email
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RlTemplateButtons;
