import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/header/Header";
import Login from "./pages/IDS/Login/login";
import Searchpage from "./pages/IDS/Search/search";
import PatentView from "./pages/IDS/PatentView/patent-view";
import Dashboard from "./pages/IDS/Dashboard/dashboard";
import Welcome from "./pages/RL/Welcome/welcome";
import PrivateRoutes from "./PrivateRoutes";
import RLDashboard from "./pages/RL/RL-Dashboard/rl-dashboard";
import CIR from "./pages/RL/CIR/cir";
import NAF from "./pages/RL/NAF/naf";
import AER from "./pages/RL/AER/aer";
import SGP from "./pages/RL/SGP/sgp";
import UCR from "./pages/RL/UCR/ucr";
import NAFA from "./pages/RL/NAFA/nafa";
import NAFD from "./pages/RL/NAFD/nafd";
import RTR from "./pages/RL/RTR/rtr";
import RSR from "./pages/RL/RSR/rsr";

const App = () => {
  const isAuthenticate = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken") ? true : false;
  return (
    <Router>
      <div className="app">
        <Header isAuthenticate={isAuthenticate} userName="John Doe" />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/search" element={<Searchpage />} />
            <Route path="/history" element={<></>} />
            <Route path="/ids" element={<PatentView />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/rl-dashboard" element={<RLDashboard />} />
            <Route path="/aer" element={<AER />} />
            <Route path="/cir" element={<CIR />} />
            <Route path="/naf" element={<NAF />} />
            <Route path="/nafa" element={<NAFA />} />
            <Route path="/nafd" element={<NAFD />} />
            <Route path="/rtr" element={<RTR />} />
            <Route path="/rsr" element={<RSR />} />
            <Route path="/sgp" element={<SGP />} />
            <Route path="/ucr" element={<UCR />} />
          </Route>
          <Route path="*" element={<Navigate to={isAuthenticate ? "/welcome" : "/login"} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
