import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetFamilyBiblioQuery } from "../../store/action/familyBiblioApi";
import { setFamilyBiblio, setLoading, setError } from '../../store/reducer/familySlice';
import InitialReferences from '../../components/patent-view/initial-ref/initial-ref';
import FamilyInformation from '../../components/patent-view/family-info-section/family-information';
import CitationMatrix from '../../components/patent-view/citation-matrix/citation-matrix';
import SB08FormDetails from '../../components/patent-view/SB08-details/details-tab';
import TableEdit from "../../assets/images/table-edit-icon.png"
import TableAdd from "../../assets/images/table-add-icon.png"
import TableDownload from "../../assets/images/table-download-icon.png"
import SearchInForm from '../../assets/images/search-in-from.svg';
import NplDetails from '../../components/patent-view/npl-info-section/npl-info';
import SummaryDetails from '../../components/patent-view/summary-details/summary';
import ActionButtons from '../../components/patent-view/action-buttons';
import TabButton from '../../components/common/tab-button';
import TopContent from '../../components/patent-view/top-content';
import './patent-view.scss';
import Button from '../../components/common/button';
import { setCanEdit } from '../../store/reducer/searchSlice';
import { setCanAddRow } from '../../store/reducer/searchSlice';
import { setActiveReduxTab } from '../../store/reducer/searchSlice';
import { useGetSavedInitialRefByUserQuery } from '../../store/action/initialReferencesApi'
import { formatSavedDate } from '../../util/utils';
import { useLocation, useNavigate } from "react-router-dom";
import Popup from '../../components/popup/reference-popup';
const PatentView = () => {
  const dispatch = useDispatch();
  const searchParams = useSelector((state) => state.search);
  const { state } = useLocation();
  const { type, number: pubNum } = state || {};
  const navigate = useNavigate();
  useEffect(() => {
    if (searchParams.type === '' && searchParams.number === '') {
      navigate('/search')
    }
  }, [searchParams])
  const { data, error, isLoading } = useGetFamilyBiblioQuery({ type: searchParams.type !== '' ? searchParams.type : type, format: 'docdb', number: searchParams.number !== '' ? searchParams.number : pubNum });
  const extractedData = useSelector((state) => state.search.extractedData);
  const isNPLFileUploaded = useSelector((state) => state.search.isNPLFileUploaded);
  const canEdit = useSelector((state) => state.search.canEdit);
  const selectedRefData = useSelector((state) => state.search.selectedRefData);
  const [isRefPopupOpen, setIsRefPopupOpen] = useState(false);
  const [isNavigatePopupOpen, setIsNavigatePopupOpen] = useState(false);
  const [isNavigateFamilyPopupOpen, setIsNavigateFamilyPopupOpen] = useState(false);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const [downloadReferences, setDownloadRef] = useState(false);
  const [downloadFamily, setDownloadFamily] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isTabSwitched, setIsTabSwitched] = useState(false);
  const [isTabFamilySwitched, setIsTabFamilySwitched] = useState(false);
  const [targetTab, setTargetTab] = useState(null);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFocusedIndex, setSearchFocusedIndex] = useState(null);
  const number = useSelector((state) => state.search.number)
  const userNumber = sessionStorage.getItem("userId")
  const { data: initdRefData } = useGetSavedInitialRefByUserQuery({ userId: userNumber, num: number })
  const savedInitialRefArray = initdRefData ? [...initdRefData] : []
  const formattedSavedInitialRefData = savedInitialRefArray.map((member) => {
    return {
      "Publication / Application Number": member.pdf_ref_num !== "PlaceholderRefNum" ? member.pdf_ref_num : "",
      "Kind Code": member.pdf_kind_code !== "-" ? member.pdf_kind_code : "",
      "Issue / Publication Date": member.pdf_ip_date !== null ? formatSavedDate(member.pdf_ip_date) : "",
      "Patentee": member.pdf_patentee_name !== "-" ? member.pdf_patentee_name : "",
      "Type": member.pdf_type,
    };
  })
  const handleSearchClick = () => {
    setIsSearchActive(true);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClose = () => {
    setIsSearchActive(false);
    setSearchTerm("");
    setSearchFocusedIndex(null);
  };

  const handleDownloadRef = () => {
    setDownloadRef(true);
    setTimeout(() => setDownloadRef(false), 1000)
  }
  const handleDownloadFamily = () => {
    setDownloadFamily(true);
    setTimeout(() => setDownloadFamily(false), 1000)
  }
  useEffect(() => {
    if (data) {
      dispatch(setFamilyBiblio(data));
    }
    if (error) {
      console.error('Error fetching data:', error);
      dispatch(setError(error));
    }
    if (isLoading) {
      dispatch(setLoading());
    }
  }, [data, error, isLoading, dispatch]);

  const [activeTab, setActiveTab] = useState('familyInformation');
  const [selectedColumn, setSelectedColumn] = useState(
    activeTab === 'familyInformation' ? 'Serial #' : 'References'
  );
  useEffect(() => {
    if (extractedData.length > 0) {
      setActiveTab('initialReferences');
    }
  }, [extractedData]);
  useEffect(() => {
    if (initdRefData?.length > 0) {
      setActiveTab('initialReferences');
    }
  }, [initdRefData])
  useEffect(() => {
    dispatch(setActiveReduxTab(activeTab))
  }, [activeTab])

  const handleRefPopup = () => {
    if (extractedData.length > 0) {
      setIsRefPopupOpen(true);
      return;
    }
  }
  const handleCloseRefPopup = () => {
    setIsRefPopupOpen(false);
  };
  const handleEdit = () => {
    dispatch(setCanEdit(!canEdit))
    return;
  }
  const handleAdd = (value = "default") => {
    if (activeTab === 'initialReferences') {
      dispatch(setCanAddRow('add-new-init-ref'))
      return;
    }
    if (activeTab === 'familyInformation') {
      dispatch(setCanAddRow('add-new-family'))
      return;
    }
    if (activeTab === 'references') {
      if (value === "npl-table") {
        dispatch(setCanAddRow('add-new-npl'))
        return;
      }
      dispatch(setCanAddRow('add-new-ref'))
      return;
    }
    if (activeTab === 'sb08') {
      if (value === "npl-table") {
        dispatch(setCanAddRow('add-new-npl'))
        return;
      }
      dispatch(setCanAddRow('add-new-ids'))
    }
  }
  useEffect(() => {
    if (activeTab === 'references' || activeTab === 'sb08') {
      setSelectedColumn("References")
      return;
    }
    if (activeTab === 'familyInformation') {
      setSelectedColumn("Serial #")
      return;
    }
  }, [activeTab])

  const handleTabClick = (tabName) => {
    if (activeTab === 'references' && tabName !== 'references') {
      setTargetTab(tabName);
      setIsNavigatePopupOpen(true);
    }
    else if (activeTab === 'familyInformation' && tabName !== 'familyInformation') {
      setTargetTab(tabName);
      setIsNavigateFamilyPopupOpen(true);
    }
    else {
      setIsTabSwitched(false);
      setActiveTab(tabName);
    }
  };

  const handleSaveFamilyAndNavigate = () => {
    setIsNavigateFamilyPopupOpen(false);
    setIsTabFamilySwitched(true);
    setActiveTab(targetTab);
    setIsSaveClicked(true);
    setTargetTab(null);
  };

  const handleCancelFamilyNavigate = () => {
    setIsNavigateFamilyPopupOpen(false);
    setIsTabFamilySwitched(false);
    setActiveTab(targetTab);
    setTargetTab(null);
  };

  const handleSaveAndNavigate = () => {
    setIsNavigatePopupOpen(false);
    setIsTabSwitched(true);
    setActiveTab(targetTab);
    setIsSaveClicked(true);
    setTargetTab(null);
  };

  const handleCancelNavigate = () => {
    setIsNavigatePopupOpen(false);
    setIsTabSwitched(false);
    setActiveTab(targetTab);
    setTargetTab(null);
  };
  const handleOkExport = () => {
    setIsExportPopupOpen(false);
  };

  return (
    <div className="patent-view-container">
      <div className={`top-summary-container ${activeTab === 'initialReferences' ? 'full-width-top' : ''}`}>
        <div className={`card card-1 top-content ${activeTab === 'initialReferences' ? 'full-width' : ''}`}>
          <TopContent />
        </div>
        {activeTab !== 'initialReferences' && (
          <div className="card card-1 summary">
            <SummaryDetails activeTab={activeTab} />
          </div>
        )}
      </div>
      <div className={`card card-2 ${activeTab === 'familyInformation' ? 'family-tab' : 'other-tabs'}`}>
        <div className="tab-container">
          {(extractedData.length > 0 || initdRefData?.length > 0) && (
            <TabButton isActive={activeTab === 'initialReferences'} onClick={() => handleTabClick('initialReferences')}>
              Initial References
            </TabButton>
          )}
          <TabButton isActive={activeTab === 'familyInformation'} onClick={() => handleTabClick('familyInformation')}>
            Family
          </TabButton>
          {/* <TabButton isActive={activeTab === 'citationMatrix'} onClick={() => handleTabClick('citationMatrix')}>
            Family Citations
          </TabButton> */}
          <TabButton isActive={activeTab === 'references'} onClick={() => handleTabClick('references')}>
            References
          </TabButton>
          <TabButton isActive={activeTab === 'sb08'} onClick={() => handleTabClick('sb08')}>
            IDS
          </TabButton>
          {activeTab === 'references' && selectedRefData.filter((item) => item.Selected === true).length > 0 && (<div className='d-flex flex-row align-items-center ms-1 mt-1 selected-class'>
            {selectedRefData.filter((item) => item.Selected === true).length} References Selected
          </div>)}
          <div className='d-flex flex-row legend-icon-container'>
            {activeTab !== 'initialReferences' && activeTab !== 'familyInformation' && activeTab !== 'sb08' && extractedData.length > 0 && (
              <Button className="discard-btn mx-3" children="Discard Initial References" onClick={handleRefPopup} title="Eliminate the Initial References  from the References table" />
            )}
            <div className="icons">
              {!isSearchActive && (
                <img src={SearchInForm} alt="Search In Form" className="icon" onClick={handleSearchClick} title='Search' />
              )}
              {isSearchActive && (
                <div className="search-container">
                  <select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
                    {(activeTab === 'familyInformation') && <option value="Serial #">Serial #</option>}
                    {(activeTab === 'familyInformation') && <option value="Pub. Number">Pub. Number</option>}
                    {(activeTab === 'references' || activeTab === 'sb08' || activeTab === 'initialReferences') && <option value="References">References</option>}
                  </select>

                  <input
                    type="text"
                    placeholder={`${selectedColumn}`}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                  />
                  <button src={TableAdd} alt="Table Edit" onClick={handleSearchClose} className="icon p-0">x</button>
                </div>
              )}

              <img src={TableEdit} alt="Table Edit" onClick={handleEdit} className="icon" title='Edit the Fields in the Table' />


              <img src={TableAdd} alt="Table Add" onClick={handleAdd} className="icon" title='Add New Row in the Table' />


              {activeTab === 'references' && <img src={TableDownload} alt="Download References" className='icon' onClick={handleDownloadRef} title="Export entire References List" />}
              {activeTab === 'familyInformation' && <img src={TableDownload} alt="Download Family Information" className='icon' onClick={handleDownloadFamily} title="Export entire Patent Family List" />}
            </div>
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 'initialReferences' && (extractedData.length > 0 || initdRefData?.length > 0) && <InitialReferences searchTerm={searchTerm} selectedColumn={selectedColumn} />}
          {activeTab === 'familyInformation' && <FamilyInformation searchTerm={searchTerm} selectedColumn={selectedColumn} downloadFamily={downloadFamily} />}
          {activeTab === 'references' && <CitationMatrix downloadReferences={downloadReferences} searchTerm={searchTerm} selectedColumn={selectedColumn} setIsExportPopupOpen={setIsExportPopupOpen} />}
          {activeTab === 'sb08' && <SB08FormDetails searchTerm={searchTerm} selectedColumn={selectedColumn} />}
        </div>
        {isNavigatePopupOpen && (
          <Popup
            onClose={handleCancelNavigate}
            message="Save Reference Changes"
            subMessage="Do you want to save changes before navigating away?"
            buttons={[
              { label: "Save", onClick: () => handleSaveAndNavigate(activeTab) },
              { label: "Cancel", onClick: handleCancelNavigate }
            ]}
          />
        )}
        {isNavigateFamilyPopupOpen && (
          <Popup
            onClose={handleCancelFamilyNavigate}
            message="Save Family Changes"
            subMessage="Do you want to save changes before navigating away?"
            buttons={[
              { label: "Save", onClick: () => handleSaveFamilyAndNavigate(activeTab) },
              { label: "Cancel", onClick: handleCancelFamilyNavigate }
            ]}
          />
        )}
        {isExportPopupOpen && (
          <Popup
            onClose={handleOkExport}
            message="Export References"
            subMessage="No selected rows or NPL data to export."
            buttons={[
              { label: "Ok", onClick: handleOkExport }
            ]}
            isExportPopup={true}
          />
        )}
        <div className='npl-info '>
          {activeTab !== 'familyInformation' && (
            <div className="card-2 d-flex flex-column">
              <NplDetails activeTab={activeTab} searchTerm={searchTerm} selectedColumn={selectedColumn} handleAdd={handleAdd} handleEdit={handleEdit} src1={TableAdd} src2={TableEdit} />
            </div>
          )}
        </div>
        <div className='action-buttons-container'>
          <ActionButtons activeTab={activeTab} isTabSwitched={isTabSwitched} isTabFamilySwitched={isTabFamilySwitched} isSaveClicked={isSaveClicked} setActiveTab={setActiveTab} extractedData={extractedData} isRefPopupOpen={isRefPopupOpen} handleCloseRefPopup={handleCloseRefPopup} />
        </div>
      </div>

    </div >

  );
}

export default PatentView;
