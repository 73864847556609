import React, { useState, useRef, useEffect } from "react";
import Button from "../../common/button";
import Input from "../../common/input";
import UploadImage from "../../../assets/images/create-letter-manual-upload.png";
import BackImage from "../../../assets/images/template-back.png";
import SendImage from "../../../assets/images/table-download-icon.png";
import ScheduleImage from "../../../assets/images/template-schedule.png";
import SaveImage from "../../../assets/images/template-save.png";
import FileCloseIcon from "../../../assets/images/create-letter-file-close.png";
import {
  setIsCanTempPopupOpen,
  setIsSaveTempPopupOpen,
  setIsScheduleMailPopupOpen,
} from "../../../store/reducer/templateSlice";
import { useDispatch, useSelector } from "react-redux";
import CancelTemplatePopup from "../popup/cancel-template-popup";
import SaveTemplatePopup from "../popup/save-template-popup";
import ScheduleMailPopup from "../popup/schedule-mail-popup";
import NafdTableSection from "./nafd-table-section";
import { handleRemoveSignFile } from "../common/signature-file-functions";
import SignFileList from "../common/signature-file-list";
import RlTemplateButtons from "../common/rl-template-buttons";
import AttachFileList from "../common/attach-file-list";
import { handleDownloadPdf } from "../common/download-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { handleCopyTemplate } from "../common/copy-template";
import NafdTemplateHeader from "./nafd-template-header";
import { setIsDownloadPopupOpen } from "../../../store/reducer/templateSlice";
import RlTemplatePopups from "../common/rl-template-popups";
import RlTooltip from "../common/rl-tooltip";

const EmailSection = () => {
  const dispatch = useDispatch();
  const isCanTempPopupOpen = useSelector(
    (state) => state.template.isCanTempPopupOpen
  );
  const isSaveTempPopupOpen = useSelector(
    (state) => state.template.isSaveTempPopupOpen
  );
  const isScheduleMailPopupOpen = useSelector(
    (state) => state.template.isScheduleMailPopupOpen
  );
  const inputReduxValues = useSelector(
    (state) => state.template.inputReduxValues
  );
  const isDownloadPopupOpen = useSelector(
    (state) => state.template.isDownloadPopupOpen
  );
  const caseTemplate = useSelector((state) => state.createLetter.caseTemplate);
  const markReduxFiles = useSelector((state) => state.template.markReduxFiles);
  const [imageUrl, setImageUrl] = useState(null);
  const contentRef = useRef(null);
  const buttonRef = useRef(null);

  const addressText = `Vivian Teng
  Chicago International Film Festival Inc. Cinema - Chicago
  212 W. Van Buren, Suite 400
  Chicago IL 60607`;

  const servicesText = `Entertainment services, namely, organizing, planning and
                conducting a series of festivals in the fields of film,
                television, and entertainment; entertainment services, namely,
                motion picture and film production services; Entertainment and
                educational services, namely identifying and developing
                independent artists and audiences; discovering, supporting, and
                inspiring independent film and theatre artists from the United
                States and internationally; introducing audiences to the work of
                such artists; planning, managing, and conducting an
                international film festival; and conducting seminars and
                workshops in the field of filmmaking; providing on-line
                information regarding films, and television programming;
                conducting award shows related to the film and entertainment
                industry; planning and conducting film screening events in the
                nature of classic and contemporary movies, documentaries and
                short films; planning and conducting cultural events,
                exhibitions, conferences and panel discussions in the fields of
                film, television, art, literature, and music; entertainment and
                educational services, namely, educational meetings and
                conferences, arranging and conducting symposiums, panels, and
                workshops, in the field of entertainment, film, television;
                organizing and conducting meetings for social entertainment
                purposes for entertainment companies, filmmakers and content
                creators; educational services, namely, the conducting of
                seminars, laboratories and programs directed to persons in the
                motion picture industry to develop and promote film as a major
                art form; provision of non-downloadable films and television
                programs via a video-on-demand service and via a streaming
                service; providing a website featuring information in the field
                of entertainment, film, and television`;

  const fields = [
    {
      label: "in the name of",
      value: inputReduxValues?.applicant
        ? inputReduxValues.applicant
        : "{Matter.Applicant<n>}",
    },
    {
      label: "Our Ref No",
      value: inputReduxValues?.ourRef
        ? inputReduxValues.ourRef
        : "{Matter.AttorneyRef}",
    },
  ];

  const filingParticularFields = [
    {
      label: "Mark",
      value: inputReduxValues?.mark ? inputReduxValues.mark : "{Matter.Title}",
    },
    {
      label: "Applicant",
      value: inputReduxValues?.applicant
        ? inputReduxValues.applicant
        : "{Matter.Applicant<n>}",
    },
    {
      label: "Serial Number",
      value: inputReduxValues?.serialNo
        ? inputReduxValues.serialNo
        : "{Matter.ApplicationNum}",
    },
    {
      label: "Classes",
      value: inputReduxValues?.classes
        ? inputReduxValues.classes
        : "{Matter.Class}",
    },
    {
      label: "Services",
      value: inputReduxValues?.services
        ? inputReduxValues.services
        : `{Matter.Services}`,
    },
    {
      label: "Filing Basis",
      value: inputReduxValues?.filingBasis
        ? inputReduxValues.filingBasis
        : "{Matter.Filing&basis}",
    },
    {
      label: "Application Date",
      value: inputReduxValues?.applicationDate
        ? inputReduxValues.applicationDate
        : "{Matter.FilingDate}",
    },
    {
      label: "Priority Filing Date",
      value: inputReduxValues?.prdFilingDate
        ? inputReduxValues.prdFilingDate
        : "{Matter.FilingDate(+6m MMMM d, yyyy)}",
    },
  ];
  const handleDiscard = () => {
    handleScrollToTop();
    dispatch(setIsCanTempPopupOpen(true));
  };
  const handleClose = () => {
    dispatch(setIsCanTempPopupOpen(false));
  };
  const handleSaveClose = () => {
    dispatch(setIsSaveTempPopupOpen(false));
  };
  const handleScheduleClose = () => {
    dispatch(setIsScheduleMailPopupOpen(false));
  };
  const handleDownloadPopupClose = () => {
    dispatch(setIsDownloadPopupOpen(false));
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [attachFiles, setAttachFiles] = useState([]);
  const [attachSignFile, setAttachSignFile] = useState([]);
  const [attachSignError, setAttachSignError] = useState("");
  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validFiles = [];

    filesArray.forEach((file) => {
      validFiles.push(file);
    });
    setAttachFiles(validFiles);
  };

  let invalidSignFiles = [];

  const handleSignatureFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validSignFiles = [];
    filesArray.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "rtf" || fileExtension === "txt") {
        validSignFiles.push(file);
      } else {
        invalidSignFiles.push(file);
      }
    });
    if (invalidSignFiles.length > 0) {
      document.getElementById("template-attach-signature-file").value = "";
      alert("Please upload a .rtf or .txt file.");
      setAttachSignError("Please upload a .rtf or .txt file.");
      return;
    }
    setAttachSignFile(validSignFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = attachFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("template-attach-file").value = "";
    setAttachFiles(updatedFiles);
  };

  useEffect(() => {
    if (markReduxFiles.length > 0) {
      const url = URL.createObjectURL(markReduxFiles[0]);
      setImageUrl(url);
      return () => URL.revokeObjectURL(url);
    }
    if (markReduxFiles.length === 0) {
      setImageUrl(null);
    }
  }, [markReduxFiles]);

  return (
    <>
      <div
        className="d-flex flex-column email-container px-3 py-3 section-scroll"
        ref={contentRef}
      >
        <RlTooltip contentRef={contentRef} buttonRef={buttonRef} />
        <NafdTemplateHeader
          inputReduxValues={inputReduxValues}
          handleCopyTemplate={handleCopyTemplate}
          addressText={addressText}
          contentRef={contentRef}
        />
        <br></br>
        <span className="d-flex flex-row re-section align-items-stretch">
          <span className="capital-re mt-1">RE:</span>
          <span className="d-flex flex-column cert-regis-section">
            <span className="d-flex flex-row">
              <span className="field-title pt-1">
                U.S. Trademark Application for
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={`Mark`}
                    className="ms-1"
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                  />
                ) : (
                  <span className="mx-1 field-span-pdf pt-1 highlight-field-class">
                    {inputReduxValues?.mark
                      ? inputReduxValues?.mark
                      : "{Matter.Title}"}
                  </span>
                )}
                (Ser. No.
                <span className="mx-1 field-span-pdf pt-1 highlight-field-class">
                  {inputReduxValues?.serialNo
                    ? inputReduxValues?.serialNo
                    : "{Matter.ApplicationNum}"}
                </span>
                )
              </span>
            </span>
            {fields.map((field, index) => (
              <span key={index} className="d-flex flex-row mt-1">
                <br></br>
                <span className="field-title pt-1">
                  {field.label}:
                  <span className="mx-1 field-span-pdf pt-1 highlight-field-class">
                    {field.value}
                  </span>
                </span>
                <br></br>
              </span>
            ))}
          </span>
        </span>
        <br></br>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">Dear</span>
          <span
            className="email-content-span ms-1 contact-name-span-nafd highlight-field-class"
            style={{ marginLeft: "5px" }}
          >
            {inputReduxValues?.clientName
              ? inputReduxValues?.clientName
              : "{Matter.ContactName}"}
          </span>
          <span>,</span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            In accordance with your instructions, I am happy to confirm that I
            prepared and filed a U.S. trademark application on behalf of your
            company for the captioned mark. A copy of the filing receipt is
            enclosed for your client’s records.
          </span>
        </div>
        <div className="d-flex flex-row re-section mt-1 align-items-stretch">
          <div className="d-flex flex-column cert-regis-section ps-1">
            <div className="cert-regis-title cert-regis-title-table ms-1 mb-2">
              Filing Particulars
            </div>
            <NafdTableSection
              filingParticularFields={filingParticularFields}
              markReduxFiles={markReduxFiles}
              imageUrl={imageUrl}
            />
          </div>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            Please note, if you decide to expand your filing program to
            additional countries, I recommend filing on or before
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.prdFilingDate
                ? inputReduxValues?.prdFilingDate
                : "{Matter.FilingDate(+6m MMMM d, yyyy)}"}
            </span>
            <span className="ms-1">
              in order to claim the priority filing date (i.e., your rights in
              these countries will date back to
            </span>
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.applicationDate
                ? inputReduxValues?.applicationDate
                : "{Matter.FilingDate}"}
            </span>
            )
          </span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            The U.S. Trademark Office is currently taking about 9-10 months for
            an application to be assigned to an Examiner so we will likely have
            further news in late
            <span
              className="email-content-span highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {"October or early November 2025"}
            </span>
            . I will keep you updated on any developments. In the meantime,
            please let me know if you have any questions.
          </span>
        </div>
        <div className="d-flex flex-column email-content-section align-items-start last-text-section">
          <div className="content-text text-only-size">
            Kind regards,
            <br></br>
            /jms/
            <br></br>
            Jackie Stelling
          </div>
          {attachFiles.length > 0 && (
            <AttachFileList
              attachFiles={attachFiles}
              FileCloseIcon={FileCloseIcon}
              handleRemoveFile={handleRemoveFile}
            />
          )}
          {attachSignFile.length > 0 && (
            <SignFileList
              handleRemoveSignFile={handleRemoveSignFile}
              FileCloseIcon={FileCloseIcon}
              attachSignFile={attachSignFile}
              setAttachSignFile={setAttachSignFile}
            />
          )}
        </div>
        <RlTemplateButtons
          handleDiscard={handleDiscard}
          BackImage={BackImage}
          UploadImage={UploadImage}
          handleSignatureFileChange={handleSignatureFileChange}
          handleFileChange={handleFileChange}
          handleDownloadPdf={handleDownloadPdf}
          SendImage={SendImage}
          SaveImage={SaveImage}
          contentRef={contentRef}
        />
      </div>
      <RlTemplatePopups
        isCanTempPopupOpen={isCanTempPopupOpen}
        isSaveTempPopupOpen={isSaveTempPopupOpen}
        isScheduleMailPopupOpen={isScheduleMailPopupOpen}
        isDownloadPopupOpen={isDownloadPopupOpen}
        handleClose={handleClose}
        handleSaveClose={handleSaveClose}
        handleScheduleClose={handleScheduleClose}
        handleDownloadPopupClose={handleDownloadPopupClose}
        contentRef={contentRef}
        caseTemplate={caseTemplate}
      />
    </>
  );
};

export default EmailSection;
