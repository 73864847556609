import { React, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import TableEdit from "../../assets/images/table-edit-icon.png";
import TableDownload from "../../assets/images/table-download-icon.png";
import DashboardShare from "../../assets/images/dashboard-share-icon.png";
import DashboardDelete from "../../assets/images/dashboard-delete.png";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../popup/reference-popup";
import {
  setSelectIdsToggle,
  setSharePopupToggle,
  setEditDashboardTable,
  setShareToEmailNumber,
} from "../../store/reducer/adminSlice";
import {
  useEditAdminMutation,
  useEditEmptyIdAdminMutation,
  useDeleteAdminMutation,
  useDeleteUserAdminMutation,
  useDeleteEmptyUserAdminMutation,
} from "../../store/action/adminApi";
import {
  setPopupNumber,
  setPopupIdsType,
  setPopupDocketNumber,
} from "../../store/reducer/searchSlice";

const Table = ({ headers, data: initialData, renderAsHtml }) => {
  const dispatch = useDispatch();
  const editDashboardTable = useSelector(
    (state) => state.admin.editDashboardTable
  );
  const [data, setData] = useState([...initialData]);
  const [editDashboard] = useEditAdminMutation();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedDockId, setSelectedDockId] = useState(null);
  const [editEmptyIdAdmin] = useEditEmptyIdAdminMutation();
  const [deleteAdmin] = useDeleteAdminMutation();
  const [deleteUserAdmin] = useDeleteUserAdminMutation();
  const [deleteEmptyUserAdmin] = useDeleteEmptyUserAdminMutation();
  const userEditNumber = sessionStorage.getItem("userId");
  const headerToFieldObject = {
    "Patent Family": "family_mem",
    "Attorney Docket No.": "docket_num",
    Type: "ids_type",
    Date: "curr_date",
    Status: "status",
    "Filing Status": "filing_status",
  };
  const [filedStatusObj, setFiledStatusObj] = useState({});
  const familyCount = {};
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleSelect = (e, index) => {
    setFiledStatusObj({
      ...filedStatusObj,
      [index]: e.target.value,
    });
  };

  const handleEdit = async (rowId, header, value, docketNum) => {
    if (value.length > 18 || /[!@#$%^&*]/.test(value) === true) {
      alert("Filed date cannot contain other characters.");
      window.location.reload();
      return;
    }
    const convertedHeader = headerToFieldObject[header];
    if (header === "Date") {
      const editedDate = new Date(value).toISOString();
      const editedDateObject = { [convertedHeader]: editedDate };
      await editDashboard({
        userId: userEditNumber,
        id: rowId,
        data: editedDateObject,
      });
      return;
    }
    const editObject = { [convertedHeader]: value };
    if (rowId === "") {
      await editEmptyIdAdmin({
        userId: userEditNumber,
        docketNum: docketNum,
        data: editObject,
      });
      return;
    }
    await editDashboard({
      userId: userEditNumber,
      id: rowId,
      data: editObject,
    });
  };
  const handleDelete = async (id, docket_number) => {
    try {
      if (id !== "") {
        await deleteUserAdmin({ id: id, userId: userEditNumber }).unwrap();
      } else {
        await deleteEmptyUserAdmin({
          docketNum: docket_number,
          userId: userEditNumber,
        }).unwrap();
      }
      setData((prevData) => initialData.filter((row) => row.Id_num !== id));
    } catch (error) {
      console.error("Failed to delete record:", error);
    } finally {
      window.location.reload();
      setIsPopupVisible(false);
    }
  };

  const confirmDelete = (id, docknum) => {
    setSelectedId(id);
    setSelectedDockId(docknum);
    setIsPopupVisible(true);
  };
  const handleDownload = async (uid, pubNumber, docketNumber) => {
    try {
      const response = await fetch(
        `api/v1/admin/download-pdf/${uid}/${
          pubNumber === "" ? docketNumber : pubNumber
        }`,
        {
          method: "GET",
          headers: {
            Accept: "application/pdf",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =
        pubNumber !== ""
          ? `Generated_IDS_${uid}-${pubNumber}.pdf`
          : `Generated_IDS_${uid}-${docketNumber}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  return (
    <div className="table-container dashboard-table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0
            ? data.map((row, rowIndex) => {
                const patentFamily = row["Patent Family"];
                if (patentFamily) {
                  familyCount[patentFamily] =
                    (familyCount[patentFamily] || 0) + 1;
                  if (familyCount[patentFamily] > 1) {
                    row["Patent Family"] = `${patentFamily} (V${
                      familyCount[patentFamily] - 1
                    })`;
                  }
                }

                return (
                  <tr key={rowIndex}>
                    {headers.map((header, colIndex) => (
                      <td key={colIndex}>
                        {header === "Action" ? (
                          <div className="d-flex flex-row align-items-center justify-content-center">
                            <img
                              src={TableEdit}
                              alt="Edit"
                              title="Edit the Existing or New IDS for this Case."
                              className="action-icon click-icon"
                              onClick={() => {
                                dispatch(setPopupNumber(row["Id_num"]));
                                dispatch(setPopupIdsType(row["Type"]));
                                dispatch(
                                  setPopupDocketNumber(
                                    row["Attorney Docket No."]
                                  )
                                );
                                dispatch(setSelectIdsToggle(true));
                              }}
                            />
                            <img
                              src={DashboardShare}
                              alt="Share"
                              title="Share the IDS Form PDF over Email."
                              className={`ms-2 ${
                                row.Status.props.children === "COMPLETED"
                                  ? "click-icon"
                                  : "disable-share-download"
                              }`}
                              onClick={() => {
                                dispatch(setSharePopupToggle(true));
                                dispatch(setShareToEmailNumber(row["Id_num"]));
                              }}
                            />
                            <img
                              src={TableDownload}
                              alt="Download"
                              title="Download the IDS Form PDF."
                              className={`ms-2 ${
                                row.Status.props.children === "COMPLETED"
                                  ? "click-icon"
                                  : "disable-share-download"
                              }`}
                              onClick={() => {
                                const userNumber = parseInt(
                                  sessionStorage.getItem("userId"),
                                  10
                                );
                                handleDownload(
                                  userNumber,
                                  row["Id_num"],
                                  row["Attorney Docket No."]
                                );
                              }}
                            />
                            <img
                              src={DashboardDelete}
                              alt="Delete"
                              title="Delete the Record"
                              className={`delete-record`}
                              onClick={() =>
                                confirmDelete(
                                  row.Id_num,
                                  row["Attorney Docket No."]
                                )
                              }
                            />
                          </div>
                        ) : header === "Filing Status" &&
                          (row["Filing Status"] === "Status" ||
                            row["Filing Status"] === "Un-Filed" ||
                            row["Filing Status"] === "Filed") &&
                          filedStatusObj[rowIndex] !== "Filed" ? (
                          <select
                            id={`editable_filed_select_${rowIndex}_${header}`}
                            value={
                              Object.keys(filedStatusObj).length === 0
                                ? row["Filing Status"]
                                : filedStatusObj[rowIndex]
                            }
                            onChange={async (e) => {
                              handleSelect(e, rowIndex);
                              if (e.target.value !== "Filed") {
                                await handleEdit(
                                  row["Id_num"],
                                  header,
                                  e.target.value,
                                  row["Attorney Docket No."]
                                );
                                return;
                              }
                            }}
                            className={`filed-select ${
                              rowIndex % 2 === 0 ? "odd-select" : ""
                            }`}
                          >
                            <option value="Status" hidden>
                              Status
                            </option>
                            <option value="Filed">Filed</option>
                            <option value="Un-Filed">Un-Filed</option>
                          </select>
                        ) : header === "Filing Status" &&
                          filedStatusObj[rowIndex] === "Filed" ? (
                          <span
                            id={`editable_filed_span_${rowIndex}_${header}`}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            className="filed-content"
                            onKeyDown={handleKeyDown}
                            onBlur={(event) => {
                              const updatedValue = event.target.innerText;
                              handleEdit(
                                row["Id_num"],
                                header,
                                updatedValue,
                                row["Attorney Docket No."]
                              );
                            }}
                          >
                            Filed()
                          </span>
                        ) : header === "Filing Status" &&
                          row["Filing Status"] !== "Status" &&
                          row["Filing Status"] !== "Un-Filed" &&
                          row["Filing Status"] !== "Filed" &&
                          filedStatusObj[rowIndex] !== "Filed" ? (
                          <span
                            id={`editable_filed_span_${rowIndex}_${header}`}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            className="filed-content"
                            onKeyDown={handleKeyDown}
                            onBlur={(event) => {
                              const updatedValue = event.target.innerText;
                              handleEdit(
                                row["Id_num"],
                                header,
                                updatedValue,
                                row["Attorney Docket No."]
                              );
                            }}
                          >
                            {row["Filing Status"]}
                          </span>
                        ) : (
                          row[header]
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })
            : initialData.map((row, rowIndex) => {
                const patentFamily = row["Patent Family"];
                if (patentFamily) {
                  familyCount[patentFamily] =
                    (familyCount[patentFamily] || 0) + 1;
                  if (familyCount[patentFamily] > 1) {
                    row["Patent Family"] = `${patentFamily} (V${
                      familyCount[patentFamily] - 1
                    })`;
                  }
                }

                return (
                  <tr key={rowIndex}>
                    {headers.map((header, colIndex) => (
                      <td key={colIndex}>
                        {header === "Action" ? (
                          <div className="d-flex flex-row align-items-center justify-content-center">
                            <img
                              src={TableEdit}
                              alt="Edit"
                              title="Edit the Existing or New IDS for this Case."
                              className="action-icon click-icon"
                              onClick={() => {
                                dispatch(setPopupNumber(row["Id_num"]));
                                dispatch(setPopupIdsType(row["Type"]));
                                dispatch(
                                  setPopupDocketNumber(
                                    row["Attorney Docket No."]
                                  )
                                );
                                dispatch(setSelectIdsToggle(true));
                              }}
                            />
                            <img
                              src={DashboardShare}
                              alt="Share"
                              title="Share the IDS Form PDF over Email."
                              className={`ms-2 ${
                                row.Status.props.children === "COMPLETED"
                                  ? "click-icon"
                                  : "disable-share-download"
                              }`}
                              onClick={() => {
                                dispatch(setSharePopupToggle(true));
                                dispatch(setShareToEmailNumber(row["Id_num"]));
                              }}
                            />
                            <img
                              src={TableDownload}
                              alt="Download"
                              title="Download the IDS Form PDF."
                              className={`ms-2 ${
                                row.Status.props.children === "COMPLETED"
                                  ? "click-icon"
                                  : "disable-share-download"
                              }`}
                              onClick={() => {
                                const userNumber = parseInt(
                                  sessionStorage.getItem("userId"),
                                  10
                                );
                                handleDownload(
                                  userNumber,
                                  row["Id_num"],
                                  row["Attorney Docket No."]
                                );
                              }}
                            />
                            <img
                              src={DashboardDelete}
                              alt="Delete"
                              title="Delete the Record"
                              className={`delete-record`}
                              onClick={() =>
                                confirmDelete(
                                  row.Id_num,
                                  row["Attorney Docket No."]
                                )
                              }
                            />
                          </div>
                        ) : header === "Filing Status" &&
                          (row["Filing Status"] === "Status" ||
                            row["Filing Status"] === "Un-Filed" ||
                            row["Filing Status"] === "Filed") &&
                          filedStatusObj[rowIndex] !== "Filed" ? (
                          <select
                            id={`editable_filed_select_${rowIndex}_${header}`}
                            value={
                              Object.keys(filedStatusObj).length === 0
                                ? row["Filing Status"]
                                : filedStatusObj[rowIndex]
                            }
                            onChange={async (e) => {
                              handleSelect(e, rowIndex);
                              if (e.target.value !== "Filed") {
                                await handleEdit(
                                  row["Id_num"],
                                  header,
                                  e.target.value,
                                  row["Attorney Docket No."]
                                );
                                return;
                              }
                            }}
                            className={`filed-select ${
                              rowIndex % 2 === 0 ? "odd-select" : ""
                            }`}
                          >
                            <option value="Status" hidden>
                              Status
                            </option>
                            <option value="Filed">Filed</option>
                            <option value="Un-Filed">Un-Filed</option>
                          </select>
                        ) : header === "Filing Status" &&
                          filedStatusObj[rowIndex] === "Filed" ? (
                          <span
                            id={`editable_filed_span_${rowIndex}_${header}`}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            className="filed-content"
                            onKeyDown={handleKeyDown}
                            onBlur={(event) => {
                              const updatedValue = event.target.innerText;
                              handleEdit(
                                row["Id_num"],
                                header,
                                updatedValue,
                                row["Attorney Docket No."]
                              );
                            }}
                          >
                            Filed()
                          </span>
                        ) : header === "Filing Status" &&
                          row["Filing Status"] !== "Status" &&
                          row["Filing Status"] !== "Un-Filed" &&
                          row["Filing Status"] !== "Filed" &&
                          filedStatusObj[rowIndex] !== "Filed" ? (
                          <span
                            id={`editable_filed_span_${rowIndex}_${header}`}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            className="filed-content"
                            onKeyDown={handleKeyDown}
                            onBlur={(event) => {
                              const updatedValue = event.target.innerText;
                              handleEdit(
                                row["Id_num"],
                                header,
                                updatedValue,
                                row["Attorney Docket No."]
                              );
                            }}
                          >
                            {row["Filing Status"]}
                          </span>
                        ) : (
                          row[header]
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
        </tbody>
      </table>
      {isPopupVisible && (
        <Popup
          onClose={() => setIsPopupVisible(false)}
          message="Confirm Delete"
          subMessage="Are you sure you want to delete this record?"
          buttons={[
            {
              label: "Yes",
              className: "confirm-btn",
              onClick: () => handleDelete(selectedId, selectedDockId),
            },
            {
              label: "No",
              className: "cancel-btn",
              onClick: () => setIsPopupVisible(false),
            },
          ]}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
