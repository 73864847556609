import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const uploadRlPdfApi = createApi({
    reducerPath: "uploadRlPdfApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        uploadRlPdf: builder.mutation({
            query: ({ data }) => ({
                url: `extract-rl-pdf-data/upload-rl-pdf-file`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useUploadRlPdfMutation } = uploadRlPdfApi;
export default uploadRlPdfApi;
