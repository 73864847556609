// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-content {
  padding-left: 15px;
}
.card-content .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  color: var(--primary_black);
}
.card-content .row .key {
  font-weight: 600;
  margin-right: 20px;
  width: 200px;
  text-align: left;
}
.card-content .row .value {
  text-align: left;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/patent-view/index.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AAAF;AAEE;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,UAAA;EACA,2BAAA;AAAJ;AAEI;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;AAAN;AAGI;EACE,gBAAA;EACA,SAAA;AADN","sourcesContent":["@import \"../../index.css\";\n\n.card-content {\n  padding-left: 15px;\n\n  .row {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    padding: 0;\n    color: var(--primary_black);\n\n    .key {\n      font-weight: 600;\n      margin-right: 20px;\n      width: 200px;\n      text-align: left;\n    }\n\n    .value {\n      text-align: left;\n      flex: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
