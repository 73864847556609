import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { buildMutationHelper } from "../../buildMutationHelper";
import { BASE_URL } from "../../utils/constants";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),

  endpoints: (builder) => ({
    login: buildMutationHelper(
      builder,
      (getData) => ({
        method: "POST",
        url: `auth/login`,
        body: getData,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      (response, dispatch) => {
        console.log(response);
      },
      ({ error }, dispatch) => {
        console.log(error);
      }
    ),
    forgotPassword: builder.mutation({
      query: (emailData) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: emailData,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      onSuccess: (response, dispatch) => {
        console.log('Forgot Password Response:', response);
      },
      onError: ({ error }, dispatch) => {
        console.log('Forgot Password Error:', error);
      }
    }),
    resetPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: `/auth/reset-password/${token}`,
        method: 'POST',
        body: { password },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      onSuccess: (response, dispatch) => {
        console.log('Reset Password Response:', response);
      },
      onError: ({ error }, dispatch) => {
        console.log('Reset Password Error:', error);
      }
    }),
    shareToEmail: builder.mutation({
      query: ({ email, uid, pubNumber }) => ({
        url: `/auth/share-on-email/${uid}/${pubNumber}`,
        method: 'POST',
        body: { email },
      }),
      onSuccess: (response, dispatch) => {
        console.log('Response:', response);
      },
      onError: ({ error }, dispatch) => {
        console.log('Error:', error);
      }
    })
  }),
});

export const { useLoginMutation, useForgotPasswordMutation, useResetPasswordMutation, useShareToEmailMutation } = loginApi;
export default loginApi;
