import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./sgp.scss";
import SgpParamSection from "../../../components/RL/sgp-sections/sgp-param-section";
import SgpEmailSection from "../../../components/RL/sgp-sections/sgp-email-section";

const SGP = () => {
  return (
    <div className="sgp-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">Statement of Grant of Protection</div>
        <div className="d-flex flex-row">
          <SgpParamSection />
          <SgpEmailSection />
        </div>
      </div>
    </div>
  );
};

export default SGP;
