import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../common/button';
import Popup from '../popup/generate-form';
import RefPopup from '../popup/discard-reference-popup'
import NullPopup from '../popup/null-popup'
import '../../pages/PatentView/patent-view.scss';
import { saveInitialReferences } from '../../store/reducer/initialReferencesSlice';
import { saveReferences } from '../../store/reducer/referencesSlice';
import { saveFamilyData } from '../../store/reducer/familySlice';
import { setFormattedRefData } from '../../store/reducer/referencesSlice';
import { setDiscardedIdsData, setDiscardedNplData } from '../../store/reducer/searchSlice';
import { useLocation } from "react-router-dom";
const ActionButtons = ({ activeTab, setActiveTab, extractedData, isRefPopupOpen, handleCloseRefPopup, isTabSwitched, isTabFamilySwitched }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { number } = state || {};
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isNullPopupOpen, setIsNullPopupOpen] = useState(false);
  const { formattedInitialRefData } = useSelector((state) => state.search);
  const { formattedFamilyData } = useSelector((state) => state.family);
  const { formattedReferenceData } = useSelector((state) => state.references);
  const extractedNplData = useSelector((state) => state.search.extractedNplData);
  const familyNplData = useSelector((state) => state.search.familyNplData);
  const selectedRefData = useSelector((state) => state.search.selectedRefData);
  const selectedNplData = useSelector((state) => state.search.selectedNplData);
  const formattedIdsData = useSelector(
    (state) => state.search.formattedIdsData
  );
  const formattedNplData = useSelector(
    (state) => state.search.formattedNplData
  );
  const finalNplData =
    selectedNplData.length === 0
      ? formattedNplData
      : selectedNplData.filter((item) => item.Selected === false).length ===
        selectedNplData.length
        ? selectedNplData
        : selectedNplData.filter((item) => item.Selected === true);
  const reduxTopContentData = useSelector((state) => state.topContent.data);
  const headerData = useSelector((state) => state.admin.data);
  const userId = parseInt(sessionStorage.getItem("userId"), 10);
  if (isNaN(userId)) {
    throw new Error('Invalid userId');
  }
  const navigate = useNavigate();
  const tabs = ['initialReferences', 'familyInformation', 'references', 'sb08'];
  const normalizeNplTitle = (str) => str.replace(/^-+\s*/, '').trim().replace(/\s+/g, ' ');
  const handleGenerateClick = () => {
    if (formattedIdsData.length === 0 && finalNplData.length === 0
      && headerData?.['serial_num'] === 'N/A'
      && headerData?.['docket_num'] === 'N/A'
      && headerData?.['applicants'] === 'N/A'
      && headerData?.['title'] === 'N/A'
      && headerData?.['filling_date'] === 'N/A'
      && Object.keys(reduxTopContentData).length === 0) {
      setIsNullPopupOpen(true)
      return;
    }
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleNoClick = async () => {
    handleCloseRefPopup();
  }
  const handleYesClick = async () => {
    handleCloseRefPopup();
    await handleDiscardSaveReferences()
    setActiveTab('sb08');
  }
  const handleCloseNullPopup = () => {
    setIsNullPopupOpen(false);
  };
  const handleNoNullClick = async () => {
    setIsNullPopupOpen(false);
  }
  const handleYesNullClick = async () => {
    setIsNullPopupOpen(false);
    setIsPopupOpen(true);
  }

  const handleNextClick = async () => {
    try {
      if (activeTab === 'initialReferences') {
        await handleSaveInitialReferences();
        setActiveTab('familyInformation'); // Move to next tab after saving
      } else if (activeTab === 'familyInformation') {
        await handleSaveFamilyData();
        setActiveTab('references'); // Move to next tab after saving
      } else if (activeTab === 'references') {
        await handleSaveReferences();
        setActiveTab('sb08'); // Move to next tab after saving
      } else if (activeTab === 'sb08') {
        handleGenerateClick(); // Open popup for 'sb08'
      }
    } catch (error) {
      console.error('Failed to save data:', error);
    }
  };

  const handleBackClick = () => {
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex === 0) {
      navigate(-1)
      return;
    }
    if (currentIndex === 1 && extractedData.length === 0) {
      navigate(-1)
      return;
    }
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1]);
    }
  };

  const getNextButtonText = () => {
    switch (activeTab) {
      case 'references':
        return 'Save';
      case 'sb08':
        return 'Generate';
      default:
        return 'Save & Next';
    }
  };
  // eslint-disable-next-line
  const isBackButtonVisible = () => {
    const firstTab = 'initialReferences';
    return activeTab !== firstTab;
  };

  const handleSaveInitialReferences = async () => {
    try {
      if (formattedInitialRefData.length === 0) {
        alert("No Initial References Present To Save")
        return;
      }
      const initialReferencesData = formattedInitialRefData.map(item => {
        let pdf_ref_num = item["Publication / Application Number"] || "PlaceholderRefNum";
        if (!pdf_ref_num || typeof pdf_ref_num !== 'string') {
          pdf_ref_num = "PlaceholderRefNum";
        }

        let formattedDate = null;
        if (item['Issue / Publication Date']) {
          const date = new Date(item['Issue / Publication Date']);
          formattedDate = !isNaN(date.getTime()) ? date.toISOString() : null;
        }

        return {
          id_num: number,
          pdf_ref_num,
          pdf_kind_code: item["Kind Code"] || "-",
          pdf_ip_date: formattedDate || null,
          pdf_patentee_name: item["Patentee"] || "-",
          pdf_type: item["IDS Form Section Heading"] || "-",
          pdf_string: '',
          userId: userId,
        };
      });

      await dispatch(saveInitialReferences(initialReferencesData)).unwrap();
    } catch (error) {
      console.error('Failed to save initial references:', error);
    }
  };
  const classifyDiscardPatentNumber = (patentNumber) => {
    const patentStr = patentNumber.toString().slice(2);
    if (patentStr.length === 10) {
      return "U.S.PATENT APPLICATION PUBLICATIONS"
    } else {
      return "U.S.PATENTS"
    }
  }
  const existsInitialInRef = (initRefData, refItem) => {
    return initRefData.some(item => {
      const refExceptKindCode = refItem['References'];
      const refKindCode = refItem['Kind Code'];
      const numberExceptZero = item['Publication / Application Number'].replace(/([A-Z]{2}\d{4})(0+)/, '$1');
      return (numberExceptZero === refExceptKindCode && item['Kind Code'] === refKindCode)
    })
  }
  const existsInitialNpl = (initNplData, nplItem) => {
    return initNplData.some(item => {
      const initNplTitle = normalizeNplTitle(item['References']);
      const nplTitle = normalizeNplTitle(nplItem['References'])
      const initNplTitleItem = normalizeNplTitle(item['References']);
      const nplTitleItem = normalizeNplTitle(nplItem['References'])
      return (initNplTitle === nplTitle) || (initNplTitleItem === nplTitleItem)
    })
  }
  const handleDiscardSaveReferences = async () => {
    try {
      const ReferencesData = formattedReferenceData.filter(item => !existsInitialInRef(formattedInitialRefData, item)).map(item => {
        let ref_num = item["References"] || "PlaceholderRefNum";
        if (!ref_num || typeof ref_num !== 'string') {
          ref_num = "PlaceholderRefNum";
        }
        // eslint-disable-next-line
        let formattedDate = null;
        if (item['Issued / Pub. Date']) {
          const date = new Date(item['Issued / Pub. Date']);
          // eslint-disable-next-line
          formattedDate = !isNaN(date.getTime()) ? date.toISOString() : null;
        }
        const match = item["References"].match(/^([A-Z]{2})/);
        const countryCode = match ? match[0] : "";
        const publicationType = countryCode === "US" ? classifyDiscardPatentNumber(item["References"]) : "FOREIGN PATENT DOCUMENTS";
        return {
          "Selected": true,
          "Sr.No": item["Sr.No"],
          "Serial #": item["Serial #"],
          "References": item["References"],
          "Kind Code": item["Kind Code"],
          "Issued / Pub. Date": item["Issued / Pub. Date"],
          "Patentee Name": item["Patentee Name"],
          "IDS Form Section Heading": publicationType,
        };
      });
      const nplData = familyNplData.filter(item => !existsInitialNpl(extractedNplData, item)).map((item) => {
        return {
          "Selected": true,
          "Author Name": item["Author Name"],
          "Title": item["Title"],
          "References": item["References"],
          "Title of the Item": item["Title of the Item"],
          "Date": item["Date"],
          "Pages": item["Pages"],
          "Volume": item["Volume"],
          "Publisher": item["Publisher"],
          "Link": item["Link"]
        };
      })
      if (selectedRefData.length !== 0) {
        const selectedReferencesData = selectedRefData.filter((item) => item.Selected === true).filter(item => !existsInitialInRef(formattedInitialRefData, item)).map(item => {
          let ref_num = item["References"] || "PlaceholderRefNum";
          if (!ref_num || typeof ref_num !== 'string') {
            ref_num = "PlaceholderRefNum";
          }

          let formattedDate = null;
          if (item['Issued / Pub. Date']) {
            const date = new Date(item['Issued / Pub. Date']);
            // eslint-disable-next-line
            formattedDate = !isNaN(date.getTime()) ? date.toISOString() : null;
          }
          const match = item["References"].match(/^([A-Z]{2})/);
          const countryCode = match ? match[0] : "";
          const publicationType = countryCode === "US" ? classifyDiscardPatentNumber(item["References"]) : "FOREIGN PATENT DOCUMENTS";
          return {
            "Selected": true,
            "Sr.No": item["Sr.No"],
            "Serial #": item["Serial #"],
            "References": item["References"],
            "Kind Code": item["Kind Code"],
            "Issued / Pub. Date": item["Issued / Pub. Date"],
            "Patentee Name": item["Patentee Name"],
            "IDS Form Section Heading": publicationType,
          };
        });
        dispatch(setDiscardedIdsData(selectedReferencesData));
      }
      else {
        dispatch(setDiscardedIdsData(ReferencesData));
      }
      if (selectedNplData.length !== 0) {
        const selectNplData = selectedNplData.filter((item) => item.Selected === true).filter(item => !existsInitialNpl(extractedNplData, item)).map((item) => {
          return {
            "Selected": true,
            "Author Name": item["Author Name"],
            "Title": item["Title"],
            "References": item["References"],
            "Title of the Item": item["Title of the Item"],
            "Date": item["Date"],
            "Pages": item["Pages"],
            "Volume": item["Volume"],
            "Publisher": item["Publisher"],
            "Link": item["Link"]
          };
        })
        dispatch(setDiscardedNplData(selectNplData))
      } else {
        dispatch(setDiscardedNplData(nplData))
      }
    } catch (error) {
      console.error('Failed to save references:', error);
    }
  };
  const handleSaveFamilyData = async () => {
    try {
      if (formattedFamilyData.length === 0) {
        alert("No Families Present To Save")
        return;
      }
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime()) ? date.toISOString() : null;
      };

      const familyDataToSave = formattedFamilyData.map(item => {
        return {
          id_num: number,
          serial_num: item['Serial #'],
          country_code: item['Country Code'],
          filing_date: formatDate(item['Filing Date']),
          status: item['Status'],
          pub_num: item['Pub. Number'],
          ip_date: formatDate(item['Pub. Date']),
          pat_num: item['Patent #'],
          patent_date: formatDate(item['Patent Date']),
          docket_num: item['Attorney Docket #'],
          applicants: item['Applicants'],
          priority_date: formatDate(item['Priority Date']),
          lastids_date: formatDate(item['Last IDS Filed Date']),
          ref_available: item['Availability'],
          userId: userId,
        };
      });

      await dispatch(saveFamilyData(familyDataToSave)).unwrap();
    } catch (error) {
      console.error('Failed to save family data:', error);
    }
  };

  const handleSaveReferences = async () => {
    try {
      if (formattedReferenceData.length === 0) {
        alert("No References Present To Save")
        return;
      }
      const ReferencesData = formattedReferenceData.map(item => {
        let ref_num = item["References"] || "PlaceholderRefNum";
        if (!ref_num || typeof ref_num !== 'string') {
          ref_num = "PlaceholderRefNum";
        }

        let formattedDate = null;
        if (item['Issued / Pub. Date']) {
          const date = new Date(item['Issued / Pub. Date']);
          formattedDate = !isNaN(date.getTime()) ? date.toISOString() : null;
        }

        return {
          id_num: number,
          ref_num,
          kind_code: item["Kind Code"] || "-",
          ip_date: formattedDate || null,
          patentee_name: item["Patentee Name"] || "",
          string: "-",
          serial_num: item["Serial #"],
          flag: "",
          userId: userId,
        };
      });

      await dispatch(saveReferences(ReferencesData)).unwrap();
    } catch (error) {
      console.error('Failed to save references:', error);
    }
  };

  useEffect(() => {
    if (isTabSwitched) {
      handleSaveReferences();
    }
  }, [isTabSwitched]);
  useEffect(() => {
    if (isTabFamilySwitched) {
      handleSaveFamilyData();
    }
  }, [isTabFamilySwitched]);

  return (
    <div className='action-buttons d-flex'>
      <Button className='back-btn' onClick={handleBackClick} title="Move to Previous Page">Back</Button>
      <Button className='next-btn' onClick={handleNextClick} title="Save the data and move to Next Page">{getNextButtonText()}</Button>
      {isPopupOpen && (
        <Popup onClose={handleClosePopup} />
      )}
      {isRefPopupOpen && (
        <RefPopup onClose={handleCloseRefPopup}
          handleNoClick={handleNoClick}
          handleYesClick={handleYesClick} />
      )}
      {isNullPopupOpen && (
        <NullPopup onClose={handleCloseNullPopup}
          handleNoClick={handleNoNullClick}
          handleYesClick={handleYesNullClick} />
      )}
    </div>
  );
}

export default ActionButtons;
