import React, { useEffect, useState } from "react";
import Input from "../../common/input";
import InputIcon from "../../../assets/images/template-edit-icon.png";
import TickIcon from "../../../assets/images/tick-new-icon.png";
import CrossIcon from "../../../assets/images/cross-new-icon.png";
import MarkUpload from "../../../assets/images/mark-upload-icon.png";
import MarkEdit from "../../../assets/images/mark-edit-icon.png";
import FileCloseIcon from "../../../assets/images/create-letter-file-close.png";
import Button from "../../common/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputReduxValues,
  setMarkReduxFiles,
} from "../../../store/reducer/templateSlice";
import { useUploadRlPdfMutation } from "../../../store/action/uploadRlPdfApi";
import RlTemplateMarkFilesList from "../common/rl-template-mark-files-list";
import RLTemplateParamButtons from "../common/rl-template-param-buttons";
import RlTemplateMarkButton from "../common/rl-template-mark-button";
import { countWords } from "../../../util/utils";

const ParamSection = () => {
  const dispatch = useDispatch();
  const [markFiles, setMarkFiles] = useState([]);
  const [markError, setMarkError] = useState("");
  const caseNumberType = useSelector(
    (state) => state.createLetter.caseNumberType
  );
  const caseNumber = useSelector((state) => state.createLetter.caseNumber);
  const letterRadioOption = useSelector(
    (state) => state.createLetter.letterRadioOption
  );
  const manualInputFiles = useSelector(
    (state) => state.createLetter.manualInputFiles
  );
  const uploadInputFiles = useSelector(
    (state) => state.createLetter.uploadInputFiles
  );
  const manualFileTypes = useSelector(
    (state) => state.createLetter.manualFileTypes
  );
  const uploadFileTypes = useSelector(
    (state) => state.createLetter.uploadFileTypes
  );

  const [uploadRlPdf, { isLoading, isError, isSuccess }] =
    useUploadRlPdfMutation();

  const handleMarkFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    const maxSize = 1048576;
    const validFiles = [];
    let errorMessage = "";

    filesArray.forEach((file) => {
      if (file.size > maxSize) {
        errorMessage = `File size should not exceed 1MB.`;
      } else if (!/\.(jpe?g|png|gif|bmp|svg|webp|tiff)$/i.test(file.name)) {
        errorMessage = `Invalid file type. Only image files are allowed.`;
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessage) {
      setMarkError(errorMessage);
    } else {
      setMarkError("");
      setMarkFiles(validFiles);
      dispatch(setMarkReduxFiles(validFiles));
    }
  };

  useEffect(() => {
    if (markError !== "") {
      setTimeout(() => {
        setMarkError("");
      }, [2000]);
    }
  }, [markError]);

  const handleRemoveMarkFile = (index) => {
    const updatedFiles = markFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("mark-attach-file").value = "";
    setMarkFiles(updatedFiles);
    dispatch(setMarkReduxFiles([]));
  };
  const fields = [
    {
      id: "usSerialNum",
      label: "U.S. Serial Number*",
      placeholder: "{Matter.ApplicationNum}",
    },
    { id: "mark", label: "Mark*", placeholder: "{Matter.Title}" },
    {
      id: "subjectMark",
      label: "Subject Mark*",
      placeholder: "{Matter.Title}",
    },
    {
      id: "extenReqNum",
      label: "Extension Request Number*",
      placeholder: "{Matter.ExtRequestNum}",
    },
    {
      id: "docketRefNum",
      label: "Docket/Reference Number*",
      placeholder: "{Matter.AttorneyRef}",
    },
    {
      id: "notAllowDate",
      label: "Notice of Allowance Date*",
      placeholder: "{Matter.IssueDate(MMMM d, YYYY)}",
    },
    {
      id: "extAllowDate",
      label: "Extension of Notice of Allowance Date*",
      placeholder: "{Matter.IssueDate(+12m MMMM d, yyyy)}",
    },
    {
      id: "clientName",
      label: "Point Of Contact*",
      placeholder: "{Matter.ContactName}",
    },
  ];

  const [manualEdit, setManualEdit] = useState(false);

  const [inputValues, setInputValues] = useState(
    fields.reduce((acc, field) => {
      acc[field.id] = "";
      return acc;
    }, {})
  );

  const [editableFields, setEditableFields] = useState(
    fields.reduce((acc, field) => {
      acc[field.id] = false;
      return acc;
    }, {})
  );

  const [editableFocusFields, setEditableFocusFields] = useState({});

  const toggleEdit = (fieldId) => {
    if (fieldId === "mark" && markFiles.length !== 0) {
      alert("Please remove the image first to add text.");
      return;
    }
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: true,
    }));
    setEditableFocusFields((prevState) => ({
      ...prevState,
      [fieldId]: true,
    }));
  };

  const toggleDisableEdit = (fieldId, icon) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: false,
    }));
    setEditableFocusFields((prevState) => ({
      ...prevState,
      [fieldId]: false,
    }));
    if (icon === "tick") {
      setManualEdit(false);
    }
    if (icon === "cross") {
      setManualEdit(false);
      setInputValues((prevValues) => ({
        ...prevValues,
        [fieldId]: "",
      }));
      return;
    }
  };

  const handleInputChange = (fieldId, value) => {
    if (countWords(value) > 1000) {
      alert("Maximum Word Limit Reached.");
      return;
    }
    setManualEdit(true);
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value,
    }));
  };

  const handleSave = () => {
    dispatch(setInputReduxValues(inputValues));
  };

  const formatPDFTextAer = async (textContent) => {
    const usSerialNumElement =
      textContent[
        textContent.findIndex((item) => item.includes("Serial Number"))
      ];
    const regex = /Serial Number:\s*(\d+)/;
    const match = usSerialNumElement.match(regex);
    const mark =
      textContent[textContent.findIndex((item) => item.includes("Mark"))]
        ?.split(":")?.[1]
        ?.trim() || "";
    const extenReqNum =
      textContent[
        textContent.findIndex((item) =>
          item.includes("Extension Request Number")
        )
      ]
        ?.split(":")?.[1]
        ?.trim() || "";
    const docketRefNum =
      textContent[
        textContent.findIndex((item) =>
          item.includes("Docket/Reference Number")
        )
      ]
        ?.split(":")?.[1]
        ?.trim() || "";
    const notAllowDate =
      textContent[
        textContent.findIndex((item) =>
          item.includes("Notice of Allowance Date")
        )
      ]
        ?.split(":")?.[1]
        ?.trim() || "";

    let extAllowDate = "";

    const date = new Date(notAllowDate);

    switch (extenReqNum) {
      case "1":
        date.setFullYear(date.getFullYear() + 1);
        break;
      case "2":
        date.setMonth(date.getMonth() + 18);
        break;
      case "3":
        date.setMonth(date.getMonth() + 24);
        break;
      case "4":
        date.setMonth(date.getMonth() + 30);
        break;
      case "5":
        date.setMonth(date.getMonth() + 36);
        break;
      default:
        date.setMonth(date.getMonth() + 42);
        break;
    }

    extAllowDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const fetchObject = {
      usSerialNum: match ? match[1] : "",
      mark: mark,
      extenReqNum: extenReqNum,
      docketRefNum: docketRefNum,
      notAllowDate: notAllowDate,
      extAllowDate: extAllowDate,
    };
    setInputValues((prevValues) => ({
      ...prevValues,
      ...fetchObject,
    }));
  };
  const getAerPdfData = async (index) => {
    const formDataAer = new FormData();
    formDataAer.append(
      "file",
      letterRadioOption === "manual"
        ? manualInputFiles[index]
        : uploadInputFiles[index]
    );
    let caseNum = caseNumber;
    if (caseNumberType === "Serial#") {
      caseNum = `sn${caseNumber}`;
    } else if (caseNumberType === "Registration #") {
      caseNum = `rn${caseNumber}`;
    } else {
      caseNum = `refnum${caseNumber}`;
    }
    formDataAer.append("caseNum", caseNum);
    try {
      const responseTextAer = await uploadRlPdf({ data: formDataAer }).unwrap();
      await formatPDFTextAer(responseTextAer.textContent);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetch = async () => {
    try {
      if (letterRadioOption === "manual") {
        manualFileTypes.forEach(async (type, index) => {
          switch (type) {
            case "ltr_ntc_of_approval_of_extension_request":
              await getAerPdfData(index);
              break;
          }
        });
      } else {
        uploadFileTypes.forEach(async (type, index) => {
          switch (type) {
            case "ltr_ntc_of_approval_of_extension_request":
              await getAerPdfData(index);
              break;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manualEdit === false) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues, manualEdit]);

  useEffect(() => {
    Object.keys(editableFocusFields).forEach((fieldId) => {
      if (editableFocusFields[fieldId]) {
        var element = document.getElementById(`${fieldId}-input`);
        element.focus();
      }
    });
  }, [editableFocusFields]);

  return (
    <>
      <div className="d-flex flex-column param-container justify-content-between section-scroll">
        <div className="d-flex flex-column param-input-container justify-content-center px-3 py-3">
          <div className="param-title">Parameters</div>
          {fields.map((field) => (
            <div
              key={field.id}
              className="d-flex flex-column justify-content-center mt-2"
            >
              <div className="param-input-title">{field.label}</div>
              <div className="d-flex flex-row align-items-center">
                <Input
                  type="text"
                  id={`${field.id}-input`}
                  placeholder={`${field.placeholder}`}
                  className={`param-input-style ${
                    editableFields[field.id] === false &&
                    inputValues[field.id] !== ""
                      ? "param-input-text-style"
                      : ""
                  } ${
                    field.id === "notAllowDate" || field.id === "extAllowDate"
                      ? "param-input-style-long"
                      : ""
                  }`}
                  value={inputValues[field.id]}
                  onChange={(e) => handleInputChange(field.id, e.target.value)}
                  disabled={!editableFields[field.id]}
                  spellcheck="false"
                />
                {editableFields[field.id] === false && (
                  <RlTemplateMarkButton
                    field={field}
                    MarkEdit={MarkEdit}
                    MarkUpload={MarkUpload}
                    handleDragStart={handleDragStart}
                    handleMarkFileChange={handleMarkFileChange}
                    InputIcon={InputIcon}
                    toggleEdit={toggleEdit}
                  />
                )}
                {editableFields[field.id] === true && (
                  <div className="param-input-image mx-1 d-flex flex-row align-items-center">
                    <img
                      src={TickIcon}
                      className="param-image"
                      alt="tick-icon"
                      onClick={() => toggleDisableEdit(field.id, "tick")}
                      onDragStart={handleDragStart}
                    />
                    <img
                      src={CrossIcon}
                      className="param-image mx-1"
                      alt="cross-icon"
                      onClick={() => toggleDisableEdit(field.id, "cross")}
                      onDragStart={handleDragStart}
                    />
                  </div>
                )}
              </div>
              {field.id === "mark" && markFiles.length > 0 && (
                <RlTemplateMarkFilesList
                  markFiles={markFiles}
                  FileCloseIcon={FileCloseIcon}
                  handleRemoveMarkFile={handleRemoveMarkFile}
                />
              )}
              {field.id === "mark" && (
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", height: "auto" }}
                >
                  {markError}
                </span>
              )}
            </div>
          ))}
        </div>
        <RLTemplateParamButtons
          isLoading={isLoading}
          handleFetch={handleFetch}
          handleSave={handleSave}
        />
      </div>
    </>
  );
};

export default ParamSection;
