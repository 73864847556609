
export const PRIORITY_ORDER = [undefined, 'original', 'docdb', 'docdba', 'epodoc', 'translated', 'lni', 'lookup'];

/**
 * Formats a date string from yyyymmdd to yyyy-mm-dd.
 * @param {string} dateStr - The date string in yyyymmdd format.
 * @returns {string} - The formatted date string in yyyy-mm-dd format.
 */
export const formatDate = (dateStr) => {
  if (!dateStr || dateStr.length !== 8) return '';
  return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`;
};

export const formatSavedDate = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * Searches within a text by filtering it based on the query.
 * @param {string} text - The text to search within.
 * @param {string} query - The search query.
 * @returns {boolean} - Whether the text matches the search query.
 */
export const searchInText = (text, query) => {
  if (!query) return true;
  return text.toLowerCase().includes(query.toLowerCase());
};

export const formatCurrentDate = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? String(hours).padStart(2, '0') : '12';

  return `${year}-${month}-${day} ${hours}:${minutes}${ampm}`;
}