import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./rl-dashboard.scss";
import SearchInForm from "../../../assets/images/search-in-from.svg";
import Table from "../../../components/common/rl-dashboard-table";
import Button from "../../../components/common/button";
import { formatCurrentDate, formatCustomDate } from "../../../util/utils";
import { highlightText } from "../../../utils/highlight";
import {
  setIsLetterPopupOpen,
  revertCreateLetter,
} from "../../../store/reducer/createLetterSlice";
import { useGetSavedRlTemplatesByUserQuery } from "../../../store/action/rlAdminApi";
import CreateLetterPopup from "../../../components/RL/popup/create-letter-popup";

const RLDashboard = () => {
  const dispatch = useDispatch();
  const userNumber = sessionStorage.getItem("userId");
  const isLetterPopupOpen = useSelector(
    (state) => state.createLetter.isLetterPopupOpen
  );
  const { data, refetch } = useGetSavedRlTemplatesByUserQuery({
    userId: userNumber,
  });
  const rlDashboardData = data ? [...data] : [];
  const headers = [
    "Sr.No",
    "Client",
    "Client Email Id",
    "Template",
    "Number Type",
    "Number",
    "Date & Time",
    "Action",
  ];
  const navigate = useNavigate();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("Client");
  const onCloseLetterPopup = () => {
    dispatch(setIsLetterPopupOpen(false));
    dispatch(revertCreateLetter());
  };
  const handleClick = () => {
    dispatch(setIsLetterPopupOpen(true));
  };
  const handleSearchClick = () => {
    setIsSearchActive(true);
  };
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearchClose = () => {
    setIsSearchActive(false);
    setSearchTerm("");
  };

  const defaultRow = headers.reduce(
    (acc, header) => ({ ...acc, [header]: "" }),
    {}
  );
  const defaultData = Array.from({ length: 12 }, () => defaultRow);

  const formattedRlDashboardData = rlDashboardData.map((member) => {
    return {
      Client: member.client_name,
      "Client Email Id": member.email_id ? member.email_id : "N/A",
      Template: member.template,
      "Number Type": member.number_type,
      Number: member.ref_id,
      "Date & Time": formatCustomDate(member.curr_date_time),
    };
  });

  const formattedSrRlDashboardData = formattedRlDashboardData.map(
    (item, index) => ({
      ...item,
      "Sr.No": index + 1,
    })
  );

  const highlightedData = formattedSrRlDashboardData.map((row, index) => ({
    ...row,
    Client: highlightText(row["Client"], searchTerm, selectedColumn, "Client"),
  }));

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className="rl-dashboard-container">
        <div className="card card-2">
          <div className="tab-container d-flex">
            <div className="dashboard-title">Dashboard</div>
            <div className="icons">
              <Button
                className={"create-rl-btn"}
                children="Create RL"
                onClick={handleClick}
                title="Create New RL"
              />
              {isSearchActive && (
                <div className="search-container">
                  <select
                    value={selectedColumn}
                    onChange={(e) => setSelectedColumn(e.target.value)}
                  >
                    <option value="Client">Client</option>
                  </select>
                  <input
                    type="text"
                    placeholder={`${selectedColumn}`}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                  />
                  <button
                    alt="Close Search Dialog"
                    onClick={handleSearchClose}
                    className="icon p-0"
                  >
                    x
                  </button>
                </div>
              )}
              {!isSearchActive && (
                <img
                  src={SearchInForm}
                  alt="Search In Form"
                  className="icon"
                  title="Search among the Cases"
                  onClick={handleSearchClick}
                />
              )}
            </div>
          </div>
          <Table
            headers={headers}
            data={highlightedData}
            searchTerm={searchTerm}
          />
        </div>
        <div className="d-flex flex-row login-stamp">
          <span className="login-stamp-text">Last Login Time stamp:</span>{" "}
          &nbsp;
          {formatCurrentDate()}
        </div>
      </div>
      {isLetterPopupOpen && <CreateLetterPopup onClose={onCloseLetterPopup} />}
    </>
  );
};

export default RLDashboard;
