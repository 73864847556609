import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./schedule-mail-calendar.scss";
import UpArrow from "../../../assets/images/schedule-mail-up-arrow.png";
import { setScheduledDate } from "../../../store/reducer/templateSlice";

const ScheduleMailCalendar = () => {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const currentMonth = currentDate.getMonth();
  const currentMonthTime = currentDateTime.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentYearTime = currentDateTime.getFullYear();
  const currentDayTime = currentDateTime.getDate();

  const firstDay = new Date(currentYear, currentMonth, 1);
  const lastDay = new Date(currentYear, currentMonth + 1, 0);
  const numDaysInMonth = lastDay.getDate();

  const startingDay = firstDay.getDay();

  const days = Array.from({ length: numDaysInMonth }, (_, index) => index + 1);

  const nextMonth = () => {
    setCurrentDate(new Date(currentYear, currentMonth + 1, 1));
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentYear, currentMonth - 1, 1));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSelectDate = (day) => {
    const scheduleMonth = currentMonth + 1;
    const scheduleYear = currentYear;
    const scheduleDay = String(day).padStart(2, "0");
    const selectedDate = new Date(scheduleYear, scheduleMonth - 1, scheduleDay);
    const currentDate = new Date();
    if (
      selectedDate < currentDate &&
      selectedDate.toDateString() !== currentDate.toDateString()
    ) {
      alert("Please select a date in the future.");
      return;
    }
    const scheduleDate = `${scheduleDay}/${scheduleMonth}/${scheduleYear}`;
    dispatch(setScheduledDate(scheduleDate));
    return;
  };

  return (
    <div className="calendar">
      <header>
        <span className="month-year-style px-2 py-2">{`${currentDate.toLocaleString(
          "default",
          {
            month: "long",
          }
        )} ${currentYear}`}</span>
        <img
          src={UpArrow}
          className="arrow-img mx-1"
          onClick={nextMonth}
          alt="Up"
        />
        <img
          src={UpArrow}
          className="arrow-img rotate-arrow-180 mx-1"
          onClick={prevMonth}
          alt="Down"
        />
      </header>
      <div className="days-of-week">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="day-name">
            {day}
          </div>
        ))}
      </div>
      <div className="calendar-grid">
        {Array.from({ length: startingDay }).map((_, index) => (
          <div key={`empty-${index}`} className="empty-day" />
        ))}
        {days.map((day) => (
          <div
            key={day}
            className={`day ${
              day === currentDayTime &&
              currentMonth === currentMonthTime &&
              currentYear === currentYearTime
                ? "current-style"
                : ""
            }`}
            onClick={() => handleSelectDate(day)}
          >
            {day}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleMailCalendar;
