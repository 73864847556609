import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import topContentApi from "../action/topContentApi";

export const saveTopContent = createAsyncThunk(
    "topContent/save",
    async (data, thunkAPI) => {
        try {
            const response = await thunkAPI.dispatch(
                topContentApi.endpoints.createTopContent.initiate(data)
            ).unwrap();

            return response;
        } catch (error) {
            console.error("API Call Failed:", error);
            return thunkAPI.rejectWithValue(
                error.message || "Failed to save top content data"
            );
        }
    }
);
const initialState = {
    data: [],
    status: "idle",
    error: null,
};

const topContentSlice = createSlice({
    name: "topContent",
    initialState,
    reducers: {
        setTopContentData: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(saveTopContent.pending, (state) => {
                state.status = "loading";
            })
            .addCase(saveTopContent.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
                state.error = null;
            })
            .addCase(saveTopContent.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload || "Failed to save top content data";
            });
    },
});
export const {
    setTopContentData
} = topContentSlice.actions;
export default topContentSlice.reducer;
