import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    createAdmin: builder.mutation({
      query: (data) => ({
        url: 'admin',
        method: 'POST',
        body: data,
      }),
    }),
    getAdmins: builder.query({
      query: () => `admin`,
      keepUnusedDataFor: 0
    }),
    updateStatus: builder.mutation({
      query: ({ id, userId, data }) => ({
        url: `admin/${userId}/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    updateEmptyStatus: builder.mutation({
      query: ({ userId, docketNum, data }) => ({
        url: `admin/update-empty-status/${userId}/${docketNum}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    editEmptyIdAdmin: builder.mutation({
      query: ({ userId, docketNum, data }) => ({
        url: `admin/edit-empty-id/${userId}/${docketNum}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    editAdmin: builder.mutation({
      query: ({ userId, id, data }) => ({
        url: `admin/edit/${userId}/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteAdmin: builder.mutation({
      query: (id) => ({
        url: `admin/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteUserAdmin: builder.mutation({
      query: ({ userId, id }) => ({
        url: `admin/delete-admin/${userId}/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteEmptyUserAdmin: builder.mutation({
      query: ({ userId, docketNum }) => ({
        url: `admin/delete-empty-admin/${userId}/${docketNum}`,
        method: 'DELETE',
      }),
    }),
    getSavedDataByUser: builder.query({
      query: ({ userId }) => `admin/saved-data/${userId}`,
      keepUnusedDataFor: 0
    }),
  }),
});

export const { useCreateAdminMutation, useGetAdminsQuery, useUpdateStatusMutation, useEditAdminMutation, useEditEmptyIdAdminMutation, useUpdateEmptyStatusMutation, useGetSavedDataByUserQuery, useDeleteAdminMutation, useDeleteUserAdminMutation, useDeleteEmptyUserAdminMutation } = adminApi;
export default adminApi;
