import React, { useState } from "react";
import Button from "../../common/button";
import Input from "../../common/input";
import { useShareToEmailMutation } from "../../../store/action/loginApi";
import { useDispatch, useSelector } from "react-redux";
import { setSharePopupToggle } from "../../../store/reducer/adminSlice";
import "./send-on-mail.scss";

const Popup = ({ onClose }) => {
  const [shareToEmailApi] = useShareToEmailMutation();
  const shareToEmailNumber = useSelector(
    (state) => state.admin.shareToEmailNumber
  );
  const dispatch = useDispatch();
  const RadioButton = ({ option }) => {
    return (
      <div className="d-flex flex-row align-items-center mx-2 radio-btn-container justify-content-center">
        <Input type="radio" name="format-btn" className="mx-2" />
        <label>{option}</label>
      </div>
    );
  };
  const [recieveMail, setRecieveMail] = useState("");
  const [validMail, setValidMail] = useState(true);
  const [validEmailError, setValidEmailError] = useState("");
  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };
  const handleChangeEmail = (e) => {
    setRecieveMail(e.target.value);
    if (isValidEmail(e.target.value) === false) {
      setValidMail(false);
      setValidEmailError("Please enter a valid email");
    } else {
      setValidMail(true);
      setValidEmailError("");
    }
  };
  const sendOnEmail = async (mail) => {
    const userNumber = parseInt(sessionStorage.getItem("userId"), 10);
    await shareToEmailApi({
      email: mail,
      pubNumber: shareToEmailNumber,
      uid: userNumber,
    }).then(({ data }) => {
      if (data.success) {
        alert("Email has been sent successfully");
      } else {
        alert("An Error Occurred");
      }
    });
  };

  return (
    <div className="popup-overlay">
      <div className="send-on-mail-popup">
        <div className="message">
          <span>Send on mail</span>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div className="d-flex submessage py-4 flex-column align-items-start">
          <label for="email" className="mb-2">
            Email-id
          </label>
          <Input
            type="email"
            placeholder="Type a email id"
            className={`py-2 email-input ${
              validMail === false ? "email-error" : ""
            }`}
            value={recieveMail}
            onChange={handleChangeEmail}
            required
          />
          <span className="email-valid mt-2">{validEmailError}</span>

          {/* <label for="file-format" className="py-2">
            Choose File Format
          </label>
          <div className="d-flex flex-row align-items-center justify-content-center mx-2 mb-2">
            <RadioButton option="PDF" />
            <RadioButton option="Word" />
            <RadioButton option="Excel" />
          </div> */}
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center py-1">
          <Button
            className={"send-on-mail-popup-btn mx-2"}
            children="Send"
            onClick={() => {
              if (isValidEmail(recieveMail) === false) {
                setValidMail(false);
                setValidEmailError("Please enter a valid email");
                setTimeout(() => {
                  setValidMail(true);
                  setValidEmailError("");
                }, 3000);
                return;
              }
              sendOnEmail(recieveMail);
              dispatch(setSharePopupToggle(false));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
