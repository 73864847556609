import React from "react";

const RlTemplateMarkFilesList = (props) => {
  const { markFiles, FileCloseIcon, handleRemoveMarkFile } = props;

  return (
    <>
      <div
        className="uploaded-mark-files d-flex flex-row justify-content-start"
        style={{ height: "auto" }}
      >
        <ul className="files-list">
          {markFiles.map((file, index) => (
            <li key={index}>
              <div className="d-flex flex-row align-items-center">
                <span className="file-name-span">{file.name}</span>

                <span
                  className="mark-remove-file"
                  onClick={() => handleRemoveMarkFile(index)}
                >
                  <img
                    src={FileCloseIcon}
                    className="mark-files-close-icon"
                    alt="file remove"
                  />
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default RlTemplateMarkFilesList;
