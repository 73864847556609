import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const RsrTemplateHeader = (props) => {
  const { inputReduxValues, handleCopyTemplate, contentRef } = props;
  return (
    <div className="d-flex flex-row justify-content-between sub-cert-section py-1">
      <span className="d-flex flex-row justify-content-center">
        <span className="d-flex flex-row justify-content-center cert-reg">
          <span>Subject Line: Renewal Due By</span>
          <span className="mx-2 matter-span-rsr matter-title-span-rsr matter-title-span-rsr-date highlight-field-class">
            {inputReduxValues?.renewalDate
              ? inputReduxValues?.renewalDate
              : "{Matter.RenewalDate}"}
          </span>
          :
        </span>
        <span className="d-flex flex-row justify-content-center mx-1">
          <span className="cert-reg">United States Reg No.</span>
          <span className="mx-1 matter-span-rsr matter-title-span-rsr highlight-field-class">
            {inputReduxValues?.regNo
              ? inputReduxValues?.regNo
              : "{Matter.PatentNum}"}
          </span>
        </span>
        <span className="d-flex flex-row justify-content-center mx-1">
          <span className="cert-reg">KR Ref.</span>
          <span className="mx-1 matter-span-rsr matter-title-span-rsr highlight-field-class">
            {inputReduxValues?.refNo
              ? inputReduxValues?.refNo
              : "{Matter.AttorneyRef}"}
          </span>
        </span>
      </span>
      <div
        className="d-flex flex-row align-items-center copy-icon-container"
        onClick={() => handleCopyTemplate(contentRef)}
      >
        <FontAwesomeIcon
          icon={faCopy}
          className="copy-img"
          title="Copy Template Content"
        />
      </div>
    </div>
  );
};

export default RsrTemplateHeader;
