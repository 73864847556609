import { React, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setCanAddRow } from "../../store/reducer/searchSlice";
import "./index.scss";

const Table = ({
  headers,
  data,
  renderAsHtml,
  handleUpdateInitialRefRowData,
  handleAddInitialRefRowData,
}) => {
  const canEdit = useSelector((state) => state.search.canEdit);
  const canAddRow = useSelector((state) => state.search.canAddRow);
  const dispatch = useDispatch();
  const setCursorPosition = (rowIndex, header) => {
    if (canEdit === true) {
      var element = document.getElementById(
        `editable_span_${rowIndex}_${header}`
      );
      if (element.childNodes.length === 0) {
        const newInput = document.createElement("input");
        newInput.className = "editable-input";
        newInput.textContent = "";
        element.appendChild(newInput);
        element.focus();
      }
    }
  };
  const addBlankInitialRefRow = () => {
    const newRow = headers.reduce((acc, header) => {
      acc[header] = "";
      return acc;
    }, {});
    handleAddInitialRefRowData((prevData) => [...prevData, newRow]);
    dispatch(setCanAddRow(""));
    return;
  };
  useEffect(() => {
    if (canEdit === true) {
      var element = document.getElementById(
        `editable_span_0_Publication / Application Number`
      );
      element.focus();
    }
  }, [canEdit]);
  useEffect(() => {
    if (canAddRow === "add-new-init-ref") {
      addBlankInitialRefRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddRow]);
  const handleCellChange = (rowIndex, header, event) => {
    const newValue = event.target.textContent;
    const updatedData = data.map((row, index) =>
      index === rowIndex ? { ...row, [header]: newValue } : row
    );
    handleUpdateInitialRefRowData(updatedData);
    handleAddInitialRefRowData([]);
    return;
  };
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td
                  id={`editable_span_${rowIndex}_${header}`}
                  spellCheck="false"
                  key={colIndex}
                  dangerouslySetInnerHTML={
                    renderAsHtml && header === "Link"
                      ? { __html: row[header] }
                      : undefined
                  }
                  contentEditable={canEdit === true ? "true" : "false"}
                  onFocus={() => setCursorPosition(rowIndex, header)}
                  suppressContentEditableWarning={true}
                  onBlur={(event) => {
                    if (canEdit) handleCellChange(rowIndex, header, event);
                  }}
                >
                  {!renderAsHtml || header !== "Link" ? row[header] : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
