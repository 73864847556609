import React from "react";

const RtrContactSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <div className="content-text text-only-size">
          If we are to claim non-use for the goods, we will need further
          information which we will send in a further letter.
          <br></br>
          We enclose below an "Instruction Sheet" to facilitate return of your
          instructions.
          <br></br>
          If you have any questions regarding this matter, please contact us at
          your convenience.
          <br></br>
          Linda Palomar
        </div>
      </div>
    </>
  );
};

export default RtrContactSection;
