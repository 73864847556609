import React from "react";
import Input from "../../common/input";

const RlTemplateMarkButton = (props) => {
  const {
    field,
    MarkEdit,
    MarkUpload,
    handleDragStart,
    handleMarkFileChange,
    toggleEdit,
    InputIcon,
  } = props;

  return (
    <>
      {field.id === "mark" && (
        <div className="param-input-image mx-1" onClick={() => {}}>
          <div className="mark-file-wrapper">
            <img
              style={{ width: "25px", height: "27px" }}
              src={MarkUpload}
              title="Upload JPEG Mark File"
              className="param-image"
              alt="input-icon"
              onDragStart={handleDragStart}
            />
            <Input
              type="file"
              id="mark-attach-file"
              className={`mark-attach-input`}
              onChange={handleMarkFileChange}
              accept="image/*"
            />
          </div>
        </div>
      )}
      <div
        className="param-input-image mx-1"
        onClick={() => toggleEdit(field.id)}
      >
        <img
          src={field.id === "mark" ? MarkEdit : InputIcon}
          className="param-image"
          alt="input-icon"
          onDragStart={handleDragStart}
        />
      </div>
    </>
  );
};

export default RlTemplateMarkButton;
