import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCanAddRow } from "../../store/reducer/searchSlice";
import PropTypes from "prop-types";
import RefArrow from "../../assets/images/ref-available-arrow.png";
import { setClearData } from "../../store/reducer/familySlice";
import "./index.scss";
const Table = ({
  headers,
  data,
  renderAsHtml,
  availableReferenceData = [],
  handleUpdateFamilyRowData,
  handleAddFamilyRowData,
}) => {
  const [openRows, setOpenRows] = useState({});
  const canEdit = useSelector((state) => state.search.canEdit);
  const canAddRow = useSelector((state) => state.search.canAddRow);
  const dispatch = useDispatch();
  const setCursorPosition = (rowIndex, header) => {
    if (canEdit === true) {
      var element = document.getElementById(
        `editable_span_${rowIndex}_${header}`
      );
      if (element.childNodes.length === 0) {
        const newInput = document.createElement("input");
        newInput.className = "editable-input";
        newInput.textContent = "";
        element.appendChild(newInput);
        element.focus();
      }
    }
  };

  const handleCellChange = (rowIndex, header, event) => {
    const newValue = event.target.textContent;
    const updatedData = data.map((row, index) =>
      index === rowIndex ? { ...row, [header]: newValue } : row
    );
    handleUpdateFamilyRowData(updatedData);
  };

  const addBlankFamilyRow = () => {
    const newRow = headers.reduce((acc, header) => {
      acc[header] = "";
      return acc;
    }, {});
    handleAddFamilyRowData((prevData) => [...prevData, newRow]);
    dispatch(setCanAddRow(""));
  };

  useEffect(() => {
    if (canAddRow === "add-new-family") {
      addBlankFamilyRow();
    }
  }, [canAddRow]);

  const toggleRow = (rowId) =>
    setOpenRows((previous) => ({
      ...previous,
      [rowId]: !(previous[rowId] ?? false),
    }));

  useEffect(() => {
    dispatch(setClearData());
  }, []);
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <>
              <tr key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                  >
                    {header === "Availability" &&
                    row[header] === "Ref# Available" ? (
                      <>
                        <span className="ref-avail-class">
                          {row[header]}
                          <img
                            src={RefArrow}
                            className="ref-arrow-img mx-1"
                            alt="Dropdown"
                            onClick={() => toggleRow(rowIndex)}
                            title="Expand the References"
                          />
                        </span>
                        <span className="ref-count">{row["citedCounts"]}</span>
                      </>
                    ) : !renderAsHtml || header !== "Link" ? (
                      <span
                        id={`editable_span_${rowIndex}_${header}`}
                        onFocus={() => setCursorPosition(rowIndex, header)}
                        contentEditable={canEdit === true ? "true" : "false"}
                        suppressContentEditableWarning={true}
                        className={`${canEdit === true ? "editable-span" : ""}`}
                        onBlur={(event) => {
                          if (canEdit)
                            handleCellChange(rowIndex, header, event);
                        }}
                      >
                        {row[header]}
                      </span>
                    ) : null}
                  </td>
                ))}
              </tr>
              {openRows[rowIndex] && (
                <>
                  {availableReferenceData.some(
                    (refData) =>
                      refData["NplText"] === "" &&
                      refData["Serial #"] === row["plainSerial#"] &&
                      refData["Patent #"] === row["Patent #"]
                  ) && (
                    <>
                      <tr>
                        <td colSpan="3">
                          <span className="ref-title">Publication No.</span>{" "}
                        </td>
                        <td colSpan="3">
                          <span className="ref-title">Kind Code</span>{" "}
                        </td>
                        <td colSpan="3">
                          <span className="ref-title">Publication Date</span>{" "}
                        </td>
                        <td colSpan="5">
                          <span className="ref-title">Patentee Name</span>{" "}
                        </td>
                      </tr>
                      {availableReferenceData
                        .filter(
                          (refData) =>
                            refData["NplText"] === "" &&
                            refData["Serial #"] === row["plainSerial#"] &&
                            refData["Patent #"] === row["Patent #"]
                        )
                        .map((refData) => (
                          <tr>
                            <td colSpan="3">{refData["Publication No."]}</td>
                            <td colSpan="3">{refData["Kind Code"]}</td>
                            <td colSpan="3">{refData["Publication Date"]}</td>
                            <td colSpan="5">{refData["Patentee Name"]}</td>
                          </tr>
                        ))}
                    </>
                  )}
                  {availableReferenceData
                    .filter(
                      (refData) =>
                        refData["Serial #"] === row["plainSerial#"] &&
                        refData["Patent #"] === row["Patent #"] &&
                        refData["NplText"] !== ""
                    )
                    .map((refData) => (
                      <tr>
                        <td colSpan="14">{refData["NplText"]} </td>
                      </tr>
                    ))}
                </>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
