import React from "react";

const UcrRecordationSection = (props) => {
  const { inputReduxValues, imageUrl } = props;
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <div className="content-text text-only-size">
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Recordation of MARK{" "}
            {imageUrl ? (
              <img
                src={imageUrl}
                alt={`Mark`}
                className="ms-1"
                style={{
                  width: "80px",
                  height: "80px",
                }}
              />
            ) : (
              <span className="email-content-span-heading ">
                {inputReduxValues?.mark
                  ? inputReduxValues?.mark
                  : "{Matter.Title}"}
              </span>
            )}{" "}
            with U.S. Customs and Border Protection
          </span>
          <br></br>
          Please note that U.S. Customs & Border Protection (CBP), a bureau of
          the Department of Homeland Security, maintains a trademark recordation
          system for marks registered at the U.S. Patent and Trademark Office.
          Parties who register their marks on the Principal Register may record
          these marks with CBP, to assist CBP in its efforts to prevent the
          importation of good(s) that infringe registered marks. The recordation
          database includes information regarding all recorded marks, including
          images of these marks. CBP officers who actively monitor imports to
          prevent the importation of good(s) bearing infringing registered marks
          can view the recordation database at each of the ports of entry where
          these officers work.
          <br></br>
          If the{" "}
          {imageUrl ? (
            <img
              src={imageUrl}
              alt={`Mark`}
              className="ms-1"
              style={{
                width: "80px",
                height: "80px",
              }}
            />
          ) : (
            <span className="email-content-span highlight-field-class">
              {inputReduxValues?.mark
                ? inputReduxValues?.mark
                : "{Matter.Title}"}
            </span>
          )}{" "}
          mark should be recorded with the CBP, or you would like us to provide
          you with additional information, please let us know.
        </div>
      </div>
    </>
  );
};

export default UcrRecordationSection;
