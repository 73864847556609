import React from "react";
import Button from "../common/button";
import "./reference-popup-index.scss";

const Popup = ({
  onClose,
  message,
  subMessage,
  buttons,
  isExportPopup = false,
}) => {
  return (
    <div className="popup-overlay">
      <div className="ref-popup">
        <div className="message">
          <span>{message}</span>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div
          className="d-flex submessage py-4"
          style={
            isExportPopup === true
              ? { padding: "0", justifyContent: "center" }
              : {}
          }
        >
          {subMessage}
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center py-4">
          {buttons.map((btn, index) => (
            <Button
              key={index}
              className={`reference-popup-btn ${btn.className || ""} mx-2`}
              children={btn.label}
              onClick={btn.onClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Popup;
