import React from "react";

const UcrNoticeSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <span className="content-text">
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Notice of Registration
          </span>
          <br></br>
          Now that the mark has been registered, we recommend that you indicate
          registration by placing the “®” designator adjacent to the trademark
          as it is used on your good(s) and service(s), in advertising, and on
          promotional material. Notice of registration on the good(s) and
          service(s) is necessary to collect damages in an infringement action
          and is helpful in establishing diligent use of the mark should it ever
          be challenged.
        </span>
      </div>
    </>
  );
};

export default UcrNoticeSection;
