import { createSlice, createAction } from "@reduxjs/toolkit";


export const revertAll = createAction("REVERT_ALL");

const initialState = {
  type: "",
  number: "",
  extractedData: [],
  formattedInitialRefData: [],
  extractedExcelData: [],
  extractedNplFileUploadData: [],
  docketNumber: "",
  radioOption: "",
  filesUploaded: [],
  additionalRefInput: "",
  correspondingFamInput: "",
  addRefData: [],
  canEdit: false,
  canAddRow: "",
  formattedIdsData: [],
  formattedIdsSortedData: [],
  discardedIdsData: [],
  selectedRefData: [],
  selectedNplData: [],
  activeReduxTab: "",
  discardedNplData: [],
  extractedNplData: [],
  familyNplData: [],
  formattedNplData: [],
  popupNumber: "",
  popupNewIdsNumber: "",
  popupIdsType: "",
  popupNewIdsType: "",
  popupDocketNumber: "",
  popupOpenClick: false,
  isNPLFileUploaded: false,
  canTopEdit: false
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(revertAll, () => initialState)
      .addMatcher(
        (action) => action.type === "search/setSearchParameters",
        (state, action) => {
          if (state.radioOption !== action.payload.radioOption) {
            return initialState;
          }
          state.type = action.payload.type;
          state.number = action.payload.number;
          state.radioOption = action.payload.radioOption;
          state.filesUploaded = action.payload.filesUploaded;
          state.correspondingFamInput = action.payload.correspondingFamInput;
          state.additionalRefInput = action.payload.additionalRefInput;
        }
      );
  },
  reducers: {
    setSearchParameters: (state, action) => {
      // const filesUploaded = action.payload.filesUploaded.map(file => ({
      //   name: file.name,
      //   size: file.size,
      //   type: file.type,
      // }));
      state.type = action.payload.type;
      state.number = action.payload.number;
      state.radioOption = action.payload.radioOption;
      state.filesUploaded = action.payload.filesUploaded;
      state.correspondingFamInput = action.payload.correspondingFamInput;
      state.additionalRefInput = action.payload.additionalRefInput;
    },
    setExtractedData: (state, action) => {
      state.extractedData = action.payload;
    },
    setFormattedInitialRefData: (state, action) => {
      state.formattedInitialRefData = action.payload;
    },
    setExtractedNplFileUploadData: (state, action) => {
      state.extractedNplFileUploadData = action.payload;
    },
    setExtractedExcelData: (state, action) => {
      state.extractedExcelData = action.payload;
    },
    setDocketNumber: (state, action) => {
      state.docketNumber = action.payload;
    },
    setAddRefData: (state, action) => {
      state.addRefData = action.payload;
    },
    setCanEdit: (state, action) => {
      state.canEdit = action.payload;
    },
    setCanTopEdit: (state, action) => {
      state.canTopEdit = action.payload;
    },
    setCanAddRow: (state, action) => {
      state.canAddRow = action.payload;
    },
    setFormattedIdsData: (state, action) => {
      state.formattedIdsData = action.payload;
    },
    setFormattedIdsSortedData: (state, action) => {
      state.formattedIdsSortedData = action.payload;
    },
    setDiscardedIdsData: (state, action) => {
      state.discardedIdsData = action.payload;
    },
    setSelectedRefData: (state, action) => {
      state.selectedRefData = action.payload;
    },
    setSelectedNplData: (state, action) => {
      state.selectedNplData = action.payload;
    },
    setActiveReduxTab: (state, action) => {
      state.activeReduxTab = action.payload;
    },
    setDiscardedNplData: (state, action) => {
      state.discardedNplData = action.payload;
    },
    setExtractedNplData: (state, action) => {
      state.extractedNplData = action.payload;
    },
    setFamilyNplData: (state, action) => {
      state.familyNplData = action.payload;
    },
    setFormattedNplData: (state, action) => {
      state.formattedNplData = action.payload;
    },
    setPopupNumber: (state, action) => {
      state.popupNumber = action.payload;
    },
    setPopupNewIdsNumber: (state, action) => {
      state.popupNewIdsNumber = action.payload;
    },
    setPopupIdsType: (state, action) => {
      state.popupIdsType = action.payload;
    },
    setPopupNewIdsType: (state, action) => {
      state.popupNewIdsType = action.payload;
    },
    setPopupDocketNumber: (state, action) => {
      state.popupDocketNumber = action.payload;
    },
    setPopupOpenClick: (state, action) => {
      state.popupOpenClick = action.payload;
    },
    setNPLFileUploaded: (state, action) => {
      state.isNPLFileUploaded = action.payload;
    },
  },
});

export const {
  setSearchParameters,
  setExtractedData,
  setFormattedInitialRefData,
  setExtractedExcelData,
  setExtractedNplFileUploadData,
  setDocketNumber,
  setAddRefData,
  setCanEdit,
  setCanTopEdit,
  setCanAddRow,
  setFormattedIdsData,
  setFormattedIdsSortedData,
  setDiscardedIdsData,
  setSelectedRefData,
  setSelectedNplData,
  setActiveReduxTab,
  setDiscardedNplData,
  setExtractedNplData,
  setFamilyNplData,
  setFormattedNplData,
  setPopupNumber,
  setPopupNewIdsNumber,
  setPopupIdsType,
  setPopupNewIdsType,
  setPopupDocketNumber,
  setPopupOpenClick,
  setNPLFileUploaded,
} = searchSlice.actions;
export default searchSlice.reducer;
