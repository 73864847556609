import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./rsr.scss";
import RsrParamSection from "../../../components/RL/rsr-sections/rsr-param-section";
import RsrEmailSection from "../../../components/RL/rsr-sections/rsr-email-section";

const RSR = () => {
  return (
    <div className="rsr-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">
          Renewal Due US Service Mark Registration
        </div>
        <div className="d-flex flex-row">
          <RsrParamSection />
          <RsrEmailSection />
        </div>
      </div>
    </div>
  );
};

export default RSR;
