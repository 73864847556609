import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../common/initial-references-table';
import { setFormattedInitialRefData } from '../../../../store/reducer/searchSlice';
import { highlightText } from '../../../../utils/highlight';
import { useGetSavedInitialRefByUserQuery } from '../../../../store/action/initialReferencesApi';
import { formatSavedDate } from '../../../../util/utils';
import { useLocation } from "react-router-dom";
const InitialReferences = ({ searchTerm, selectedColumn }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { number: pubNum } = state || {};
  const { extractedData: dataTable } = useSelector((state) => state.search);
  const number = useSelector((state) => state.search.number)
  const extractedPdfData = useSelector((state) => state.search.extractedPdfData)
  const userNumber = sessionStorage.getItem("userId")
  const { data } = useGetSavedInitialRefByUserQuery({ userId: userNumber, num: number !== '' ? number : pubNum })
  const savedInitialRefArray = data ? [...data] : []
  const [addInitialRefRowData, setAddInitialRefRowData] = useState([])
  const [updateInitialRefRowData, setUpdateInitialRefRowData] = useState([])
  const handleUpdateInitialRefRowData = (data) => {
    setUpdateInitialRefRowData(data)
  }
  const handleAddInitialRefRowData = (data) => {
    setAddInitialRefRowData(data)
  }

  const isPlainObject = (value) => {
    return (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value) &&
      value.constructor === Object
    );
  }

  const filteredDataTable = useMemo(() => {
    return (dataTable || []).filter(row => {
      const type = row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell']?.['uscom:Heading'] || row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell'] || '';
      const type1 = row?.['uscom:TableRow'];
      return type !== 'NON-PATENT LITERATURE DOCUMENTS' && type !== 'Application Number' && !Array.isArray(type) && isPlainObject(type) === false && isPlainObject(type1) === false;
    });

  }, [dataTable]);

  const formatCells = (cells, headers) => {
    return headers.reduce((acc, header, index) => {
      const cell = cells[index] || {};
      const text = cell['uscom:TextInput']?.['__text'] || cell['uscom:TextInput'] || '';
      return { ...acc, [header]: text };
    }, {});
  };
  const extractTextInputValue = (input) => {
    if (typeof input === 'string' || typeof input === 'number') {
      return input;
    }
    if (input && typeof input === 'object' && '#text' in input === false) {
      return input['uscom:OCRConfidenceData'];
    }
    if (input && typeof input === 'object' && '#text' in input) {
      if ((typeof input['#text'] === 'number' || typeof input['#text'] === 'string') && Array.isArray(input['uscom:OCRConfidenceData']) === false) {
        return `${input['uscom:OCRConfidenceData']}${input['#text']}`
      }
      if ((typeof input['#text'] === 'number' || typeof input['#text'] === 'string') && Array.isArray(input['uscom:OCRConfidenceData']) === true) {
        return `${input['#text']} ${input['uscom:OCRConfidenceData'].join(" ")}`
      }
      return input['#text'];
    }
    return '';
  };
  const extractExtraTextInputValue = (input, cells) => {
    if (typeof input === 'string' || typeof input === 'number') {
      return input;
    }
    if (input && typeof input === 'object' && '#text' in input === false) {
      return input['uscom:OCRConfidenceData'];
    }
    if (input && typeof input === 'object' && '#text' in input) {
      if ((typeof input['#text'] === 'number' || typeof input['#text'] === 'string') && Array.isArray(input['uscom:OCRConfidenceData']) === false) {
        return `${input['uscom:OCRConfidenceData']}${input['#text']}`
      }
      if ((typeof input['#text'] === 'number' || typeof input['#text'] === 'string') && Array.isArray(input['uscom:OCRConfidenceData']) === true) {
        return `${input['#text']} ${input['uscom:OCRConfidenceData'].join(" ")}`
      }
      return input['#text'];
    }
    return '';
  };

  const isArrayValue = (value) => Array.isArray(value) && value.length > 0;

  const filteredExtraData = filteredDataTable?.filter(row => !row?.['uscom:TableRow']?.[0]?.['uscom:TableHeaderCell']);

  const filteredDataWithoutObject = filteredExtraData?.filter(row => isArrayValue(row?.['uscom:TableRow']?.[0]?.['uscom:TableDataCell']) === true);

  const filteredFinalData = filteredDataWithoutObject?.filter(row => isPlainObject(row?.['uscom:TableRow']?.[0]?.['uscom:TableDataCell']?.[0]) === false);

  const formattedExtraData = filteredFinalData.flatMap((row) => {
    const tableDataCells = row?.['uscom:TableRow'];
    return tableDataCells.filter(dataCell => isArrayValue(dataCell['uscom:TableDataCell']) && typeof dataCell['uscom:TableDataCell']?.[0] !== 'object').map((dataCell) => {
      const cells = dataCell['uscom:TableDataCell'] || [];
      const defaultExtraData = {
        "Publication / Application Number": extractExtraTextInputValue(cells[2]?.['uscom:TextInput']).slice(0, 1) === 'W' && extractTextInputValue(cells[2]?.['uscom:TextInput']).slice(1, 2) !== 'O' ? `WO${extractTextInputValue(cells[2]?.['uscom:TextInput']).slice(1)}` : extractTextInputValue(cells[2]?.['uscom:TextInput']),
        "Kind Code": extractExtraTextInputValue(cells[3]?.['uscom:TextInput']),
        "Issue / Publication Date": extractExtraTextInputValue(cells[4]?.['uscom:TextInput']),
        "Patentee": extractExtraTextInputValue(cells[5]?.['uscom:TextInput']),
        "IDS Form Section Heading": extractTextInputValue(cells[2]?.['uscom:TextInput']).slice(0, 2) !== "US" ? 'FOREIGN PATENT DOCUMENTS' : 'U.S.PATENT APPLICATION PUBLICATIONS',
      };
      if (
        defaultExtraData["Publication / Application Number"] === '' &&
        defaultExtraData["Kind Code"] === '' &&
        defaultExtraData["Issue / Publication Date"] === '' &&
        defaultExtraData["Patentee"] === ''
      ) {
        return null;
      }
      return defaultExtraData
    }).filter(Boolean);
  })

  const formattedData = useMemo(() => {
    return filteredDataTable.flatMap((row, index) => {
      const tableHeader = row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell']?.['uscom:Heading']
        || row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell'] || "";
      const tableHeaders = row?.['uscom:TableRow'][1]?.['uscom:TableHeaderCell'] || [];
      const tableDataCells = row?.['uscom:TableRow'].slice(2) || [];
      return tableDataCells.filter(dataCell => isArrayValue(dataCell['uscom:TableDataCell']) && typeof dataCell['uscom:TableDataCell']?.[0] !== 'object').map((dataCell) => {
        const cells = dataCell['uscom:TableDataCell'] || [];
        const data = formatCells(cells, tableHeaders);
        switch (tableHeader) {
          case 'FOREIGN PATENT DOCUMENTS':
            const defaultForeignData = {
              "Publication / Application Number": extractTextInputValue(cells[2]?.['uscom:TextInput']).slice(0, 1) === 'W' && extractTextInputValue(cells[2]?.['uscom:TextInput']).slice(1, 2) !== 'O' ? `WO${extractTextInputValue(cells[2]?.['uscom:TextInput']).slice(1)}` : extractTextInputValue(cells[2]?.['uscom:TextInput']),
              "Kind Code": extractTextInputValue(cells[4]?.['uscom:TextInput']),
              "Issue / Publication Date": extractTextInputValue(cells[5]?.['uscom:TextInput']),
              "Patentee": extractTextInputValue(cells[6]?.['uscom:TextInput']),
              "IDS Form Section Heading": tableHeader,
            };
            if (
              defaultForeignData["Publication / Application Number"] === '' &&
              defaultForeignData["Kind Code"] === '' &&
              defaultForeignData["Issue / Publication Date"] === '' &&
              defaultForeignData["Patentee"] === ''
            ) {
              return null;
            }
            return defaultForeignData
          case 'U.S.PATENTS':
            const defaultUsData = {
              "Publication / Application Number": cells[2] ? `US${extractTextInputValue(cells[2]['uscom:TextInput'])}` : '',
              "Kind Code": extractTextInputValue(cells[3]?.['uscom:TextInput']),
              "Issue / Publication Date": extractTextInputValue(cells[4]?.['uscom:TextInput']),
              "Patentee": extractTextInputValue(cells[5]?.['uscom:TextInput']),
              "IDS Form Section Heading": tableHeader,
            }
            if (
              defaultUsData["Publication / Application Number"] === '' &&
              defaultUsData["Kind Code"] === '' &&
              defaultUsData["Issue / Publication Date"] === '' &&
              defaultUsData["Patentee"] === ''
            ) {
              return null;
            }
            return defaultUsData
          default:
            const defaultData = {
              "Publication / Application Number": data["Publication Number"] ? `US${data["Publication Number"]}` : '',
              "Kind Code": data["Kind Code"] || '',
              "Issue / Publication Date": data["Publication Date"] || '',
              "Patentee": data["Name of Patentee or Applicant of cited Document"] || '',
              "IDS Form Section Heading": tableHeader,
            };
            if (
              defaultData["Publication / Application Number"] === '' &&
              defaultData["Kind Code"] === '' &&
              defaultData["Issue / Publication Date"] === '' &&
              defaultData["Patentee"] === ''
            ) {
              return null;
            }
            return defaultData;
        }
      }).filter(Boolean);
    });
  }, [filteredDataTable]);

  const headers = [
    "Sr.No",
    "Publication / Application Number",
    "Kind Code",
    "Issue / Publication Date",
    "Patentee",
    "IDS Form Section Heading",
  ];
  const formattedSavedInitialRefData = savedInitialRefArray.map((member) => {
    return {
      "Publication / Application Number": member.pdf_ref_num !== "PlaceholderRefNum" ? member.pdf_ref_num : "",
      "Kind Code": member.pdf_kind_code !== "-" ? member.pdf_kind_code : "",
      "Issue / Publication Date": member.pdf_ip_date !== null ? formatSavedDate(member.pdf_ip_date) : "",
      "Patentee": member.pdf_patentee_name !== "-" ? member.pdf_patentee_name : "",
      "IDS Form Section Heading": member.pdf_type,
    };
  })

  const finalFormattedTotalData = [...formattedData, ...formattedExtraData, ...extractedPdfData]

  useEffect(() => {
    if (formattedSavedInitialRefData.length > 0) {
      dispatch(setFormattedInitialRefData(formattedSavedInitialRefData));
      return;
    }
    dispatch(setFormattedInitialRefData(finalFormattedTotalData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData, dispatch]);

  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const defaultData = Array.from({ length: 10 }, () => defaultRow);
  const combinedData = updateInitialRefRowData.length > 0 ? addInitialRefRowData.length > 0 ? [...updateInitialRefRowData, ...addInitialRefRowData] : [...updateInitialRefRowData] : formattedSavedInitialRefData.length > 0 ? [...formattedSavedInitialRefData, ...addInitialRefRowData] : [...finalFormattedTotalData, ...addInitialRefRowData]
  const highlightedData = combinedData.map((row, index) => ({
    ...row,
    "Sr.No": index + 1,
    "Publication / Application Number": highlightText(row["Publication / Application Number"], searchTerm, selectedColumn, 'Serial #'),
  }));

  return (
    <div className="initial-ref">
      <Table headers={headers} data={highlightedData.length > 0 ? highlightedData : defaultData} handleUpdateInitialRefRowData={handleUpdateInitialRefRowData} handleAddInitialRefRowData={handleAddInitialRefRowData} />
    </div>
  );
}

export default InitialReferences;