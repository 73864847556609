import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./schedule-mail-popup.scss";
import Button from "../../common/button";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/images/create-letter-close.png";
import DropdownImage from "../../../assets/images/create-letter-select-dropdown.png";
import LabelImage from "../../../assets/images/schedule-mail-label.png";
import CalendarImage from "../../../assets/images/schedule-mail-calendar-icon.png";
import ClockImage from "../../../assets/images/schedule-mail-clock.png";
import { components } from "react-select";
import Select from "react-select";
import Input from "../../common/input";
import ScheduleMailCalendar from "../schedule-mail-calendar/schedule-mail-calendar";
import { setIsScheduleMailPopupOpen } from "../../../store/reducer/templateSlice";

const ScheduleMailPopup = ({ onClose }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const scheduleMaildropdownOptions = [
    { value: "0800", label: "8:00 AM", disabled: false },
    { value: "0830", label: "8:30 AM", disabled: false },
    { value: "0900", label: "9:00 AM", disabled: false },
    { value: "0930", label: "9:30 AM", disabled: false },
    { value: "1000", label: "10:00 AM", disabled: false },
  ];

  const [scheduleTimeOption, setScheduleTimeOption] = useState({
    value: "1000",
    label: "10:00 AM",
    disabled: false,
  });

  const scheduledDate = useSelector((state) => state.template.scheduledDate);

  const handleTimeChange = (option) => {
    setScheduleTimeOption(option);
  };

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "rgba(101, 101, 101, 1)",
      fontWeight: "500",
      height: "33px",
      alignItems: "center",
      marginLeft: "-0.2rem",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      border: "1px solid black",
      borderRadius: "5px",
      height: "37px",
      color: "rgba(0, 0, 0, 1)",
      textAlign: "left",
      fontFamily: "Poppins",
      fontSize: "13px",
      lineHeight: "17px",
      fontWeight: "600",
      paddingBottom: "15px",
      alignItems: "center",
    }),
    menu: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 1)",
      lineHeight: "17px",
      fontFamily: "Poppins",
      fontSize: "13px",
      fontWeight: "600",
      borderRight: "1px solid rgba(40, 59, 124, 1)",
      borderLeft: "1px solid rgba(40, 59, 124, 1)",
      borderBottom: "1px solid rgba(40, 59, 124, 1)",
      borderTop: "1px solid rgba(40, 59, 124, 1)",
      borderRadius: "7px",
      textAlign: "left",
      marginTop: "-0.1rem",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: "115px",
      overflowY: "auto",
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      borderTop: "1px solid rgba(153, 153, 153, 1)",
      color: "rgba(0, 0, 0, 1)",
      backgroundColor: isFocused ? "#1967D2" : "",
      cursor: "pointer",
      paddingLeft: "0.3rem",
      fontWeight: "400",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(101, 101, 101, 1)",
      fontFamily: "Poppins",
      fontSize: "13px",
      lineHeight: "17px",
      fontWeight: "500",
      height: "33px",
      marginLeft: "-0.2rem",
    }),
    indicatorSeparator: () => ({
      width: "0px",
    }),
    indicatorsContainer: () => ({
      height: "37px",
    }),
  };

  const selectDropdownIndicator = (props) => {
    const {
      selectProps: { menuIsOpen },
    } = props;

    const rotateClass = menuIsOpen ? "rotate-dropdown-180" : "";

    return (
      <components.DropdownIndicator {...props}>
        <img
          src={DropdownImage}
          className={`select-img ${rotateClass}`}
          alt="Select Dropdown"
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="schedule-mail-popup-overlay">
      <div className="schedule-mail-popup d-flex flex-column align-items-center justify-content-center">
        <div className="message">
          <span>Schedule Email</span>
          <img
            src={CloseIcon}
            onClick={() => {
              onClose();
            }}
            className="close-button close-icon-cross"
            alt="cross"
          />
        </div>
        <div className="d-flex submessage py-2 flex-column align-items-center">
          <div className="d-flex flex-row align-items-start justify-content-center schedule-content">
            <div className="calendar-section">
              <ScheduleMailCalendar />
            </div>
            <div className="d-flex flex-column schedule-section mx-1 align-items-center">
              <div className="d-flex flex-column schedule-input-section">
                <div className="d-flex flex-row align-items-center mt-2">
                  <img
                    src={LabelImage}
                    className="schedule-img me-2"
                    alt="Label"
                  />
                  <Input
                    className={`mx-1 px-1 schedule-input`}
                    placeholder="Label"
                  />
                </div>
                <div className="d-flex flex-row align-items-center mt-2">
                  <img
                    src={CalendarImage}
                    className="schedule-img me-2"
                    alt="Calendar"
                  />
                  <Input
                    className={`mx-1 px-1 schedule-input`}
                    placeholder="Date"
                    value={scheduledDate}
                    disabled
                  />
                </div>
                <div className="d-flex flex-row align-items-center mt-2">
                  <img
                    src={ClockImage}
                    className="schedule-img me-2"
                    alt="Clock"
                  />
                  <Select
                    options={scheduleMaildropdownOptions}
                    value={scheduleTimeOption}
                    onChange={handleTimeChange}
                    className={`mx-1`}
                    classNamePrefix={"select-time"}
                    placeholder={"10:00 AM"}
                    styles={selectStyles}
                    components={{
                      DropdownIndicator: selectDropdownIndicator,
                    }}
                    isSearchable={false}
                  />
                </div>
              </div>
              {/* <div className="d-flex flex-column add-schedule-section mt-2">
                +Add new Schedule
              </div> */}
            </div>
          </div>
          <div className="d-flex flex-row schedule-mail-btn-container justify-content-end mt-1 py-1">
            <Button
              className={`schedule-mail-btn-close-style mx-2`}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              className={`schedule-mail-btn-schedule-style mx-2`}
              onClick={() => {
                dispatch(setIsScheduleMailPopupOpen(false));
              }}
            >
              Schedule
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleMailPopup;
