import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./rtr.scss";
import RtrParamSection from "../../../components/RL/rtr-sections/rtr-param-section";
import RtrEmailSection from "../../../components/RL/rtr-sections/rtr-email-section";

const RTR = () => {
  return (
    <div className="rtr-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">Renewal Due Trademark Registration</div>
        <div className="d-flex flex-row">
          <RtrParamSection />
          <RtrEmailSection />
        </div>
      </div>
    </div>
  );
};

export default RTR;
