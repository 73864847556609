import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const CirTemplateHeader = (props) => {
  const { inputReduxValues, title, handleCopyTemplate, contentRef, imageUrl } =
    props;
  return (
    <div className="d-flex flex-row justify-content-between sub-cert-section align-items-start py-1">
      <span className="d-flex flex-row justify-content-center">
        <span className="d-flex flex-row justify-content-center">
          <span>Subject Line:</span>
          {
            <span className="mx-2 matter-span matter-title-span highlight-field-class">
              {inputReduxValues?.subjectMark
                ? inputReduxValues?.subjectMark
                : "{Matter.title}-"}
            </span>
          }
        </span>
        <span
          className="d-flex flex-row justify-content-center mx-1"
          style={{ marginLeft: "5px" }}
        >
          <span className="cert-reg">{title}</span>
          <span className="mx-1 matter-span matter-aref-span ps-1 highlight-field-class">
            {inputReduxValues?.fileNo
              ? inputReduxValues?.fileNo
              : "{Matter.AttorneyRef}"}
          </span>
        </span>
      </span>
      <div
        className="d-flex flex-row align-items-center copy-icon-container"
        onClick={() => handleCopyTemplate(contentRef)}
      >
        <FontAwesomeIcon
          icon={faCopy}
          className="copy-img"
          title="Copy Template Content"
        />
      </div>
    </div>
  );
};

export default CirTemplateHeader;
