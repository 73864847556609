import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_GENERATOR_URL } from "../../utils/constants";

export const generateApi = createApi({
    reducerPath: "generateApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_GENERATOR_URL }),
    endpoints: (builder) => ({
        uploadXml: builder.mutation({
            query: ({ data, uid, pubNumber }) => ({
                url: `upload-xml/${uid}/${pubNumber}`,
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/xml',
                },
            }),
        }),
    }),
});

export const { useUploadXmlMutation } = generateApi;
export default generateApi;
