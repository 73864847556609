export const formattedCopiedText = (text) => {
  const lines = text.split("\n");

  const filteredLines = lines.filter((line) => {
    return !(
      line.includes("Copy Template Content") ||
      line.includes("Discard") ||
      line.includes("Attach Signature") ||
      line.includes("Attach file") ||
      line.includes("Download email") ||
      line.includes("Save email")
    );
  });
  const formattedText = filteredLines.join("\n");

  return formattedText;
};

export const handleCopyTemplate = (contentRef) => {
  const copiedText = contentRef.current.innerText;
  const resultText = formattedCopiedText(copiedText);
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(resultText)
      .then(() => {
        alert("Template Text Copied!");
      })
      .catch((error) => {
        console.error("Clipboard write failed: ", error);
        alert("Failed to copy text!");
      });
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = resultText;
    document.body.appendChild(textArea);
    textArea.select();
    const successful = document.execCommand("copy");
    document.body.removeChild(textArea);
    if (successful) {
      alert("Template Text Copied!");
    } else {
      alert("Failed to copy text!");
    }
  }
};
