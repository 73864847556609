// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/welcome-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sgp-template-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  height: auto;
  width: 100%;
  font-family: "Helvetica", sans-serif;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -2.5rem;
  padding-top: 2%;
}`, "",{"version":3,"sources":["webpack://./src/pages/RL/SGP/sgp.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,4BAAA;EACA,YAAA;EACA,WAAA;EACA,oCAAA;EACA,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,mBAAA;EACA,eAAA;AACF","sourcesContent":[".sgp-template-container {\n  background-image: url(\"../../../assets/images/welcome-background.png\");\n  background-repeat: no-repeat;\n  height: auto;\n  width: 100%;\n  font-family: \"Helvetica\", sans-serif;\n  z-index: -1;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  margin-top: -2.5rem;\n  padding-top: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
