import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./aer.scss";
import AerParamSection from "../../../components/RL/aer-sections/aer-param-section";
import AerEmailSection from "../../../components/RL/aer-sections/aer-email-section";

const AER = () => {
  return (
    <div className="aer-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">
          Notice of Approval of Extension Request
        </div>
        <div className="d-flex flex-row">
          <AerParamSection />
          <AerEmailSection />
        </div>
      </div>
    </div>
  );
};

export default AER;
