import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./dashboard.scss";
import FormBack from "../../assets/images/dashboard-back-button.png";
import SearchInForm from "../../assets/images/search-in-from.svg";
import Table from "../../components/common/dashboard-table";
import Button from "../../components/common/button";
import { useGetSavedDataByUserQuery } from "../../store/action/adminApi";
import { formatSavedDate } from "../../util/utils";
import SelectPopup from "../../components/popup/select-ids-popup";
import SharePopup from "../../components/popup/send-on-mail-popup";
import {
  setSelectIdsToggle,
  setSharePopupToggle,
} from "../../store/reducer/adminSlice";
import { formatCurrentDate } from "../../util/utils";
import { highlightText } from "../../utils/highlight";
const Dashboard = () => {
  const number = useSelector((state) => state.search.number);
  const selectIdsToggle = useSelector((state) => state.admin.selectIdsToggle);
  const sharePopupToggle = useSelector((state) => state.admin.sharePopupToggle);
  const userNumber = sessionStorage.getItem("userId");
  const { data } = useGetSavedDataByUserQuery({ userId: userNumber });
  const dispatch = useDispatch();
  const adminData = data ? [...data] : [];
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("Patent Family");
  const handleSearchClick = () => {
    setIsSearchActive(true);
  };
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearchClose = () => {
    setIsSearchActive(false);
    setSearchTerm("");
  };

  const headers = [
    "Sr.No",
    "Patent Family",
    "Attorney Docket No.",
    "Type",
    "Date",
    // "Email",
    "Status",
    "Action",
    "Filing Status",
  ];
  const navigate = useNavigate();
  // const defaultRow = headers.reduce(
  //   (acc, header) => ({ ...acc, [header]: "" }),
  //   {}
  // );
  const handleClick = () => {
    navigate("/search");
  };
  const handleResumeClick = () => {
    navigate("/ids");
  };
  const handleSelectClose = () => {
    dispatch(setSelectIdsToggle(false));
  };
  const handleShareClose = () => {
    dispatch(setSharePopupToggle(false));
  };
  const formattedAdminData = adminData.map((member) => {
    return {
      Id_num: member.Id_num,
      "Patent Family": member.family_mem,
      "Attorney Docket No.": member.docket_num,
      Type: member.ids_type,
      Date: formatSavedDate(member.curr_date),
      Status: (
        <span
          className={`${
            member.status === "COMPLETED"
              ? "status-text-green"
              : "status-text-red"
          }`}
        >
          {member.status}
        </span>
      ),
      "Filing Status": member.filing_status,
    };
  });
  const formattedSrAdminData = formattedAdminData.map((item, index) => ({
    ...item,
    "Sr.No": index + 1,
  }));
  const highlightedData = formattedSrAdminData.map((row, index) => ({
    ...row,
    "Patent Family": highlightText(
      row["Patent Family"],
      searchTerm,
      selectedColumn,
      "Patent Family"
    ),
    "Attorney Docket No.": highlightText(
      row["Attorney Docket No."],
      searchTerm,
      selectedColumn,
      "Docket No."
    ),
  }));
  // const defaultData = Array.from({ length: 12 }, () => defaultRow);
  return (
    <div className="dashboard-container">
      {selectIdsToggle === true && <SelectPopup onClose={handleSelectClose} />}
      {sharePopupToggle === true && <SharePopup onClose={handleShareClose} />}
      <div className="card card-2">
        <div className="tab-container d-flex">
          <div className="dashboard-title">Dashboard</div>
          <div className="icons">
            {number !== "" && (
              <Button
                className={"create-ids-btn"}
                children="Resume IDS"
                onClick={handleResumeClick}
                title="Resume Previous IDS"
              />
            )}
            <Button
              className={"create-ids-btn"}
              children="Create IDS"
              onClick={handleClick}
              title="Create New IDS"
            />
            {isSearchActive && (
              <div className="search-container">
                <select
                  value={selectedColumn}
                  onChange={(e) => setSelectedColumn(e.target.value)}
                >
                  <option value="Patent Family">Patent Family</option>
                  <option value="Docket No.">Docket No.</option>
                </select>

                <input
                  type="text"
                  placeholder={`${selectedColumn}`}
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
                <button
                  alt="Close Search Dialog"
                  onClick={handleSearchClose}
                  className="icon p-0"
                >
                  x
                </button>
              </div>
            )}
            {!isSearchActive && (
              <img
                src={SearchInForm}
                alt="Search In Form"
                className="icon"
                title="Search among the Cases"
                onClick={handleSearchClick}
              />
            )}
            {/* <img src={FormBack} alt="Form Back" className="icon" /> */}
          </div>
        </div>
        <Table
          headers={headers}
          data={highlightedData}
          searchTerm={searchTerm}
        />
      </div>
      <div className="d-flex flex-row login-stamp">
        <span className="login-stamp-text">Last Login Time stamp:</span> &nbsp;
        {formatCurrentDate()}
      </div>
    </div>
  );
};

export default Dashboard;
