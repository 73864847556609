import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./download-option-popup.scss";
import CloseIcon from "../../../assets/images/create-letter-close.png";
import CancelPopupIcon from "../../../assets/images/cancel-template-popup-icon.png";
import Button from "../../common/button";
import { setIsDownloadPopupOpen } from "../../../store/reducer/templateSlice";
import { handleDownloadPdf } from "../common/download-pdf";
import { handleDownloadWord } from "../common/download-docx";
import { generateEML } from "../common/download-eml";

const DownloadOptionPopup = (props) => {
  const { onClose, contentRef, caseTemplate } = props;

  const templateFiles = useSelector((state) => state.template.templateFiles);
  const templateSignFiles = useSelector(
    (state) => state.template.templateSignFiles
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handlePdfClick = () => {
    dispatch(setIsDownloadPopupOpen(false));
    handleDownloadPdf(contentRef, caseTemplate);
  };

  const handleWordClick = () => {
    dispatch(setIsDownloadPopupOpen(false));
    handleDownloadWord(contentRef, caseTemplate);
  };

  const handleEmlClick = () => {
    dispatch(setIsDownloadPopupOpen(false));
    generateEML(contentRef, caseTemplate, templateFiles, templateSignFiles);
  };

  return (
    <div className="download-option-popup-overlay">
      <div className="download-option-popup d-flex flex-column align-items-center justify-content-center">
        <div className="message">
          <span>Download Mail</span>
          <img
            src={CloseIcon}
            onClick={onClose}
            className="close-button close-icon-cross"
            alt="cross"
          />
        </div>
        <div className="d-flex submessage py-2 flex-column align-items-center">
          <div className="save-temp-text py-3">
            Please select download format.
          </div>
          <div className="d-flex flex-row download-option-btn-container mt-3 justify-content-center py-2">
            <Button
              className={`download-option-btn-cancel-style mx-2`}
              onClick={handlePdfClick}
            >
              Download PDF
            </Button>
            <Button
              className={`download-option-btn-cancel-style mx-2`}
              onClick={handleWordClick}
            >
              Download Word
            </Button>
            <Button
              className={`download-option-btn-cancel-style mx-2`}
              onClick={handleEmlClick}
            >
              Download EML
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadOptionPopup;
