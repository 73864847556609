import React from "react";
import Button from "../../common/button";
import "./reference-popup-index.scss";

const Popup = ({ onClose, handleNoClick, handleYesClick }) => {
  return (
    <div className="popup-overlay">
      <div className="ref-popup">
        <div className="message">
          <span>Discard Initial Citations</span>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div className="d-flex submessage py-4">
          Do you want to discard Initial Citations from New Citations?
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center py-4">
          <Button
            className={"reference-popup-btn mx-2"}
            children="Yes"
            onClick={handleYesClick}
          />
          <Button
            className={"reference-popup-btn popup-no-btn mx-2"}
            children="No"
            onClick={handleNoClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
