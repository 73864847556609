import React from "react";

const UcrLocalSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <div className="content-text text-only-size">
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Local Use of Conflicting Marks
          </span>
          <br></br>
          Please also note that, while many benefits flow from registration of
          your mark, trademark rights arise from{" "}
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            use
          </span>{" "}
          of the mark. It is therefore possible that others may have conflicting
          and superior rights to confusingly similar marks, at least in some
          geographical area of the U.S. At the end of five years, and upon
          filing a declaration, this registration will become “incontestable,”
          whereupon you will have good assurance that you will retain the mark
          for the long term.
        </div>
      </div>
    </>
  );
};

export default UcrLocalSection;
