import React from "react";

const UcrProperSection = (props) => {
  const { inputReduxValues, imageUrl } = props;
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <span className="content-text">
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Proper Trademark Use
          </span>
          <br></br>
          In order to build strong rights in the
          {imageUrl ? (
            <img
              src={imageUrl}
              alt={`Mark`}
              className="ms-1"
              style={{
                width: "80px",
                height: "80px",
              }}
            />
          ) : (
            <span className="email-content-span country-name-span ms-1 highlight-field-class">
              {inputReduxValues?.mark
                ? inputReduxValues?.mark
                : "{Matter.Title}"}
            </span>
          )}{" "}
          trademark, it is necessary for you to continue use of the mark on its
          good(s) in a distinctive manner. The registration which we have
          obtained confirms the claim of trademark which you establish by proper
          and extensive usage. It is important that any proposed packaging and
          labeling of products, advertising, and promotional materials be
          reviewed for proper trademark usage in order to continue building
          strong trademark rights.
          <br></br>
          Failure to maintain a trademark’s distinctiveness may result in loss
          of your trademark rights. Loss of distinctiveness occurs through
          improper use, improper licensing, or failure to restrain infringement
          of the mark by others. Your trademark rights can also be jeopardized
          if you discontinue use of the mark -- a trademark may be considered
          abandoned (and is subject to cancellation) if it is not used for two
          consecutive years.
          <br></br>
          To preserve its distinctiveness, your trademark should be indicated by
          use of italics, capitals, quotes, bold-face type or underlining, or
          design. It is also important to the distinctiveness of a trademark
          that it appear in the same form in which it was registered without any
          significant variation.
          <br></br>
          Generic use of a trademark may render the mark non-distinctive and
          should be avoided as it may lead to loss of your trademark rights or
          cancellation of your mark. Thus, a trademark should only be used as a
          proper adjective. It should never be used as the descriptive name of
          the product. Wherever possible, a trademark should be followed by or
          preceded with a word or words which identify the good(s) or service(s)
          for which it is registered. A trademark should never be pluralized,
          used in the possessive sense, or used as a verb (you don’t Xerox a
          Xerox on a Xerox, you produce a photocopy on a Xerox-brand copy
          machine).
        </span>
      </div>
    </>
  );
};

export default UcrProperSection;
