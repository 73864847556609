import { React, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import "./rl-tooltip.scss";
import TooltipHighlight from "../../../assets/images/rl-tooltip-highlighter.png";
import TooltipUnderline from "../../../assets/images/rl-tooltip-underline.png";
import TooltipItalic from "../../../assets/images/rl-tooltip-italic.png";
import TooltipColorUnderline from "../../../assets/images/rl-tooltip-color-underline.png";
import TooltipBold from "../../../assets/images/rl-tooltip-bold.png";
import Button from "../../common/button";

const RlTooltip = (props) => {
  const { contentRef, buttonRef } = props;
  const [localSelection, setLocalSelection] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [toolTipStyle, setToolTipStyle] = useState({
    position: "absolute",
    top: 0,
    left: 0,
  });

  const toolTipRef = useRef();
  const selectRef = useRef();

  const handleMouseUp = (event) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      setShowTooltip(false);
      return;
    }
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      setShowTooltip(false);
      return;
    }
    const selection = window.getSelection();
    if (
      !window.getSelection() ||
      window.getSelection().isCollapsed ||
      window.getSelection().rangeCount === 0
    ) {
      return;
    }
    if (
      selection?.getRangeAt(0).getBoundingClientRect().top === 0 &&
      selection?.getRangeAt(0).getBoundingClientRect().left === 0
    ) {
      return;
    }
    const selectedText = selection.toString();

    if (selectedText.trim() === "") {
      setShowTooltip(false);
      return;
    }

    setLocalSelection(selection);
    setToolTipStyle((prev) => ({
      ...prev,
      top: selection?.getRangeAt(0).getBoundingClientRect().top - 10,
      left: selection?.getRangeAt(0).getBoundingClientRect().left,
    }));
    setShowTooltip(true);
    getSelectionFontSize();
    getSelectionFontFamily();
  };

  const fontSizeOptions = [
    { value: "12", label: "12" },
    { value: "14", label: "14" },
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
    { value: "22", label: "22" },
    { value: "24", label: "24" },
  ];

  const [fontSize, setFontSize] = useState("12");

  const fontFamilyOptions = [
    { value: "Helvetica", label: "Helvetica" },
    { value: "Poppins", label: "Poppins" },
    { value: "Calibri", label: "Calibri" },
    { value: "Montserrat", label: "Montserrat" },
  ];

  const [fontFamily, setFontFamily] = useState(fontFamilyOptions[0].value);

  const fontFamilySelectStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: "rgba(13, 18, 22, 1)",
      fontWeight: "600",
      height: "50px",
      alignItems: "center",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      border: "1px solid rgba(53, 71, 90, 0.2)",
      borderRadius: "8px",
      minHeight: "20px",
      height: "20px",
      color: "rgba(0, 0, 0, 1)",
      textAlign: "left",
      fontFamily: "Montserrat",
      fontSize: "12px",
      lineHeight: "17px",
      fontWeight: "600",
      paddingBottom: "15px",
      alignItems: "center",
      boxShadow: "none",
      width: "70px",
      "&:hover": {
        borderColor: "rgba(53, 71, 90, 0.2)",
      },
    }),
    menu: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 1)",
      lineHeight: "17px",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "600",
      borderRight: "1px solid rgba(40, 59, 124, 1)",
      borderLeft: "1px solid rgba(40, 59, 124, 1)",
      borderBottom: "1px solid rgba(40, 59, 124, 1)",
      borderTop: "1px solid rgba(40, 59, 124, 1)",
      borderRadius: "7px",
      textAlign: "left",
      marginTop: "-0.1rem",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      borderTop: "1px solid rgba(153, 153, 153, 1)",
      color: "rgba(0, 0, 0, 1)",
      backgroundColor: isFocused ? "" : "",
      cursor: "pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 1)",
      fontFamily: "Montserrat",
      fontSize: "12px",
      lineHeight: "17px",
      fontWeight: "600",
      height: "20px",
    }),
    indicatorSeparator: () => ({
      width: "0px",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
  };

  const applyFontFamily = (family) => {
    try {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const span = document.createElement("span");

      span.style.fontFamily = family;
      range.surroundContents(span);
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    } catch (e) {
      console.error(e);
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    }
  };

  const rightFontFamily = () => {
    const currentIndex = fontFamilyOptions.findIndex(
      (option) => option.value === fontFamily
    );
    if (currentIndex < fontFamilyOptions.length - 1) {
      const newFamily = fontFamilyOptions[currentIndex + 1];
      const prevFamily = fontFamily;
      setFontFamily(newFamily.value);
      applyFontFamily(newFamily.value, prevFamily);
    }
  };

  const leftFontFamily = () => {
    const currentIndex = fontFamilyOptions.findIndex(
      (option) => option.value === fontFamily
    );
    if (currentIndex > 0) {
      const newFamily = fontFamilyOptions[currentIndex - 1];
      const prevFamily = fontFamily;
      setFontFamily(newFamily.value);
      applyFontFamily(newFamily.value, prevFamily);
    }
  };

  const getSelectionFontFamily = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const selectedNode = range.startContainer;

    if (selectedNode.nodeType === 3) {
      const parentNode = selectedNode.parentNode;
      const computedStyle = window.getComputedStyle(parentNode);
      const fontFamily = computedStyle.fontFamily;

      setFontFamily(fontFamily);
    }
  };

  const handleFontFamilyChange = (selectedOption) => {
    setFontFamily(selectedOption);
    applyFontFamily(selectedOption?.value);
  };

  const getSelectionFontSize = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const selectedNode = range.startContainer;

    if (selectedNode.nodeType === 3) {
      const parentNode = selectedNode.parentNode;
      const computedStyle = window.getComputedStyle(parentNode);
      const fontSize = computedStyle.fontSize;

      setFontSize(fontSize.replace("px", ""));
    }
  };

  const applyFontSize = (size) => {
    try {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.style.fontSize = size + "px";
      range.surroundContents(span);
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    } catch (e) {
      console.error(e);
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    }
  };

  const increaseFontSize = () => {
    const currentIndex = fontSizeOptions.findIndex(
      (option) => option.value === fontSize
    );
    if (currentIndex < fontSizeOptions.length - 1) {
      const newSize = fontSizeOptions[currentIndex + 1];
      setFontSize(newSize.value);
      applyFontSize(newSize.value);
    }
  };

  const decreaseFontSize = () => {
    const currentIndex = fontSizeOptions.findIndex(
      (option) => option.value === fontSize
    );
    if (currentIndex > 0) {
      const newSize = fontSizeOptions[currentIndex - 1];
      setFontSize(newSize.value);
      applyFontSize(newSize.value);
    }
  };

  const handleClickOutside = (event) => {
    if (!window.getSelection().anchorNode) {
      setShowTooltip(false);
      return;
    }
    if (
      (!window.getSelection() ||
        window.getSelection().isCollapsed ||
        window.getSelection().rangeCount === 0) &&
      window.getSelection()?.getRangeAt(0)?.getBoundingClientRect()?.top !==
        0 &&
      window.getSelection()?.getRangeAt(0)?.getBoundingClientRect()?.left !== 0
    ) {
      setShowTooltip(false);
      return;
    }
    if (
      window.getSelection()?.toString() === "" &&
      window.getSelection()?.anchorNode !== Node.TEXT_NODE
    ) {
      setShowTooltip(false);
      return;
    }
    if (
      toolTipRef.current &&
      !toolTipRef.current.contains(event.target) &&
      !window.getSelection().toString()
    ) {
      setShowTooltip(false);
    }
  };

  const applyHighlight = () => {
    try {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      const parentNode = range.startContainer.parentNode;
      const isUnderlined = parentNode.style.backgroundColor === "yellow";
      if (isUnderlined) {
        parentNode.style.backgroundColor = "transparent";
      } else {
        span.style.backgroundColor = "yellow";
        range.surroundContents(span);
      }
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    } catch (e) {
      console.error(e);
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    }
  };

  const applyBold = () => {
    try {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const span = document.createElement("span");

      const parentNode = range.startContainer.parentNode;
      const isBold = parentNode.style.fontWeight === "bold";

      if (isBold) {
        parentNode.style.fontWeight = "normal";
      } else {
        span.style.fontWeight = "bold";
        range.surroundContents(span);
      }
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    } catch (e) {
      console.error(e);
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    }
  };

  const applyItalic = () => {
    try {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const span = document.createElement("span");

      const parentNode = range.startContainer.parentNode;
      const isItalic = parentNode.style.fontStyle === "italic";

      if (isItalic) {
        parentNode.style.fontStyle = "normal";
      } else {
        span.style.fontStyle = "italic";
        range.surroundContents(span);
      }
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    } catch (e) {
      console.error(e);
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    }
  };

  const applyUnderline = () => {
    try {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      const parentNode = range.startContainer.parentNode;
      const isUnderlined = parentNode.style.textDecoration === "underline";
      if (isUnderlined) {
        parentNode.style.textDecoration = "none";
      } else {
        span.style.textDecoration = "underline";
        range.surroundContents(span);
      }
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    } catch (e) {
      console.error(e);
      setShowTooltip(false);
      window.getSelection().removeAllRanges();
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showTooltip === true && (
        <div
          style={toolTipStyle}
          className="rl-tooltip-container d-flex flex-row align-items-center justify-content-center"
          ref={toolTipRef}
        >
          <div className="font-family-container mx-1 d-flex flex-row align-items-center justify-content-center">
            <Button
              onClick={leftFontFamily}
              className="rl-tooltip-font-family-button d-flex flex-row align-items-center justify-content-center"
            >
              {"<"}
            </Button>
            <span className="mx-1 font-family-label">{fontFamily}</span>
            <Button
              onClick={rightFontFamily}
              className="rl-tooltip-font-family-button d-flex flex-row align-items-center justify-content-center"
            >
              {">"}
            </Button>
          </div>
          <div className="font-size-container d-flex flex-row align-items-center justify-content-center">
            <Button
              onClick={decreaseFontSize}
              className="rl-tooltip-font-button d-flex flex-row align-items-center justify-content-center"
            >
              -
            </Button>
            <span className="mx-1 font-label">{fontSize}</span>
            <Button
              onClick={increaseFontSize}
              className="rl-tooltip-font-button d-flex flex-row align-items-center justify-content-center"
            >
              +
            </Button>
          </div>
          <div className="text-edit-container d-flex flex-row align-items-center justify-content-center ">
            <img
              src={TooltipBold}
              className="mx-1"
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={applyBold}
              alt="Bold"
            ></img>
            <img
              src={TooltipItalic}
              className="mx-1"
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={applyItalic}
              alt="Italic"
            ></img>
            <img
              src={TooltipUnderline}
              className="mx-1"
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={applyUnderline}
              alt="Underline"
            ></img>
            <img
              src={TooltipHighlight}
              className="mx-1"
              style={{ width: "15px", height: "15px", cursor: "pointer" }}
              onClick={applyHighlight}
              alt="Highlight"
            ></img>
          </div>
        </div>
      )}
    </>
  );
};

export default RlTooltip;
