import { React } from 'react';
import { useSelector } from 'react-redux';
import './summary.scss';

const SummaryDetails = ({ activeTab }) => {
  const { familyBiblio } = useSelector(state => state.family);
  const formattedFamilyData = useSelector(state => state.family.formattedFamilyData)
  const familyCount = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['@total-result-count'] || 'N/A';
  const citedCountArray = formattedFamilyData.filter((item) => item['citedCounts']).map((item) => {
    return item['citedCounts']
  })
  const famCount = formattedFamilyData.length;
  const formattedReferenceData = useSelector((state) => state.references.formattedReferenceData);
  const familyNplData = useSelector((state) => state.search.familyNplData);
  const formattedIdsData = useSelector(
    (state) => state.search.formattedIdsData
  );
  const formattedIdsSortedData = useSelector(
    (state) => state.search.formattedIdsSortedData
  );
  const formattedNplData = useSelector(
    (state) => state.search.formattedNplData
  );
  const selectedNplData = useSelector((state) => state.search.selectedNplData);
  const finalNplData =
    selectedNplData.length === 0
      ? formattedNplData
      : selectedNplData.filter((item) => item.Selected === false).length ===
        selectedNplData.length
        ? selectedNplData
        : selectedNplData.filter((item) => item.Selected === true);
  const totalRefLength = (formattedIdsSortedData.length > 0 ? formattedIdsSortedData.length : formattedIdsData.length) + finalNplData.length
  const refLength = formattedReferenceData.length;
  const nplLength = familyNplData.length;
  const totalCounts = refLength + nplLength;
  const totalRefCounts = Array.isArray(citedCountArray) === true && citedCountArray.length !== 0 ? citedCountArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0;
  const getUSMembers = () => {
    const familyMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member'];

    const usMembers = Array.isArray(familyMembers)
      ? familyMembers.filter(member => member['country'] === 'US')
      : [];

    const activeCount = usMembers.filter(member => member['status'] === 'Active').length;
    const deadCount = usMembers.filter(member => member['status'] === 'Dead').length;
    return { active: activeCount, dead: deadCount };
  };

  const getForeignMembers = () => {
    const familyMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member'];

    const foreignMembers = Array.isArray(familyMembers)
      ? familyMembers.filter(member => member['country'] !== 'US')
      : [];

    const activeCount = foreignMembers.filter(member => member['status'] === 'Active').length;
    const deadCount = foreignMembers.filter(member => member['status'] === 'Dead').length;
    return { active: activeCount, dead: deadCount };
  };

  const usMembers = getUSMembers();
  const foreignMembers = getForeignMembers();

  const data = {
    "Family Count": famCount,
    "U.S. Members": `Active: ${usMembers.active}   Dead: ${usMembers.dead}`,
    "Foreign Members": `Active: ${foreignMembers.active}   Dead: ${foreignMembers.dead}`,
    "Last IDS Filed Date": "",
  };

  const referencesSummaryData = {
    "Total references": totalRefLength,
    "Newly cited references": "",
    "References not yet cited": "",
    "References with possible inconsistencies": ""
  }
  const referencesData = {
    "Total references": totalCounts,
    "Newly cited references": "",
    "References not yet cited": "",
    "References with possible inconsistencies": ""
  }

  return (
    <div className="details-container">
      <div className="summary-content">
        {activeTab !== "sb08" && activeTab !== "references" ? Object.keys(data).map((key, index) => (
          <div className="row" key={index}>
            <div className="key">{key}</div>
            <div className="value">{data[key]}</div>
          </div>
        )) : activeTab === "references" ? Object.keys(referencesData).map((key, index) => (
          <div className="row" key={index}>
            <div className="key">{key}</div>
            <div className="value">{referencesData[key]}</div>
          </div>
        )) : Object.keys(referencesSummaryData).map((key, index) => (
          <div className="row" key={index}>
            <div className="key">{key}</div>
            <div className="value">{referencesSummaryData[key]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SummaryDetails;
