import React, { useEffect, useState, useRef } from "react";
import Input from "../../common/input";
import InputIcon from "../../../assets/images/template-edit-icon.png";
import TickIcon from "../../../assets/images/tick-new-icon.png";
import CrossIcon from "../../../assets/images/cross-new-icon.png";
import MarkUpload from "../../../assets/images/mark-upload-icon.png";
import MarkEdit from "../../../assets/images/mark-edit-icon.png";
import FileCloseIcon from "../../../assets/images/create-letter-file-close.png";
import Button from "../../common/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputReduxValues,
  setMarkReduxFiles,
} from "../../../store/reducer/templateSlice";
import { useUploadRlPdfMutation } from "../../../store/action/uploadRlPdfApi";
import RlTemplateMarkFilesList from "../common/rl-template-mark-files-list";
import RLTemplateParamButtons from "../common/rl-template-param-buttons";
import RlTemplateMarkButton from "../common/rl-template-mark-button";
import { countWords } from "../../../util/utils";

const ParamSection = () => {
  const dispatch = useDispatch();
  const [markFiles, setMarkFiles] = useState([]);
  const [markError, setMarkError] = useState("");
  const caseNumberType = useSelector(
    (state) => state.createLetter.caseNumberType
  );
  const caseNumber = useSelector((state) => state.createLetter.caseNumber);
  const letterRadioOption = useSelector(
    (state) => state.createLetter.letterRadioOption
  );
  const manualInputFiles = useSelector(
    (state) => state.createLetter.manualInputFiles
  );
  const uploadInputFiles = useSelector(
    (state) => state.createLetter.uploadInputFiles
  );
  const manualFileTypes = useSelector(
    (state) => state.createLetter.manualFileTypes
  );
  const uploadFileTypes = useSelector(
    (state) => state.createLetter.uploadFileTypes
  );
  const [uploadRlPdf, { isLoading, isError, isSuccess }] =
    useUploadRlPdfMutation();

  const handleMarkFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    const maxSize = 1048576;
    const validFiles = [];
    let errorMessage = "";

    filesArray.forEach((file) => {
      if (file.size > maxSize) {
        errorMessage = `File size should not exceed 1MB.`;
      } else if (!/\.(jpe?g|png|gif|bmp|svg|webp|tiff)$/i.test(file.name)) {
        errorMessage = `Invalid file type. Only image files are allowed.`;
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessage) {
      setMarkError(errorMessage);
    } else {
      setMarkError("");
      setMarkFiles(validFiles);
      dispatch(setMarkReduxFiles(validFiles));
    }
  };

  useEffect(() => {
    if (markError !== "") {
      setTimeout(() => {
        setMarkError("");
      }, [2000]);
    }
  }, [markError]);
  const handleRemoveMarkFile = (index) => {
    const updatedFiles = markFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("mark-attach-file").value = "";
    setMarkFiles(updatedFiles);
    dispatch(setMarkReduxFiles([]));
    setInputValues((prevValues) => ({
      ...prevValues,
      mark: "",
    }));
  };
  const addressTitle = `Ms. Sonia Nabais
  Torys LLP
  79 Wellington St. W., 30th Floor, Box 270,
  TD South Tower
  Toronto, Ontario M5K 1N2 Canada`;

  const goodsServiceTitle = `9 providing a downloadable mobile application directed to health, fitness and 
  dietary topics in the field of vascular health 

  35 Online sale of health, fitness, and dietary products 

  44 Providing health, fitness and dietary assessments and programs directed to 
  vascular health;counseling services in the fields of health and wellness,nutrition 
  and lifestyle wellness; providing information via a website and social media 
  platforms on the subject of health, fitness and dietary information directed to
  vascular health`;

  const fields = [
    { id: "mark", label: "Mark*", placeholder: "{Matter.Title}" },
    {
      id: "applicant",
      label: "Applicant*",
      placeholder: "{Matter.Applicant<n>}",
    },
    {
      id: "serialNo",
      label: "Serial Number*",
      placeholder: "{Matter.ApplicationNum}",
    },
    { id: "classes", label: "Classes*", placeholder: "{Matter.Class}" },
    {
      id: "goodsServices",
      label: "Goods and Services*",
      placeholder: `{Matter.Goods&Services}`,
    },
    {
      id: "filingBasis",
      label: "Filing Basis*",
      placeholder: "{Matter.Filing&basis}",
    },
    {
      id: "applicationDate",
      label: "Application Date*",
      placeholder: "{Matter.FilingDate}",
    },
    {
      id: "prdFilingDate",
      label: "Priority Filing Date*",
      placeholder: "{Matter.FilingDate(+6m MMMM d, yyyy)}",
    },
    {
      id: "clientName",
      label: "Point Of Contact*",
      placeholder: "{Matter.ContactName}",
    },
    {
      id: "mailAddress",
      label: "Mailing Address*",
      placeholder: `{Matter.MailingAddress}`,
    },
    {
      id: "emailAddress",
      label: "Email Address*",
      placeholder: "{Matter.EmailAddress}",
    },
    {
      id: "ourRef",
      label: "Our Ref*",
      placeholder: "{Matter.AttorneyRef}",
    },
    {
      id: "currDate",
      label: "Current Date*",
      placeholder: "{Matter.CurrentDate}",
    },
  ];

  const [manualEdit, setManualEdit] = useState(false);

  const [inputValues, setInputValues] = useState(
    fields.reduce((acc, field) => {
      acc[field.id] = "";
      return acc;
    }, {})
  );

  const [editableFields, setEditableFields] = useState(
    fields.reduce((acc, field) => {
      acc[field.id] = false;
      return acc;
    }, {})
  );

  const [editableFocusFields, setEditableFocusFields] = useState({});

  const toggleEdit = (fieldId) => {
    if (fieldId === "mark" && markFiles.length !== 0) {
      alert("Please remove the image first to add text.");
      return;
    }
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: true,
    }));
    setEditableFocusFields((prevState) => ({
      ...prevState,
      [fieldId]: true,
    }));
  };

  const toggleDisableEdit = (fieldId, icon) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: false,
    }));
    setEditableFocusFields((prevState) => ({
      ...prevState,
      [fieldId]: false,
    }));
    if (icon === "tick") {
      setManualEdit(false);
    }
    if (icon === "cross") {
      setManualEdit(false);
      setInputValues((prevValues) => ({
        ...prevValues,
        [fieldId]: "",
      }));
      if (fieldId === "mark") {
        setMarkFiles([]);
        dispatch(setMarkReduxFiles([]));
      }
      return;
    }
  };

  const handleInputChange = (fieldId, value) => {
    if (countWords(value) > 1000) {
      alert("Maximum Word Limit Reached.");
      return;
    }
    setManualEdit(true);
    if (fieldId === "mark") {
      setMarkFiles([]);
      dispatch(setMarkReduxFiles([]));
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value,
    }));
  };

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handleSave = () => {
    dispatch(setInputReduxValues(inputValues));
  };

  const extractContentAfterEachForUntilInternational = (
    array,
    cleanedClassNumbers
  ) => {
    let content = "";
    let isExtracting = false;
    let cleanedIndex = 0;
    array.forEach((str) => {
      if (str.includes("International")) {
        isExtracting = false;
      }
      const indexFor = str.indexOf("For:");
      if (indexFor !== -1) {
        isExtracting = true;
        content +=
          " " +
          cleanedClassNumbers[cleanedIndex++] +
          " " +
          str.slice(indexFor + 4).trim();
      } else if (isExtracting) {
        content += " " + str.trim();
      }
    });

    return content.trim();
  };

  const formatPDFTextNafa = async (textContent) => {
    const serialNoElement =
      textContent[
        textContent.findIndex((item) => item.includes("US Serial Number:"))
      ];
    const serialNo =
      serialNoElement?.match(/US Serial Number:(\d+)/)?.[1]?.trim() || "";
    const applicationDate = textContent[textContent.indexOf("Date:") + 1];
    const date = new Date(applicationDate);
    date.setMonth(date.getMonth() + 6);
    const options = { year: "numeric", month: "short", day: "numeric" };
    const prdFilingDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const mark = textContent[textContent.indexOf("Elements:") + 1];
    const applicant =
      textContent[
        textContent.findIndex((item) => item.includes("Owner Name"))
      ].split(":")?.[1] || "";
    let classNumbers = [];

    for (let i = 0; i < textContent.length; i++) {
      if (textContent[i] === "Class(es):" && i + 1 < textContent.length) {
        const match = textContent[i + 1].match(/^(\d{3})/);
        if (match) {
          classNumbers.push(match[1]);
        }
      }
    }
    const classes = classNumbers.join(",");
    const cleanedClassNumbers = classNumbers.map((num) =>
      num.replace(/^0+/, "")
    );
    const goodsServices = extractContentAfterEachForUntilInternational(
      textContent,
      cleanedClassNumbers
    );
    const ourRefElement =
      textContent[
        textContent.findIndex((item) => item.includes("Docket Number:"))
      ];
    const ourRef =
      ourRefElement.match(/Docket Number:([A-Za-z0-9\.]+)/)?.[1] || "";
    const filingBasisArray = textContent.filter((str) =>
      str.startsWith("Filed")
    );
    let useResult = "";
    let ituResult = "";
    let filingBasis = "";
    filingBasisArray.forEach((str) => {
      if (str.includes("Use")) {
        const useMatch = str.match(/Use:(Yes|No)/);
        if (useMatch) {
          useResult = useMatch[1];
        }
      }
      if (str.includes("ITU")) {
        const ituMatch = str.match(/ITU:(Yes|No)/);
        if (ituMatch) {
          ituResult = ituMatch[1];
        }
      }
    });
    if (useResult === "Yes") filingBasis = "1(a)";
    else if (ituResult === "Yes") filingBasis = "1(b)";
    else filingBasis = "44(d)";
    const crDate = new Date();
    const currDate = crDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const fetchObject = {
      serialNo: serialNo,
      applicationDate: applicationDate,
      mark: mark,
      applicant: applicant,
      goodsServices: goodsServices,
      classes: classes,
      ourRef: ourRef,
      filingBasis: filingBasis,
      prdFilingDate: prdFilingDate,
      currDate: currDate,
    };
    setInputValues((prevValues) => ({
      ...prevValues,
      ...fetchObject,
    }));
  };

  const getNafaPdfData = async (index) => {
    const formDataNafa = new FormData();
    formDataNafa.append(
      "file",
      letterRadioOption === "manual"
        ? manualInputFiles[index]
        : uploadInputFiles[index]
    );
    let caseNum = caseNumber;
    if (caseNumberType === "Serial#") {
      caseNum = `sn${caseNumber}`;
    } else if (caseNumberType === "Registration #") {
      caseNum = `rn${caseNumber}`;
    } else {
      caseNum = `refnum${caseNumber}`;
    }
    formDataNafa.append("caseNum", caseNum);
    try {
      const responseTextCir = await uploadRlPdf({
        data: formDataNafa,
      }).unwrap();
      await formatPDFTextNafa(responseTextCir.textContent);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetch = async () => {
    try {
      if (letterRadioOption === "manual") {
        manualFileTypes.forEach(async (type, index) => {
          switch (type) {
            case "new_application_filing_agent_letter":
              await getNafaPdfData(index);
              break;
          }
        });
      } else {
        uploadFileTypes.forEach(async (type, index) => {
          switch (type) {
            case "new_application_filing_agent_letter":
              await getNafaPdfData(index);
              break;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manualEdit === false) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues, manualEdit]);

  useEffect(() => {
    Object.keys(editableFocusFields).forEach((fieldId) => {
      if (editableFocusFields[fieldId]) {
        var element = document.getElementById(`${fieldId}-input`);
        element.focus();
      }
    });
  }, [editableFocusFields]);

  return (
    <>
      <div className="d-flex flex-column param-container justify-content-between section-scroll">
        <div className="d-flex flex-column param-input-container justify-content-center px-3 py-3">
          <div className="param-title">Parameters</div>
          {fields.map((field) => (
            <div
              key={field.id}
              className="d-flex flex-column justify-content-center mt-2"
            >
              <div className="param-input-title">{field.label}</div>
              <div className="d-flex flex-row align-items-center">
                <Input
                  type="text"
                  title={inputValues[field.id]}
                  id={`${field.id}-input`}
                  placeholder={`${field.placeholder}`}
                  className={`param-input-style ${
                    editableFields[field.id] === false &&
                    inputValues[field.id] !== ""
                      ? "param-input-text-style"
                      : ""
                  }`}
                  value={inputValues[field.id]}
                  onChange={(e) => handleInputChange(field.id, e.target.value)}
                  disabled={!editableFields[field.id]}
                  spellcheck="false"
                />
                {editableFields[field.id] === false && (
                  <RlTemplateMarkButton
                    field={field}
                    MarkEdit={MarkEdit}
                    MarkUpload={MarkUpload}
                    handleDragStart={handleDragStart}
                    handleMarkFileChange={handleMarkFileChange}
                    InputIcon={InputIcon}
                    toggleEdit={toggleEdit}
                  />
                )}
                {editableFields[field.id] === true && (
                  <div className="param-input-image mx-1 d-flex flex-row align-items-center">
                    <img
                      src={TickIcon}
                      className="param-image"
                      alt="tick-icon"
                      onClick={() => toggleDisableEdit(field.id, "tick")}
                      onDragStart={handleDragStart}
                    />
                    <img
                      src={CrossIcon}
                      className="param-image mx-1"
                      alt="cross-icon"
                      onClick={() => toggleDisableEdit(field.id, "cross")}
                      onDragStart={handleDragStart}
                    />
                  </div>
                )}
              </div>
              {field.id === "mark" && markFiles.length > 0 && (
                <RlTemplateMarkFilesList
                  markFiles={markFiles}
                  FileCloseIcon={FileCloseIcon}
                  handleRemoveMarkFile={handleRemoveMarkFile}
                />
              )}
              {field.id === "mark" && (
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", height: "auto" }}
                >
                  {markError}
                </span>
              )}
            </div>
          ))}
          <div className="d-flex mt-2 flex-column align-items-center justify-content-center">
            {isLoading === true ? "Extracting Data..." : ""}
          </div>
        </div>
        <RLTemplateParamButtons
          isLoading={isLoading}
          handleFetch={handleFetch}
          handleSave={handleSave}
        />
      </div>
    </>
  );
};

export default ParamSection;
