import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rlAdminApi from "../action/rlAdminApi";

export const saveRlTemplateData = createAsyncThunk(
    "rlAdmin/save",
    async (data, thunkAPI) => {
        try {
            const response = await thunkAPI.dispatch(
                rlAdminApi.endpoints.saveRlTemplate.initiate(data)
            ).unwrap();

            return response;
        } catch (error) {
            console.error("API Call Failed:", error);
            return thunkAPI.rejectWithValue(
                error.message || "Failed to save rl template data"
            );
        }
    }
);

const initialState = {
    rlTemplateData: [],
    status: "idle",
    error: null,
    dtUserName: ""
};

const rlAdminSlice = createSlice({
    name: "rlAdmin",
    initialState,
    reducers: {
        setRlTemplateData: (state, action) => {
            state.data = action.payload
        },
        setDtUserName: (state, action) => {
            state.dtUserName = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveRlTemplateData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(saveRlTemplateData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
                state.error = null;
            })
            .addCase(saveRlTemplateData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload || "Failed to save rl admin data";
            });
    },
});

export const {
    setRlTemplateData,
    setDtUserName
} = rlAdminSlice.actions;

export default rlAdminSlice.reducer;