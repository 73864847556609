import React from "react";
import Input from "../../common/input";

const RsrInstructionSection = (props) => {
  const { serviceTitle, serviceTitle1, serviceTitle2, inputReduxValues } =
    props;

  const servicesArray = inputReduxValues?.services
    ? inputReduxValues?.services.split(/(?<=, in Int\. Class \d{3})/)
    : [`{Matter.Services}`];

  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <div className="content-text text-only-size">
          <span className="instr-sheet-title">Instruction Sheet</span>
          <br></br>
          <ul className="instr-sheet-list">
            <li>
              The mark is no longer in use, please do not renew. I understand by
              not renewing, that this registration will lapse.
            </li>
            <li>
              Please prepare the renewal*:
              <p>
                The mark is being used in association with the following
                services (any services for which we do not have services and
                need to be cancelled from the registration are struck out):
              </p>
              <ul>
                {servicesArray.map((title, index) => (
                  <li key={index}>
                    <span className="email-content-span service-span ms-1 highlight-field-class">
                      {title}
                    </span>
                    <ul>
                      <li>We have an excuse for non-use of these services</li>
                      <li>
                        *At least one (1) specimen, showing the mark as it is
                        currently being used in interstate commerce in
                        association with the services is attached.
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
          <div className="d-flex flex-column mb-2">
            <span
              className="d-flex flex-row justify-content-between date-dash-container"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <span className="d-flex flex-row align-items-start">
                Date:
                <Input className={`ms-1 instru-input`} />
              </span>
              <span
                className="d-flex flex-column align-items-center"
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Input className={`ms-1 instru-input`} />
                <div className="email-content-span email-content-span-auto ms-1 mt-1 highlight-field-class">
                  {inputReduxValues?.clientName
                    ? `${inputReduxValues?.clientName} Krueger`
                    : "{Matter.ContactName}"}
                </div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RsrInstructionSection;
