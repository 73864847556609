import React from "react";

const Button = ({
  children,
  className,
  type,
  onClick,
  disabled = false,
  title,
}) => {
  return (
    <>
      <button
        className={className}
        type={type}
        onClick={onClick}
        disabled={disabled}
        title={title}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
