import React from "react";

const NafdTableSection = (props) => {
  const { filingParticularFields, markReduxFiles, imageUrl } = props;
  return (
    <>
      <table
        className="table table-bordered mt-2"
        style={{ border: "1px solid #dee2e6" }}
      >
        <tbody style={{ border: "1px solid #dee2e6" }}>
          {filingParticularFields.map((field, index) => (
            <tr key={index} style={{ border: "1px solid #dee2e6" }}>
              <td className="field-title field-title-table ps-1">
                {field.label}:
              </td>
              <td className="" style={{ border: "1px solid #dee2e6" }}>
                {field.label === "Mark" && markReduxFiles.length > 0 ? (
                  <img
                    src={imageUrl}
                    alt={field.label}
                    style={{ width: "80px", height: "80px" }}
                  />
                ) : (
                  <span
                    className={`mx-1 field-span ${
                      field.label === "Services"
                        ? " field-span-text-justify field-span-service"
                        : ""
                    } highlight-field-class`}
                  >
                    {field.value}
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default NafdTableSection;
