import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { setSelectedNplData } from "../../store/reducer/searchSlice";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";

const Table = ({ headers, formattedData, renderAsHtml, activeTab }) => {
  const [data, setData] = useState(formattedData);
  const [selectAll, setSelectAll] = useState(false);
  const selectedNplData = useSelector((state) => state.search.selectedNplData);
  const activeReduxTab = useSelector((state) => state.search.activeReduxTab);
  const dispatch = useDispatch();
  useEffect(() => {
    setData(formattedData);
  }, [formattedData]);

  const onSelectAll = (selectAll) => {
    const updatedData =
      selectedNplData.length === 0
        ? data.map((row) => ({
            ...row,
            Selected: selectAll,
          }))
        : selectedNplData.map((row) => ({
            ...row,
            Selected: selectAll,
          }));
    setData(updatedData);
    dispatch(setSelectedNplData(updatedData));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    onSelectAll(!selectAll);
  };

  const handleRowSelect = (rowIndex) => {
    const updatedData =
      selectedNplData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          )
        : selectedNplData.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          );
    const allSelected = updatedData.every((row) => row.Selected === true);
    if (allSelected === true) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setData(updatedData);
    dispatch(setSelectedNplData(updatedData));
  };
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {activeReduxTab === "references" && (
              <th>
                <input
                  type="checkbox"
                  checked={
                    selectedNplData.length !== 0 &&
                    selectedNplData.filter((item) => item.Selected === true)
                      .length === selectedNplData.length
                      ? true
                      : selectAll
                  }
                  onChange={handleSelectAll}
                />
              </th>
            )}
            {headers.map((header, index) => (
              <th
                className={`${activeReduxTab === "sb08" ? "sb08-td-th" : ""}`}
                key={index}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedNplData.length === 0 &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {activeReduxTab === "references" && (
                  <td>
                    <input
                      type="checkbox"
                      checked={row.Selected}
                      onChange={() => handleRowSelect(rowIndex)}
                    />
                  </td>
                )}
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
          {selectedNplData.length !== 0 &&
            activeReduxTab === "references" &&
            selectedNplData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {activeReduxTab === "references" && (
                  <td>
                    <input
                      type="checkbox"
                      checked={row.Selected}
                      onChange={() => handleRowSelect(rowIndex)}
                    />
                  </td>
                )}
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
          {selectedNplData.length !== 0 &&
            activeReduxTab === "sb08" &&
            selectedNplData
              .filter((item) => item.Selected === true)
              .map((item, index) => ({
                ...item,
                "Sr.No": index + 1,
              }))
              .map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      dangerouslySetInnerHTML={
                        renderAsHtml && header === "Link"
                          ? { __html: row[header] }
                          : undefined
                      }
                    >
                      {!renderAsHtml || header !== "Link" ? row[header] : null}
                    </td>
                  ))}
                </tr>
              ))}
          {selectedNplData.length !== 0 &&
            activeReduxTab === "sb08" &&
            selectedNplData.filter((item) => item.Selected === false).length ===
              data.length &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
