import React from "react";

const RtrSpecimenPointsSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <div className="content-text">
          NOTE: Trade literature will not be accepted by the United States
          Trademark Office as proof of use of the mark on the goods.
          <br></br>
          If the renewal is to be filed on a non-use basis, please advise.
          Examples of acceptable excuses for non-use may include:
          <br></br>
          <ul className="specimen-point-list ps-3">
            <li>
              Trade Embargo or Other Circumstance Beyond Owner's Control. Nonuse
              may be considered excusable where the owner of the registration is
              willing and able to continue use of the mark in commerce, but is
              unable to do so due to a trade embargo.
            </li>
            <li>
              Sale of a Business. Temporary nonuse due to the sale of a business
              might be considered excusable.
            </li>
            <li>
              Retooling. The mark might be out of use temporarily because of an
              interruption of production for retooling of a plant or equipment,
              with production possible again at a scheduled time. However,
              nonuse due to retooling is excusable only if the owner shows that
              the plant or equipment being retooled was essential to the
              production of the goods and that alternative equipment was
              unavailable on the market.
            </li>
            <li>
              Orders on Hand. If the product is of a type that cannot be
              produced quickly or in large numbers (e.g., airplanes), yet there
              are orders on hand and activity toward filling them, nonuse might
              be considered excusable.
            </li>
            <li>
              Illness, Fire, and Other Catastrophes. Illness, fire, and other
              catastrophes may create situations of temporary nonuse, with the
              owner being able to outline arrangements and plans for resumption
              of use. Such nonuse is often excusable. However, a mere statement
              that the owner is ill and cannot conduct his or her business will
              not in itself excuse nonuse; the owner must show that the business
              is an operation that could not continue without his or her
              presence.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default RtrSpecimenPointsSection;
