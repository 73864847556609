import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCanAddRow,
  setSelectedRefData,
} from "../../store/reducer/searchSlice";
import PropTypes from "prop-types";
import "./index.scss";
import * as XLSX from "xlsx";

const Table = ({
  headers,
  formattedData,
  renderAsHtml,
  handleUserRowData,
  handleUpdateRowData,
  downloadReferences,
}) => {
  const [data, setData] = useState(formattedData);
  const canEdit = useSelector((state) => state.search.canEdit);
  const canAddRow = useSelector((state) => state.search.canAddRow);
  const selectedRefData = useSelector((state) => state.search.selectedRefData);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();

  const setCursorPosition = (rowIndex, header) => {
    if (canEdit) {
      var element = document.getElementById(
        `editable_span_${rowIndex}_${header}`
      );
      if (element.childNodes.length === 0) {
        const newInput = document.createElement("input");
        newInput.className = "editable-input";
        newInput.textContent = "";
        element.appendChild(newInput);
        element.focus();
      }
    }
  };

  useEffect(() => {
    setData(formattedData);
  }, [formattedData]);

  useEffect(() => {
    if (downloadReferences) {
      exportReferences();
    }
  }, [downloadReferences]);

  const onSelectAll = (selectAll) => {
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row) => ({ ...row, Selected: selectAll }))
        : selectedRefData.map((row) => ({ ...row, Selected: selectAll }));

    setData(updatedData);
    dispatch(setSelectedRefData(updatedData));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    onSelectAll(!selectAll);
  };

  const handleRowSelect = (rowIndex) => {
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          )
        : selectedRefData.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          );

    const allSelected = updatedData.every((row) => row.Selected === true);
    setSelectAll(allSelected);
    setData(updatedData);
    dispatch(setSelectedRefData(updatedData));
  };

  const handleCellChange = (rowIndex, header, event) => {
    const newValue = event.target.textContent;
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, [header]: newValue } : row
          )
        : selectedRefData.map((row, index) =>
            index === rowIndex ? { ...row, [header]: newValue } : row
          );

    setData(updatedData);
    handleUpdateRowData(updatedData);
    if (selectedRefData.length !== 0) {
      dispatch(setSelectedRefData(updatedData));
    }
  };

  const addBlankRow = () => {
    const newRow = headers.reduce(
      (acc, header) => {
        acc[header] = "";
        return acc;
      },
      { Selected: false }
    );

    setData((prevData) => [...prevData, newRow]);
    handleUserRowData((prevData) => [...prevData, newRow]);
    if (selectedRefData.length !== 0) {
      newRow["Sr.No"] = `${selectedRefData.length + 1}`;
      dispatch(setSelectedRefData([...selectedRefData, newRow]));
    }
    dispatch(setCanAddRow(""));
  };

  useEffect(() => {
    if (canAddRow === "add-new-ref") {
      addBlankRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddRow]);

  const exportReferences = () => {
    const filteredData = selectedRefData.filter((row) => row.Selected);
    if (filteredData.length === 0) {
      alert("No selected rows to export.");
      return;
    }

    const exportData = filteredData.map(
      ({ Selected, "Sr.No": srNo, ...rest }) => rest
    );

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "References");
    XLSX.writeFile(workbook, "References.xlsx");
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={
                  selectedRefData.length !== 0 &&
                  selectedRefData.filter((item) => item.Selected === true)
                    .length === selectedRefData.length
                    ? true
                    : selectAll
                }
                onChange={handleSelectAll}
                className="checkbox-height"
              />
            </th>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedRefData.length === 0 &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <input
                    type="checkbox"
                    checked={row.Selected}
                    onChange={() => handleRowSelect(rowIndex)}
                    className="checkbox-height"
                  />
                </td>
                {headers.map((header, colIndex) => (
                  <td
                    id={`editable_span_${rowIndex}_${header}`}
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    contentEditable={canEdit}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
          {selectedRefData.length !== 0 &&
            selectedRefData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <input
                    type="checkbox"
                    checked={row.Selected}
                    onChange={() => handleRowSelect(rowIndex)}
                    className="checkbox-height"
                  />
                </td>
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    contentEditable={canEdit}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
