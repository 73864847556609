export const handleDownloadWord = async (contentRef, caseTemplate) => {
  const element = contentRef.current;

  const highlightedElements = element.querySelectorAll(
    ".highlight-field-class"
  );
  const tableElements = element.querySelectorAll(".table");

  highlightedElements.forEach((el) => {
    el.classList.remove("highlight-field-class");
  });

  if (tableElements) {
    tableElements.forEach((el) => {
      el.classList.remove("table");
      el.classList.remove("table-bordered");
    });
  }

  const excludedElements = element.querySelectorAll(".exclude-from-pdf");

  const targetDiv = element.querySelector(".last-text-section");

  const excludedElementFiles = element.querySelectorAll(
    ".exclude-from-pdf-files"
  );

  excludedElements.forEach((el) => {
    el.remove();
  });

  excludedElementFiles.forEach((el) => {
    el.remove();
  });

  const elementsToReAdd = Array.from(excludedElements);

  const elementsToReAddFile = Array.from(excludedElementFiles);

  const htmlContent = element.innerHTML;

  const requestBody = { htmlString: `${htmlContent}` };

  const response = await fetch("/api/v1/convert-to-word/get-word-file", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) throw new Error("Failed to fetch file");

  const fileBlob = await response.blob();

  setTimeout(() => {
    highlightedElements.forEach((el) => {
      el.classList.add("highlight-field-class");
    });
    elementsToReAdd.forEach((el) => {
      element.appendChild(el);
    });
    elementsToReAddFile.forEach((el) => {
      targetDiv.appendChild(el);
    });
    if (tableElements) {
      tableElements.forEach((el) => {
        el.classList.add("table");
        el.classList.add("table-bordered");
      });
    }
  }, 2000);

  const link = document.createElement("a");
  link.href = URL.createObjectURL(fileBlob);
  link.download = "Template.docx";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
