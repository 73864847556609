import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const NafaTemplateHeader = (props) => {
  const { inputReduxValues, handleCopyTemplate, contentRef, addressText } =
    props;
  return (
    <div className="d-flex flex-column align-items-start sub-cert-section-1 py-1">
      <div
        className="d-flex flex-row justify-content-between sub-cert-section"
        style={{ width: "100%" }}
      >
        <span className="matter-span matter-title-span-nafa highlight-field-class">
          {inputReduxValues?.currDate
            ? inputReduxValues?.currDate
            : "{Matter.CurrentDate}"}
        </span>
        <div
          className="d-flex flex-row align-items-center copy-icon-container"
          onClick={() => handleCopyTemplate(contentRef)}
        >
          <FontAwesomeIcon
            icon={faCopy}
            className="copy-img"
            title="Copy Template Content"
          />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center mt-3">
        <span className="cert-reg">By email only (</span>
        <span className="matter-span-pdf ps-1 matter-title-span-nafa highlight-field-class">
          {inputReduxValues?.emailAddress
            ? inputReduxValues?.emailAddress
            : "{Matter.EmailAddress}"}
        </span>
        )
      </div>
      <span
        style={{ whiteSpace: "pre-line", width: "auto" }}
        className="matter-span-pdf mt-3 matter-title-span-nafa-address highlight-field-class"
      >
        {inputReduxValues?.mailAddress
          ? inputReduxValues?.mailAddress
          : `{Matter.MailingAddress}`}
      </span>
    </div>
  );
};

export default NafaTemplateHeader;
