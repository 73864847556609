import React from "react";

const Input = ({
  type = "text",
  className,
  value,
  onChange,
  placeholder = "",
  disabled = false,
  name,
  id,
  checked,
  onClick,
  defaultChecked = false,
  title,
  spellcheck,
  accept,
}) => {
  return (
    <>
      <input
        name={name}
        id={id}
        type={type}
        className={className}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        checked={checked}
        onClick={onClick}
        title={title}
        spellCheck={spellcheck}
        accept={accept}
      />
    </>
  );
};

export default Input;
