import { createSlice, createAction } from "@reduxjs/toolkit";

export const revertCreateLetter = createAction("REVERT_CREATE_LETTER");

const initialState = {
    isLetterPopupOpen: false,
    isEditLetterPopupOpen: false,
    letterRadioOption: "manual",
    selectOptions: false,
    manualEmailText: "",
    manualInputFiles: [],
    uploadOutlookFiles: [],
    uploadInputFiles: [],
    manualFileTypes: [],
    uploadFileTypes: [],
    signatureFiles: [],
    manualFilesMapRedux: [],
    uploadFilesMapRedux: [],
    caseClient: "",
    caseTemplate: "",
    caseNumberType: "",
    caseNumberTypeOption: {},
    caseClientTypeOption: {},
    caseTemplateTypeOption: {},
    manualAttachOptions: [],
    uploadAttachOptions: [],
    caseNumber: "",
}

const createLetterSlice = createSlice({
    name: "createLetter",
    initialState,
    extraReducers: (builder) => builder.addCase(revertCreateLetter, () => initialState),
    reducers: {
        setIsLetterPopupOpen: (state, action) => {
            state.isLetterPopupOpen = action.payload
        },
        setIsEditLetterPopupOpen: (state, action) => {
            state.isEditLetterPopupOpen = action.payload
        },
        setLetterRadioOption: (state, action) => {
            state.letterRadioOption = action.payload
        },
        setSelectOptions: (state, action) => {
            state.selectOptions = action.payload
        },
        setManualEmailText: (state, action) => {
            state.manualEmailText = action.payload
        },
        setManualInputFiles: (state, action) => {
            state.manualInputFiles = action.payload
        },
        setUploadOutlookFiles: (state, action) => {
            state.uploadOutlookFiles = action.payload
        },
        setUploadInputFiles: (state, action) => {
            state.uploadInputFiles = action.payload
        },
        setManualFileTypes: (state, action) => {
            state.manualFileTypes = action.payload
        },
        setUploadFileTypes: (state, action) => {
            state.uploadFileTypes = action.payload
        },
        setSignatureFiles: (state, action) => {
            state.signatureFiles = action.payload
        },
        setManualFilesMapRedux: (state, action) => {
            state.manualFilesMapRedux = action.payload
        },
        setUploadFilesMapRedux: (state, action) => {
            state.uploadFilesMapRedux = action.payload
        },
        setCaseClient: (state, action) => {
            state.caseClient = action.payload
        },
        setCaseTemplate: (state, action) => {
            state.caseTemplate = action.payload
        },
        setCaseNumberType: (state, action) => {
            state.caseNumberType = action.payload
        },
        setCaseNumber: (state, action) => {
            state.caseNumber = action.payload
        },
        setCaseNumberTypeOption: (state, action) => {
            state.caseNumberTypeOption = action.payload
        },
        setCaseClientTypeOption: (state, action) => {
            state.caseClientTypeOption = action.payload
        },
        setCaseTemplateTypeOption: (state, action) => {
            state.caseTemplateTypeOption = action.payload
        },
        setManualAttachOptions: (state, action) => {
            state.manualAttachOptions = action.payload
        },
        setUploadAttachOptions: (state, action) => {
            state.uploadAttachOptions = action.payload
        },
    }
})

export const {
    setIsLetterPopupOpen,
    setIsEditLetterPopupOpen,
    setLetterRadioOption,
    setSelectOptions,
    setManualInputFiles,
    setUploadInputFiles,
    setManualEmailText,
    setManualFileTypes,
    setUploadOutlookFiles,
    setUploadFileTypes,
    setSignatureFiles,
    setCaseClient,
    setCaseTemplate,
    setCaseNumberType,
    setCaseNumber,
    setCaseNumberTypeOption,
    setCaseClientTypeOption,
    setCaseTemplateTypeOption,
    setManualAttachOptions,
    setUploadAttachOptions,
    setManualFilesMapRedux,
    setUploadFilesMapRedux
} = createLetterSlice.actions;

export default createLetterSlice.reducer