export const handleRemoveSignFile = (
  index,
  attachSignFile,
  setAttachSignFile
) => {
  const updatedFiles = attachSignFile.filter(
    (_, fileIndex) => fileIndex !== index
  );
  document.getElementById("template-attach-signature-file").value = "";
  setAttachSignFile(updatedFiles);
};
