import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsLetterPopupOpen,
  setLetterRadioOption,
  setSelectOptions,
  setManualInputFiles,
  setUploadInputFiles,
  setManualFileTypes,
  setCaseClient,
  setCaseTemplate,
  setUploadFileTypes,
  setCaseNumberType,
  setCaseNumber,
  setManualEmailText,
  setUploadOutlookFiles,
  setSignatureFiles,
  setCaseNumberTypeOption,
  setCaseClientTypeOption,
  setCaseTemplateTypeOption,
  setManualAttachOptions,
  setUploadAttachOptions,
  setManualFilesMapRedux,
  setUploadFilesMapRedux,
} from "../../../store/reducer/createLetterSlice";
import "./create-letter-popup.scss";
import Button from "../../common/button";
import Dropdown from "../../common/dropdown";
import Input from "../../common/input";
import CloseIcon from "../../../assets/images/create-letter-close.png";
import FileCloseIcon from "../../../assets/images/create-letter-file-close.png";
import PenImage from "../../../assets/images/create-letter-pen.png";
import UploadImage from "../../../assets/images/create-letter-manual-upload.png";
import BulletImage from "../../../assets/images/create-letter-bullet-point.png";
import DropdownImage from "../../../assets/images/create-letter-select-dropdown.png";
import AddImage from "../../../assets/images/create-letter-add-attachment.png";
import { components } from "react-select";
import Select from "react-select";

const CreateLetterPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const letterRadioOption = useSelector(
    (state) => state.createLetter.letterRadioOption
  );
  const selectOptions = useSelector(
    (state) => state.createLetter.selectOptions
  );
  const [isSelectValid, setIsSelectValid] = useState(true);
  const [isRadioValid, setIsRadioValid] = useState(true);
  const [selectNum, setSelectNum] = useState("");
  const [isSelectNumValid, setIsSelectNumValid] = useState(true);
  const [createError, setCreateError] = useState("");
  const [selectTemplError, setSelectTemplError] = useState("");
  const [manualPasteEmail, setManualPasteEmail] = useState("");
  const [ismanualPasteValid, setIsManualPasteValid] = useState(true);
  const [manualError, setManualError] = useState("");
  const [isUploadOutlookValid, setIsUploadOutlookValid] = useState(true);
  const [isUploadAttachValid, setIsUploadAttachValid] = useState(true);
  const [isManualAttachValid, setIsManualAttachValid] = useState(true);
  const [uploadError, setUploadError] = useState("");
  const [isManualRadioValid, setIsManualRadioValid] = useState(true);
  const [isUploadRadioValid, setIsUploadRadioValid] = useState(true);
  const [manualFilesMap, setManualFilesMap] = useState(new Map());
  const [uploadFilesMap, setUploadFilesMap] = useState(new Map());
  const [manualAttachOption, setManualAttachOption] = useState([
    {
      id: 1,
      value: "type",
      label: "Type",
      disabled: false,
    },
  ]);
  const [manualAttachArray, setManualAttachArray] = useState([]);
  const [uploadAttachOption, setUploadAttachOption] = useState([
    {
      id: 1,
      value: "type",
      label: "Type",
      disabled: false,
    },
  ]);
  const [uploadAttachArray, setUploadAttachArray] = useState([]);
  const [clientOption, setClientOption] = useState({
    value: "Select Client",
    label: "Select Client",
    disabled: false,
  });
  const [numberOption, setNumberOption] = useState({
    value: "Serial#",
    label: "Serial#",
    disabled: false,
  });
  const [templateOption, setTemplateOption] = useState({
    value: "Select Template",
    label: "Select Template",
    disabled: false,
  });
  const selectStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 1)",
      fontWeight: "700",
      height: "40px",
      alignItems: "center",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      border: "1px solid black",
      borderRadius: "10px",
      minHeight: "30px",
      height: "30px",
      color: "rgba(0, 0, 0, 1)",
      textAlign: "left",
      fontFamily: "Helvetica",
      fontSize: "12px",
      lineHeight: "17px",
      fontWeight: "600",
      paddingBottom: "15px",
      alignItems: "center",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black",
      },
    }),
    menu: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 1)",
      lineHeight: "17px",
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontWeight: "600",
      borderRight: "1px solid rgba(40, 59, 124, 1)",
      borderLeft: "1px solid rgba(40, 59, 124, 1)",
      borderBottom: "1px solid rgba(40, 59, 124, 1)",
      borderTop: "1px solid rgba(40, 59, 124, 1)",
      borderRadius: "7px",
      textAlign: "left",
      marginTop: "-0.1rem",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      borderTop: "1px solid rgba(153, 153, 153, 1)",
      color: "rgba(0, 0, 0, 1)",
      backgroundColor: isFocused ? "" : "",
      cursor: "pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 1)",
      fontFamily: "Helvetica",
      fontSize: "12px",
      lineHeight: "17px",
      fontWeight: "600",
      height: "30px",
    }),
    indicatorSeparator: () => ({
      width: "0px",
    }),
    indicatorsContainer: () => ({
      height: "40px",
    }),
  };
  const extendedSelectStyles = {
    ...selectStyles,
    placeholder: (provided) => ({
      ...selectStyles.placeholder(provided),
      fontFamily: "Poppins",
    }),
    indicatorsContainer: () => ({
      height: "40px",
    }),
    menuList: (provided) => ({
      ...selectStyles.menuList(provided),
      height: "80px",
      overflowY: "auto",
    }),
  };

  const extendedSelectStylesSerial = {
    ...extendedSelectStyles,
    menuList: (provided) => ({
      ...extendedSelectStyles.menuList(provided),
      height: "70px",
      overflowY: "auto",
    }),
  };
  const extendedDisabledSelectStyles = {
    ...selectStyles,
    singleValue: (provided) => ({
      ...selectStyles.singleValue(provided),
      color:
        clientOption.value === "Select Client"
          ? "rgba(0, 0, 0, 0.5)"
          : "rgba(0, 0, 0, 1)",
    }),
    control: (provided) => ({
      ...selectStyles.control(provided),
      borderColor:
        clientOption.value === "Select Client"
          ? "rgba(0, 0, 0, 0.5) !important"
          : "rgba(0, 0, 0, 1)",
    }),
    indicatorsContainer: (provided) => ({
      ...selectStyles.indicatorsContainer(provided),
      opacity: clientOption.value === "Select Client" ? "0.5" : "1",
    }),
    menuList: (provided) => ({
      ...selectStyles.menuList(provided),
      height: "105px",
      overflowY: "auto",
    }),
  };
  const [emailRadioOption, setEmailRadioOption] = useState("manual");
  const [uploadPopupFiles, setUploadPopupFiles] = useState([]);
  const [uploadAttachFiles, setUploadAttachFiles] = useState([]);
  const [manualAttachFiles, setManualAttachFiles] = useState([]);
  const [manualSignFiles, setManualSignFiles] = useState([]);
  const navigate = useNavigate();

  const handleRadioChange = (e) => {
    setEmailRadioOption(e.target.value);
    dispatch(setLetterRadioOption(e.target.value));
    dispatch(setSelectOptions(false));
  };

  const handleNumberChange = (option) => {
    setNumberOption(option.target.value);
  };

  const handleNumberSelectChange = (option) => {
    setNumberOption(option);
    dispatch(setCaseNumberType(option.value));
  };

  useEffect(() => {
    dispatch(setCaseNumberType(numberOption.value));
  }, []);

  const clientdropdownOptions = [
    // {
    //   value: "Select Client",
    //   label: "Select Client",
    //   disabled: false,
    //   hidden: true,
    // },
    {
      value: "Default",
      label: "Default",
      disabled: false,
    },
    { value: "Jackie", label: "Jackie" },
    { value: "Linda", label: "Linda" },
    { value: "Bob", label: "Bob" },
  ];

  const templatedropdownOptions = {
    Default: [
      {
        value: "ltr_certificate_of_international_registration",
        label: "Ltr - Certificate of International Registration",
      },
      {
        value: "ltr_new_application_filing",
        label: "Ltr - New Application Filing",
      },
      {
        value: "ltr_ntc_of_approval_of_extension_request",
        label: "Ltr - Ntc. of Approval of Extension Request",
      },
      {
        value: "ltr_statement_of_grant_of_protection",
        label: "Ltr - Statement of Grant of Protection",
      },
      {
        value: "ltr_us_certificate_of_registration",
        label: "Ltr - US Certificate of Registration",
      },
    ],
    Jackie: [
      {
        value: "new_application_filing_agent_letter",
        label: "New Application Filing_Agent letter",
      },
      {
        value: "new_application_filing_direct_client",
        label: "New Application Filing_Direct Client",
      },
    ],
    Linda: [
      {
        value: "renewal_due_trademark_registration",
        label: "Renewal due_Trademark Registration",
      },
      {
        value: "renewal_due_service_mark_registration",
        label: "Renewal due_Service Mark Registration",
      },
    ],
    Bob: [
      {
        value: "statement_of_grant_of_protection",
        label: "Statement of Grant of Protection",
      },
      { value: "new_application_filing", label: "New Application Filing" },
    ],
  };

  const handleTemplateOption = (option) => {
    setTemplateOption(option.target.value);
  };

  const handleManAttachOptionChange = (changedOption, id) => {
    const changedOptionObject = {
      ...changedOption,
      id: id,
    };
    setManualAttachOption((prevState) =>
      prevState.map((option) =>
        option.id === id ? changedOptionObject : option
      )
    );
    if (id <= manualAttachArray.length) {
      setManualAttachArray((prevState) =>
        prevState.map((value, index) =>
          index + 1 === id ? changedOption.value : value
        )
      );
      setManualFilesMap(
        (prevState) => new Map(prevState.set(changedOption.value, ""))
      );
    } else {
      setManualAttachArray((prevState) => [...prevState, changedOption.value]);
      setManualFilesMap(
        (prevState) => new Map(prevState.set(changedOption.value, ""))
      );
    }
  };

  const getKeyByValue = (map, value) => {
    for (let [key, val] of map.entries()) {
      if (val.name === value.name) {
        return key;
      }
    }
    return null;
  };

  useEffect(() => {
    if (manualFilesMap.size > 0) {
      const newFilesArray = Array.from(manualFilesMap.entries()).map(
        ([key, value]) => ({
          [key]: value,
        })
      );
      dispatch(setManualFilesMapRedux(newFilesArray));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualFilesMap]);

  useEffect(() => {
    if (uploadFilesMap.size > 0) {
      const newFilesArray = Array.from(uploadFilesMap.entries()).map(
        ([key, value]) => ({
          [key]: value,
        })
      );
      dispatch(setUploadFilesMapRedux(newFilesArray));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFilesMap]);

  useEffect(() => {
    dispatch(setManualFileTypes(manualAttachArray));
  }, [manualAttachArray]);

  const handleUpAttachOptionChange = (changedOption, id) => {
    const changedOptionObject = {
      ...changedOption,
      id: id,
    };
    setUploadAttachOption((prevState) =>
      prevState.map((option) =>
        option.id === id ? changedOptionObject : option
      )
    );
    if (id <= uploadAttachArray.length) {
      setUploadAttachArray((prevState) =>
        prevState.map((value, index) =>
          index + 1 === id ? changedOption.value : value
        )
      );
      setUploadFilesMap(
        (prevState) => new Map(prevState.set(changedOption.value, ""))
      );
    } else {
      setUploadAttachArray((prevState) => [...prevState, changedOption.value]);
      setUploadFilesMap(
        (prevState) => new Map(prevState.set(changedOption.value, ""))
      );
    }
  };

  useEffect(() => {
    dispatch(setUploadFileTypes(uploadAttachArray));
  }, [uploadAttachArray]);

  const handleClientChange = (option) => {
    setClientOption(option.target.value);
    setTemplateOption(
      templatedropdownOptions[option?.target.value]?.[0]?.value
    );
  };

  const handleClientSelectChange = (option) => {
    setClientOption(option);
    setTemplateOption(templatedropdownOptions[option?.value]?.[0]);
  };

  const clientTemplates = clientOption
    ? templatedropdownOptions[clientOption?.value] || [
        {
          value: "Select Template",
          label: "Select Template",
          disabled: false,
          hidden: true,
        },
      ]
    : templatedropdownOptions.Default;

  const findTemplateTitle = (clientTemplates, searchValue) => {
    const templateObject = clientTemplates.find(
      (item) => item.value === searchValue
    );
    return templateObject;
  };

  const numberdropdownOptions = [
    { value: "Registration #", label: "Registration #", disabled: false },
    {
      value: "Reference/Docket #",
      label: "Reference/Docket #",
      disabled: false,
    },
  ];

  const numberSerialdropdownOptions = [
    {
      value: "Serial#",
      label: "Serial#",
      disabled: false,
      hidden: true,
    },
    { value: "Registration #", label: "Registration #", disabled: false },
    {
      value: "Reference/Docket #",
      label: "Reference/Docket #",
      disabled: false,
    },
  ];

  const manualAttachDropdownOptions = [
    { value: "wipo_notification", label: "WIPO Notification", disabled: false },
    {
      value: "statement_grant_protection",
      label: "Statement of Grant of Protection ",
      disabled: false,
    },
    {
      value: "certificate_registration",
      label: "Certificate of Registration ",
      disabled: false,
    },
    {
      value: "ltr_new_application_filing",
      label: "Ltr - New Application Filing",
    },
    {
      value: "ltr_ntc_of_approval_of_extension_request",
      label: "Ltr - Ntc. of Approval of Extension Request",
    },
    {
      value: "ltr_us_certificate_of_registration",
      label: "Ltr - US Certificate of Registration",
    },
    {
      value: "new_application_filing_agent_letter",
      label: "New Application Filing_Agent letter",
    },
    {
      value: "new_application_filing_direct_client",
      label: "New Application Filing_Direct Client",
    },
    {
      value: "renewal_due_trademark_registration",
      label: "Renewal due_Trademark Registration",
    },
    {
      value: "renewal_due_service_mark_registration",
      label: "Renewal due_Service Mark Registration",
    },
  ];
  const uploadAttachDropdownOptions = [
    { value: "wipo_notification", label: "WIPO Notification", disabled: false },
    {
      value: "statement_grant_protection",
      label: "Statement of Grant of Protection ",
      disabled: false,
    },
    {
      value: "certificate_registration",
      label: "Certificate of Registration ",
      disabled: false,
    },
    {
      value: "ltr_new_application_filing",
      label: "Ltr - New Application Filing",
    },
    {
      value: "ltr_ntc_of_approval_of_extension_request",
      label: "Ltr - Ntc. of Approval of Extension Request",
    },
    {
      value: "ltr_us_certificate_of_registration",
      label: "Ltr - US Certificate of Registration",
    },
    {
      value: "new_application_filing_agent_letter",
      label: "New Application Filing_Agent letter",
    },
    {
      value: "new_application_filing_direct_client",
      label: "New Application Filing_Direct Client",
    },
    {
      value: "renewal_due_trademark_registration",
      label: "Renewal due_Trademark Registration",
    },
    {
      value: "renewal_due_service_mark_registration",
      label: "Renewal due_Service Mark Registration",
    },
  ];

  let invalidFiles = [];

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validFiles = [];

    filesArray.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "msg") {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });
    if (invalidFiles.length > 0) {
      setIsUploadOutlookValid(false);
      setUploadError("Please upload a .msg file.");
      return;
    }
    setUploadPopupFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  useEffect(() => {
    dispatch(setUploadOutlookFiles(uploadPopupFiles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadPopupFiles]);

  let invalidSignFiles = [];

  const handleSignFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validSignFiles = [];

    filesArray.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "rtf" || fileExtension === "txt") {
        validSignFiles.push(file);
      } else {
        invalidSignFiles.push(file);
      }
    });
    if (invalidSignFiles.length > 0) {
      document.getElementById("attach-sign").value = "";
      setSelectTemplError("Please upload a .rtf or .txt file.");
      return;
    }
    setManualSignFiles(validSignFiles);
  };

  useEffect(() => {
    dispatch(setSignatureFiles(manualSignFiles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualSignFiles]);

  let invalidManualAttachFiles = [];

  const handleManualAttachFileChange = (event, option) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validManualAttachFiles = [];

    filesArray.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "pdf") {
        validManualAttachFiles.push(file);
      } else {
        invalidManualAttachFiles.push(file);
      }
    });
    if (invalidManualAttachFiles.length > 0) {
      document.getElementById(`manual-attach-${option?.id}`).value = "";
      setIsManualAttachValid(false);
      setManualError("Please upload a .pdf file.");
      return;
    }
    if (manualFilesMap.get(option?.value) !== "") {
      document.getElementById(`manual-attach-${option?.id}`).value = "";
      setIsManualAttachValid(false);
      setManualError("Please upload a single file only.");
      return;
    }
    if (option?.value === "type") {
      document.getElementById(`manual-attach-${option?.id}`).value = "";
      setIsManualAttachValid(false);
      setManualError("Please select type first.");
      return;
    }
    setManualFilesMap(
      (prevState) =>
        new Map(prevState.set(option.value, validManualAttachFiles[0]))
    );
    setManualAttachFiles((prevFiles) => [
      ...prevFiles,
      ...validManualAttachFiles,
    ]);
  };

  useEffect(() => {
    dispatch(setManualInputFiles(manualAttachFiles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualAttachFiles]);

  let invalidUploadAttachFiles = [];

  const handleUploadAttachFileChange = (event, option) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validUploadAttachFiles = [];

    filesArray.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "pdf") {
        validUploadAttachFiles.push(file);
      } else {
        invalidUploadAttachFiles.push(file);
      }
    });
    if (invalidUploadAttachFiles.length > 0) {
      document.getElementById(`upload-attach-${option?.id}`).value = "";
      setIsUploadAttachValid(false);
      setUploadError("Please upload a .pdf file.");
      return;
    }
    if (uploadFilesMap.get(option?.value) !== "") {
      document.getElementById(`upload-attach-${option?.id}`).value = "";
      setIsUploadAttachValid(false);
      setUploadError("Please upload a single file only.");
      return;
    }
    if (option?.value === "type") {
      document.getElementById(`upload-attach-${option?.id}`).value = "";
      setIsUploadAttachValid(false);
      setUploadError("Please select type first.");
      return;
    }
    setUploadFilesMap(
      (prevState) =>
        new Map(prevState.set(option.value, validUploadAttachFiles[0]))
    );
    setUploadAttachFiles((prevFiles) => [
      ...prevFiles,
      ...validUploadAttachFiles,
    ]);
  };

  useEffect(() => {
    dispatch(setUploadInputFiles(uploadAttachFiles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadAttachFiles]);

  const handleRemoveFile = (index) => {
    const updatedFiles = uploadPopupFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("upload-outlook").value = "";
    setUploadPopupFiles(updatedFiles);
  };

  const handleRemoveSignFile = (index) => {
    const updatedFiles = manualSignFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("attach-sign").value = "";
    setManualSignFiles(updatedFiles);
  };

  const handleManualAttachRemoveFile = (index) => {
    const updatedFiles = manualAttachFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    const key = getKeyByValue(manualFilesMap, manualAttachFiles[index]);
    setManualFilesMap((prevState) => new Map(prevState.set(key, "")));
    document.getElementById(`manual-attach-${index + 1}`).value = "";
    setManualAttachFiles(updatedFiles);
  };

  const handleUploadAttachRemoveFile = (index) => {
    const updatedFiles = uploadAttachFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    const key = getKeyByValue(uploadFilesMap, uploadAttachFiles[index]);
    setUploadFilesMap((prevState) => new Map(prevState.set(key, "")));
    document.getElementById(`upload-attach-${index + 1}`).value = "";
    setUploadAttachFiles(updatedFiles);
  };

  const selectDropdownIndicator = (props) => {
    const {
      selectProps: { menuIsOpen },
    } = props;

    const rotateClass = menuIsOpen ? "rotate-dropdown-180" : "";

    return (
      <components.DropdownIndicator {...props}>
        <img
          src={DropdownImage}
          className={`select-img ${rotateClass}`}
          alt="Select Dropdown"
        />
      </components.DropdownIndicator>
    );
  };

  const handleNumberValueChange = (e) => {
    setSelectNum(e.target.value);
  };

  const isRadioEmpty = () => {
    if (letterRadioOption === "") {
      setIsRadioValid(false);
      return true;
    }
    return false;
  };

  const isNumberEmptyWrong = () => {
    if (selectNum === "" || /^[A-Z0-9]{1,15}$/.test(selectNum) === false) {
      setIsSelectNumValid(false);
      return true;
    }
    return false;
  };

  const isSelectEmpty = () => {
    if (clientOption?.value === "Select Client") {
      setIsSelectValid(false);
      return true;
    }
    return false;
  };

  const isManualRadioNotValid = () => {
    if (letterRadioOption === "manual" && manualPasteEmail === "") {
      setIsManualRadioValid(false);
      return true;
    }
    return false;
  };

  const isUploadRadioNotValid = () => {
    if (
      letterRadioOption === "upload" &&
      (uploadPopupFiles.length === 0 || invalidFiles.length > 0)
    ) {
      setIsUploadRadioValid(false);
      return true;
    }
    return false;
  };
  const handleSelectTemp = () => {
    if (clientOption.value === "Select Client") {
      setSelectTemplError("Please select the client first.");
    }
  };

  const handleCreate = () => {
    if (
      isRadioEmpty() === true &&
      isSelectEmpty() === true &&
      isNumberEmptyWrong() === true
    ) {
      setCreateError(
        "Please Select Email Input Radio Option, Client and Enter Valid Number."
      );
      return;
    }
    if (
      isRadioEmpty() === false &&
      isSelectEmpty() === true &&
      isNumberEmptyWrong() === true
    ) {
      setCreateError("Please Select Client and Enter Valid Number.");
      return;
    }
    if (
      isRadioEmpty() === true &&
      isSelectEmpty() === false &&
      isNumberEmptyWrong() === false
    ) {
      setCreateError("Please Select Radio Option.");
      return;
    }
    if (
      isRadioEmpty() === true &&
      isSelectEmpty() === true &&
      isNumberEmptyWrong() === false
    ) {
      setCreateError("Please Select Radio Option And Client.");
      return;
    }
    if (
      isRadioEmpty() === false &&
      isSelectEmpty() === false &&
      isNumberEmptyWrong() === true
    ) {
      setCreateError("Please Enter Valid Number.");
      return;
    }
    if (
      isRadioEmpty() === false &&
      isSelectEmpty() === true &&
      isNumberEmptyWrong() === false
    ) {
      setCreateError("Please Select Client.");
      return;
    }
    if (
      isRadioEmpty() === true &&
      isSelectEmpty() === false &&
      isNumberEmptyWrong() === true
    ) {
      setCreateError("Please Select Radio Option And Number.");
      return;
    }
    if (isManualRadioNotValid() === true) {
      setCreateError("Please Paste Email For Manual Input.");
      return;
    }
    if (isUploadRadioNotValid() === true) {
      setCreateError("Please Upload .pdf File For Upload Input.");
      return;
    }
    dispatch(setManualEmailText(manualPasteEmail));
    dispatch(setCaseClientTypeOption(clientOption));
    dispatch(setCaseNumberTypeOption(numberOption));
    dispatch(setCaseTemplateTypeOption(templateOption));
    dispatch(setCaseClient(clientOption?.label));
    dispatch(setCaseTemplate(templateOption?.label));
    dispatch(setCaseNumber(selectNum));
    dispatch(setManualAttachOptions(manualAttachOption));
    dispatch(setUploadAttachOptions(uploadAttachOption));
    if (
      templateOption?.value === "ltr_certificate_of_international_registration"
    ) {
      navigate("/cir");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (templateOption?.value === "ltr_new_application_filing") {
      navigate("/naf");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (templateOption?.value === "ltr_ntc_of_approval_of_extension_request") {
      navigate("/aer");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (templateOption?.value === "ltr_statement_of_grant_of_protection") {
      navigate("/sgp");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (templateOption?.value === "ltr_us_certificate_of_registration") {
      navigate("/ucr");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (
      clientOption?.value === "Jackie" &&
      templateOption?.value === "new_application_filing_agent_letter"
    ) {
      navigate("/nafa");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (
      clientOption?.value === "Jackie" &&
      templateOption?.value === "new_application_filing_direct_client"
    ) {
      navigate("/nafd");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (
      clientOption?.value === "Linda" &&
      templateOption?.value === "renewal_due_trademark_registration"
    ) {
      navigate("/rtr");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (
      clientOption?.value === "Linda" &&
      templateOption?.value === "renewal_due_service_mark_registration"
    ) {
      navigate("/rsr");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (
      clientOption?.value === "Bob" &&
      templateOption?.value === "statement_of_grant_of_protection"
    ) {
      navigate("/sgp");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    if (
      clientOption?.value === "Bob" &&
      templateOption?.value === "new_application_filing"
    ) {
      navigate("/naf");
      dispatch(setIsLetterPopupOpen(false));
      return;
    }
    return;
  };

  const handleSaveManual = () => {
    if (manualPasteEmail === "") {
      setIsManualPasteValid(false);
      setManualError("Please paste email.");
      return;
    }
    if (
      manualAttachOption[0].value !== "type" &&
      manualAttachFiles.length === 0
    ) {
      setIsManualAttachValid(false);
      setManualError("Please upload a .pdf file.");
      return;
    }
    dispatch(setSelectOptions(true));
    return;
  };

  const handleSaveUpload = () => {
    if (uploadPopupFiles.length === 0 || invalidFiles.length > 0) {
      setIsUploadOutlookValid(false);
      setUploadError("Please upload a .msg file");
      return;
    }
    if (
      uploadAttachOption[0].value !== "type" &&
      uploadAttachFiles.length === 0
    ) {
      setIsUploadAttachValid(false);
      setUploadError("Please upload a .pdf file.");
      return;
    }
    dispatch(setSelectOptions(true));
    return;
  };

  const handleAddNewTypeManual = () => {
    if (manualAttachOption.length === 2) {
      setManualError("A maximum of 2 files is allowed.");
      return;
    }
    const newId = manualAttachOption.length + 1;
    setManualAttachOption([
      ...manualAttachOption,
      { id: newId, value: "type", label: "Type", disabled: false },
    ]);
  };

  const handleRemoveTypeManual = (option) => {
    const updatedOptions = manualAttachOption.filter(
      (item) => item.id !== option?.id
    );
    const updatedManualAttachArray = manualAttachArray.filter(
      (item, index) => index + 1 !== option?.id
    );
    setManualAttachOption([...updatedOptions]);
    setManualAttachArray([...updatedManualAttachArray]);
    if (manualAttachFiles.length > 1) {
      const updatedManualAttachFiles = manualAttachFiles.filter(
        (item, index) => item.name !== manualFilesMap.get(option?.value)?.name
      );
      setManualAttachFiles([...updatedManualAttachFiles]);
    }
    setManualFilesMap((prevState) => {
      const newMap = new Map(prevState);
      newMap.delete(option.value);
      return newMap;
    });
  };

  const handleAddNewTypeUpload = () => {
    if (uploadAttachOption.length === 2) {
      setUploadError("A maximum of 2 files is allowed.");
      return;
    }
    const newId = uploadAttachOption.length + 1;
    setUploadAttachOption([
      ...uploadAttachOption,
      { id: newId, value: "type", label: "Type", disabled: false },
    ]);
  };

  const handleRemoveTypeUpload = (option) => {
    const updatedOptions = uploadAttachOption.filter(
      (item) => item.id !== option?.id
    );
    const updatedUploadAttachArray = uploadAttachArray.filter(
      (item, index) => index + 1 !== option?.id
    );
    setUploadAttachOption([...updatedOptions]);
    setUploadAttachArray([...updatedUploadAttachArray]);
    if (uploadAttachFiles.length > 1) {
      const updatedUploadAttachFiles = uploadAttachFiles.filter(
        (item, index) => item.name !== uploadFilesMap.get(option?.value)?.name
      );
      setUploadAttachFiles([...updatedUploadAttachFiles]);
    }
    setUploadFilesMap((prevState) => {
      const newMap = new Map(prevState);
      newMap.delete(option.value);
      return newMap;
    });
  };

  const handleManualEmailChange = (e) => {
    setManualPasteEmail(e.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (manualError === "A maximum of 2 files is allowed.") {
        setManualError("");
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [manualError]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (uploadError === "A maximum of 2 files is allowed.") {
        setUploadError("");
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [uploadError]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        selectTemplError === "Please select the client first." ||
        selectTemplError === "Please upload a .rtf or .txt file."
      ) {
        setSelectTemplError("");
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [selectTemplError]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isUploadOutlookValid === false) {
        setIsUploadOutlookValid(true);
        setUploadError("");
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [isUploadOutlookValid]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isManualAttachValid === false) {
        setIsManualAttachValid(true);
        setManualError("");
      }
      if (isUploadAttachValid === false) {
        setIsUploadAttachValid(true);
        setUploadError("");
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [isManualAttachValid, isUploadAttachValid]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (ismanualPasteValid === false) {
        setIsManualPasteValid(true);
        setManualError("");
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [ismanualPasteValid]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isSelectNumValid === false) {
        setIsSelectNumValid(true);
        setCreateError("");
      }
      if (isSelectValid === false) {
        setIsSelectValid(true);
        setCreateError("");
      }
      if (isRadioValid === false) {
        setIsRadioValid(true);
        setCreateError("");
      }
      if (isManualRadioValid === false) {
        setIsManualRadioValid(true);
        setCreateError("");
      }
      if (isUploadRadioValid === false) {
        setIsUploadRadioValid(true);
        setCreateError("");
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [
    isSelectNumValid,
    isSelectValid,
    isRadioValid,
    isManualRadioValid,
    isUploadRadioValid,
  ]);

  return (
    <div className="create-letter-popup-overlay">
      <div className="create-letter-popup d-flex flex-column align-items-center justify-content-center">
        <div className="message">
          <span>Create Letter</span>
          <img
            src={CloseIcon}
            onClick={() => {
              dispatch(setLetterRadioOption("manual"));
              dispatch(setSelectOptions(false));
              onClose();
            }}
            className="close-button close-icon-cross"
            alt="cross"
          />
        </div>
        <div className="d-flex submessage py-4 flex-column align-items-center">
          <div className="d-flex flex-column email-input-container">
            <div className="email-input-title">Email/Input</div>
            <div className="d-flex flex-row email-input-radio-container px-3 py-2 align-items-center justify-content-evenly">
              <div className="d-flex flex-row align-items-center radio-margin">
                <Input
                  type="radio"
                  value="manual"
                  checked={
                    emailRadioOption === "manual" ||
                    letterRadioOption === "manual"
                  }
                  onChange={handleRadioChange}
                  onClick={(e) => setEmailRadioOption(e.target.value)}
                  className={`create-radio me-2 ${
                    isRadioValid === false && letterRadioOption === ""
                      ? "is-rl-radio-invalid"
                      : ""
                  }`}
                />
                <label className="radio-label ms-1">Manual Input</label>
              </div>
              <div className="d-flex flex-row align-items-center radio-margin">
                <Input
                  type="radio"
                  value="upload"
                  checked={
                    emailRadioOption === "upload" ||
                    letterRadioOption === "upload"
                  }
                  onChange={handleRadioChange}
                  onClick={(e) => setEmailRadioOption(e.target.value)}
                  className={`create-radio me-2 ${
                    isRadioValid === false && letterRadioOption === ""
                      ? "is-rl-radio-invalid"
                      : ""
                  }`}
                />
                <label className="radio-label ms-1">Upload Input</label>
              </div>
            </div>
          </div>
          {emailRadioOption === "manual" && (
            <>
              <div className="d-flex flex-column manual-input-container mt-1">
                <textarea
                  rows="10"
                  cols="10"
                  className={`manual-text-input mt-1 py-2 px-2 ${
                    ismanualPasteValid === false || isManualRadioValid === false
                      ? "is-rl-field-invalid"
                      : ""
                  }`}
                  placeholder="Paste Email"
                  value={manualPasteEmail}
                  onChange={handleManualEmailChange}
                />
                <div className="manual-btn-container">
                  {manualAttachOption.map((option) => (
                    <div
                      key={option.id}
                      className="d-flex flex-row align-items-center px-2 py-1 mt-1"
                    >
                      <Select
                        options={manualAttachDropdownOptions}
                        className={`mx-1 manual-select`}
                        value={option}
                        onChange={(selectedOption) =>
                          handleManAttachOptionChange(selectedOption, option.id)
                        }
                        placeholder={"Type"}
                        styles={extendedSelectStyles}
                        components={{
                          DropdownIndicator: selectDropdownIndicator,
                        }}
                        isSearchable={false}
                      />
                      <div className={`manual-file-wrapper`}>
                        <div
                          className={`d-flex flex-row manual-upload-wrapper justify-content-center px-4 ${
                            isManualAttachValid === false
                              ? "is-rl-field-invalid"
                              : ""
                          }`}
                        >
                          <img
                            src={UploadImage}
                            alt="upload"
                            className="manual-upload-image mt-1"
                          />
                          <span
                            className="manual-attach-text ps-1 mt-1"
                            style={{ fontSize: "12px" }}
                          >
                            Upload Attachment
                          </span>
                        </div>
                        <Input
                          type="file"
                          className={`manual-attach-input`}
                          onChange={(e) =>
                            handleManualAttachFileChange(e, option)
                          }
                          id={`manual-attach-${option?.id}`}
                          accept=".pdf, application/pdf"
                        />
                      </div>
                      {option?.id === 1 && (
                        <div
                          className="add-img-container d-flex flex-row align-items-center ms-1"
                          onClick={handleAddNewTypeManual}
                        >
                          <img src={AddImage} className="add-img" alt="Add" />
                        </div>
                      )}
                      {option?.id !== 1 && (
                        <div
                          className="add-img-container d-flex flex-row align-items-center ms-1"
                          onClick={() => {
                            handleRemoveTypeManual(option);
                          }}
                        >
                          <img
                            src={AddImage}
                            className="add-img transform-add-img"
                            alt="Cross"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div
                  className="pt-1"
                  style={{ height: "10px", fontSize: "12px" }}
                >
                  {manualError !== "" && (
                    <span className="text-danger">{manualError}</span>
                  )}
                </div>
                {manualAttachFiles.length > 0 && (
                  <div
                    className="manual-create-letter-files mt-2"
                    style={{ height: "auto" }}
                  >
                    <ul className="files-list">
                      {manualAttachFiles.map((file, index) => (
                        <li key={index}>
                          <div className="d-flex flex-row">
                            <span>
                              <img
                                src={BulletImage}
                                className="files-list-bullet"
                                alt="bullet"
                              />
                            </span>
                            <span className="ms-1">{file.name}</span>
                          </div>
                          <span
                            className="remove-file"
                            onClick={() => handleManualAttachRemoveFile(index)}
                          >
                            <img
                              src={FileCloseIcon}
                              className="files-close-icon"
                              alt="file remove"
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {selectOptions === false && (
                  <div className="d-flex flex-row save-email-container mt-2 mb-2 justify-content-end">
                    <Button
                      className={`save-email-btn-style`}
                      onClick={handleSaveManual}
                    >
                      Next
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
          {emailRadioOption === "upload" && (
            <>
              <div className="d-flex flex-column upload-input-container mt-2">
                <div className="d-flex flex-row justify-content-center upload-outlook-container px-2 py-2">
                  <div className="upload-file-wrapper">
                    <div
                      className={`d-flex flex-row upload-upload-wrapper justify-content-center px-4 ${
                        isUploadOutlookValid === false ||
                        isUploadRadioValid === false
                          ? "is-rl-field-invalid"
                          : ""
                      }`}
                    >
                      <img
                        src={UploadImage}
                        alt="upload"
                        className="upload-upload-image mt-1"
                      />
                      <span className={`upload-attach-text px-3 py-1`}>
                        Upload Outlook File
                      </span>
                    </div>
                    <Input
                      type="file"
                      id="upload-outlook"
                      className={`upload-attach-input`}
                      onChange={handleFileChange}
                      accept=".msg"
                    />
                  </div>
                </div>
                {uploadPopupFiles.length > 0 && (
                  <div
                    className="uploaded-create-letter-files mt-2"
                    style={{ height: "auto" }}
                  >
                    <ul className="files-list">
                      {uploadPopupFiles.map((file, index) => (
                        <li key={index}>
                          <div className="d-flex flex-row">
                            <span>
                              <img
                                src={BulletImage}
                                className="files-list-bullet"
                                alt="bullet"
                              />
                            </span>
                            <span className="ms-1">{file.name}</span>
                          </div>
                          <span
                            className="remove-file"
                            onClick={() => handleRemoveFile(index)}
                          >
                            <img
                              src={FileCloseIcon}
                              className="files-close-icon"
                              alt="file remove"
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="upload-btn-container">
                  {uploadAttachOption.map((option) => (
                    <div
                      key={option.id}
                      className="d-flex flex-row align-items-center px-2 py-1 mt-1"
                    >
                      <Select
                        options={uploadAttachDropdownOptions}
                        onChange={(selectedOption) =>
                          handleUpAttachOptionChange(selectedOption, option.id)
                        }
                        value={option}
                        className={`mx-1 upload-select`}
                        placeholder={"Type"}
                        styles={extendedSelectStyles}
                        components={{
                          DropdownIndicator: selectDropdownIndicator,
                        }}
                        isSearchable={false}
                      />
                      <div className={`upload-file-wrapper`}>
                        <div
                          className={`d-flex flex-row upload-upload-wrapper px-4 justify-content-center ${
                            isUploadAttachValid === false
                              ? "is-rl-field-invalid"
                              : ""
                          }`}
                        >
                          <img
                            src={UploadImage}
                            alt="upload"
                            className="upload-upload-image mt-1"
                          />
                          <span
                            className="upload-attach-text ps-1 mt-1"
                            style={{ fontSize: "12px" }}
                          >
                            Upload Attachment
                          </span>
                        </div>
                        <Input
                          type="file"
                          id={`upload-attach-${option?.id}`}
                          className={`upload-attach-input`}
                          onChange={(e) =>
                            handleUploadAttachFileChange(e, option)
                          }
                          accept=".pdf, application/pdf"
                        />
                      </div>
                      {option?.id === 1 && (
                        <div
                          className="add-img-container d-flex flex-row align-items-center ms-1"
                          onClick={handleAddNewTypeUpload}
                        >
                          <img src={AddImage} className="add-img" alt="Add" />
                        </div>
                      )}
                      {option?.id !== 1 && (
                        <div
                          className="add-img-container d-flex flex-row align-items-center ms-1"
                          onClick={() => {
                            handleRemoveTypeUpload(option);
                          }}
                        >
                          <img
                            src={AddImage}
                            className="add-img transform-add-img"
                            alt="Cross"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div
                  className="pt-1"
                  style={{ height: "10px", fontSize: "12px" }}
                >
                  {uploadError !== "" && (
                    <span className="text-danger">{uploadError}</span>
                  )}
                </div>
                {uploadAttachFiles.length > 0 && (
                  <div
                    className="uploaded-create-letter-files mt-2"
                    style={{ height: "auto" }}
                  >
                    <ul className="files-list">
                      {uploadAttachFiles.map((file, index) => (
                        <li key={index}>
                          <div className="d-flex flex-row">
                            <span>
                              <img
                                src={BulletImage}
                                className="files-list-bullet"
                                alt="bullet"
                              />
                            </span>
                            <span className="ms-1">{file.name}</span>
                          </div>
                          <span
                            className="remove-file"
                            onClick={() => handleUploadAttachRemoveFile(index)}
                          >
                            <img
                              src={FileCloseIcon}
                              className="files-close-icon"
                              alt="file remove"
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {selectOptions === false && (
                  <div className="d-flex flex-row save-email-container mt-2 mb-2 justify-content-end">
                    <Button
                      className={`save-email-btn-style`}
                      onClick={handleSaveUpload}
                    >
                      Next
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
          {selectOptions === true && (
            <>
              <div className="d-flex flex-column email-input-container">
                <div className="email-input-title">Select Client</div>
                <div className="d-flex flex-column select-client-input-container justify-content-center">
                  <div className="d-flex flex-row mt-1 px-3 py-1 align-items-center justify-content-around">
                    <span
                      className="d-flex flex-row align-items-center"
                      style={{ width: "100%" }}
                    >
                      <span style={{ width: "42%" }}>
                        <Select
                          options={clientdropdownOptions}
                          className={` `}
                          value={clientOption}
                          onChange={handleClientSelectChange}
                          placeholder={"Select Client"}
                          styles={extendedSelectStylesSerial}
                          classNames={{
                            control: (state) =>
                              isSelectValid === false
                                ? "is-rl-field-invalid"
                                : "",
                          }}
                          components={{
                            DropdownIndicator: selectDropdownIndicator,
                          }}
                          isSearchable={false}
                        />
                      </span>
                      <span
                        style={{ width: "58%" }}
                        className="mx-1"
                        title={`${templateOption?.label}`}
                        onClick={handleSelectTemp}
                      >
                        <Select
                          options={clientTemplates}
                          value={templateOption}
                          onChange={setTemplateOption}
                          className={``}
                          placeholder={"Select Template"}
                          components={{
                            DropdownIndicator: selectDropdownIndicator,
                          }}
                          styles={extendedDisabledSelectStyles}
                          isDisabled={
                            clientOption.value === "Select Client"
                              ? true
                              : false
                          }
                          isSearchable={false}
                        />
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column px-2 pb-1">
                    {clientOption?.value === "Default" && (
                      <div className="file-wrapper px-2">
                        <div className="d-flex flex-row signature-wrapper px-2 mt-1">
                          <img
                            src={PenImage}
                            alt="pen"
                            className="pen-image mt-1"
                          />
                          <span className="signature-text px-2 py-1">
                            Attach Signature
                          </span>
                        </div>
                        <Input
                          type="file"
                          id={`attach-sign`}
                          className={`attach-input`}
                          onChange={handleSignFileChange}
                          accept=".rtf,.txt"
                        />
                      </div>
                    )}
                  </div>
                  {manualSignFiles.length > 0 && (
                    <div
                      className="attach-sign-create-letter-files mt-1"
                      style={{ height: "auto" }}
                    >
                      <ul className="files-list">
                        {manualSignFiles.map((file, index) => (
                          <li key={index}>
                            <div className="d-flex flex-row ms-3">
                              <span>
                                <img
                                  src={BulletImage}
                                  className="files-list-bullet"
                                  alt="bullet"
                                />
                              </span>
                              <span className="ms-1 attach-sign-name-span">
                                {file.name}
                              </span>
                            </div>
                            <span
                              className="remove-file"
                              onClick={() => handleRemoveSignFile(index)}
                            >
                              <img
                                src={FileCloseIcon}
                                className="files-close-icon"
                                alt="file remove"
                              />
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="pt-1"
                style={{ height: "10px", fontSize: "12px" }}
              >
                {selectTemplError !== "" && (
                  <span className="text-danger">{selectTemplError}</span>
                )}
              </div>
              <div className="d-flex flex-column email-input-container">
                <div className="email-input-title">Enter Matter Number</div>
                <div className="d-flex flex-row select-number-input-container px-3 py-2 align-items-center justify-content-around">
                  <span
                    className="d-flex flex-row align-items-center"
                    style={{ width: "100%" }}
                  >
                    <span style={{ width: "42%" }}>
                      <Select
                        options={numberSerialdropdownOptions}
                        value={numberOption}
                        className={` `}
                        onChange={handleNumberSelectChange}
                        placeholder={"Serial #"}
                        components={{
                          DropdownIndicator: selectDropdownIndicator,
                        }}
                        styles={extendedSelectStylesSerial}
                        isSearchable={false}
                      />
                    </span>
                    <span style={{ width: "58%" }} className="mx-1">
                      <Input
                        className={`px-2 number-input ${
                          isSelectNumValid === false
                            ? "is-rl-field-invalid"
                            : ""
                        }`}
                        placeholder="Enter Number"
                        value={selectNum}
                        onChange={handleNumberValueChange}
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div
                className="pt-1"
                style={{ height: "15px", fontSize: "12px" }}
              >
                {createError !== "" && (
                  <span className="text-danger">{createError}</span>
                )}
              </div>
              <div className="d-flex flex-row create-btn-container mt-2 justify-content-center">
                <Button
                  className={`create-btn-back-style mx-1`}
                  onClick={() => {
                    dispatch(setLetterRadioOption("manual"));
                    dispatch(setSelectOptions(false));
                    onClose();
                  }}
                >
                  Back
                </Button>
                <Button className={`create-btn-style`} onClick={handleCreate}>
                  Create
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateLetterPopup;
