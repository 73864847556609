import React from "react";

const SignFileList = (props) => {
  const {
    handleRemoveSignFile,
    FileCloseIcon,
    attachSignFile,
    setAttachSignFile,
  } = props;
  return (
    <div
      className="uploaded-template-files d-flex flex-row justify-content-end exclude-from-pdf-files"
      style={{ height: "auto" }}
    >
      <ul className="files-list">
        {attachSignFile.map((file, index) => (
          <li key={index}>
            <div className="d-flex flex-row align-items-center">
              <span className="">{file.name}</span>

              <span
                className="template-remove-file"
                onClick={() =>
                  handleRemoveSignFile(index, attachSignFile, setAttachSignFile)
                }
              >
                <img
                  src={FileCloseIcon}
                  className="template-files-close-icon"
                  alt="file remove"
                />
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SignFileList;
