import { React, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../common/sb08-table';
import './index.scss';
import { formatDate, PRIORITY_ORDER } from '../../../util/utils';
import { highlightText } from '../../../utils/highlight';
import { setFormattedIdsData } from '../../../store/reducer/searchSlice';

const SB08FormDetails = ({ searchTerm, selectedColumn }) => {
  const { familyBiblio } = useSelector((state) => state.family);
  const discardedIdsData = useSelector((state) => state.search.discardedIdsData)
  const selectedRefData = useSelector((state) => state.search.selectedRefData);
  const formattedReferenceData = useSelector((state) => state.references.formattedReferenceData);
  const [addIDSRowData, setAddIDSRowData] = useState([])
  const [updateIDSRowData, setUpdateIDSRowData] = useState([])
  const dispatch = useDispatch();

  const handleUpdateIDSRowData = (data) => {
    setUpdateIDSRowData(data);
  };

  const handleAddRowIDSData = (data) => {
    setAddIDSRowData(data);
  };

  const getHighestPriorityDocId = (docIds) => {
    if (!Array.isArray(docIds)) {
      return {};
    }
    const sortedDocIds = [...docIds].sort((a, b) => {
      const priorityA = PRIORITY_ORDER.indexOf(a['@document-id-type']);
      const priorityB = PRIORITY_ORDER.indexOf(b['@document-id-type']);
      return priorityA - priorityB;
    });
    return sortedDocIds[0] || {};
  };

  const familyMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member'];
  const familyMembersArray = Array.isArray(familyMembers) ? familyMembers : (familyMembers ? [familyMembers] : []);

  let tableIndex = 1;

  const formattedData = familyMembersArray.flatMap(member => {
    const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited'];
    if (!referencesCited || !Array.isArray(referencesCited['citation'])) {
      return [];
    }

    return referencesCited['citation'].map(citation => {
      const docIds = Array.isArray(citation.patcit?.['document-id']) ? citation.patcit?.['document-id'] : [];
      const docId = getHighestPriorityDocId(docIds);
      const dnumType = citation.patcit?.['@dnum-type'] || "";
      const country = docId?.['country']?.['$'] || "";
      const docNumber = docId?.['doc-number']?.['$'] || "";
      const kind = docId?.['kind']?.['$'] || "";
      const date = docId?.['date']?.['$'] || "";
      const publicationNumber = `${country}${docNumber}${kind}`;
      const publicationDate = formatDate(date);

      const publicationType = country === "US" 
        ? /^[0-9]{4}/.test(publicationNumber) 
          ? "U.S.PATENT APPLICATION PUBLICATIONS" 
          : "U.S.PATENT" 
        : "FOREIGN PATENT DOCUMENTS";

      const appDocId = docIds.find(id => id['@document-id-type'] === 'docdb');
      const applicationNumber = appDocId?.['doc-number']?.['$'] || "";
      const filingDate = formatDate(citation.patcit?.['application-date']?.[0]?.['date']?.[0] || "");
      const patenteeName = appDocId?.['name']?.['$'] || "";

      if (publicationNumber || kind || publicationDate || applicationNumber || filingDate || patenteeName || dnumType) {
        return {
          "Sr.No": tableIndex++,
          "References": publicationNumber,
          "Kind Code": kind,
          "Issued / Pub. Date": publicationDate,
          "Patentee Name": patenteeName,
          "Type": publicationType,
        };
      }

      return null;
    }).filter(row => row !== null);
  });
  const headers = [
    "Sr.No",
    "References",
    "Kind Code",
    "Issued / Pub. Date",
    "Patentee Name",
    "Type",
  ];

  const checkedRefData = selectedRefData.length === 0 ? [] : selectedRefData.filter((item) => item.Selected === true)
  const finalDiscardIDSData = discardedIdsData.length === 0 ? [] : discardedIdsData
  const noCheckedData = checkedRefData.length === 0 && discardedIdsData.length === 0 ? formattedReferenceData : []
  const idsTableData = finalDiscardIDSData.length !== 0 ? finalDiscardIDSData : checkedRefData.length !== 0 ? checkedRefData : noCheckedData
  const combinedData = updateIDSRowData.length === 0 || updateIDSRowData.length >= 1 ? [...idsTableData, ...addIDSRowData] : [...updateIDSRowData];
  const highlightedData = combinedData.map((item, index) => ({
    ...item,
    "Sr.No": index + 1,
    "References": highlightText(item["References"] || '', searchTerm, selectedColumn, 'Serial #'),
  }));

  useEffect(() => {
    if (highlightedData.length > 0) {
      dispatch(setFormattedIdsData(highlightedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addIDSRowData, updateIDSRowData, dispatch]);
  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const data = Array.from({ length: 10 }, () => defaultRow);
  return (
    <div className="sb08">
      <Table headers={headers} data={highlightedData.length > 0 ? highlightedData : data} handleUpdateIDSRowData={handleUpdateIDSRowData} handleAddRowIDSData={handleAddRowIDSData} />
    </div>
  );
};

export default SB08FormDetails;