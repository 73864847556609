import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const rlAdminApi = createApi({
    reducerPath: "rlAdminApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        saveRlTemplate: builder.mutation({
            query: (data) => ({
                url: 'rl-admin/create-rl-admin',
                method: 'POST',
                body: data,
            }),
        }),
        getSavedRlTemplatesByUser: builder.query({
            query: ({ userId }) => `rl-admin/get-user-rl-admin/${userId}`,
            keepUnusedDataFor: 0
        }),
    }),
});

export const { useSaveRlTemplateMutation, useGetSavedRlTemplatesByUserQuery } = rlAdminApi;
export default rlAdminApi;
