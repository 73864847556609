import React from "react";

const AttachFileList = (props) => {
  const { attachFiles, FileCloseIcon, handleRemoveFile } = props;

  return (
    <div
      className="uploaded-template-files d-flex flex-row justify-content-end exclude-from-pdf-files"
      style={{ height: "auto" }}
    >
      <ul className="files-list">
        {attachFiles.map((file, index) => (
          <li key={index}>
            <div className="d-flex flex-row align-items-center">
              <span className="">{file.name}</span>

              <span
                className="template-remove-file"
                onClick={() => handleRemoveFile(index)}
              >
                <img
                  src={FileCloseIcon}
                  className="template-files-close-icon"
                  alt="file remove"
                />
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AttachFileList;
