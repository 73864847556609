import React, { useState, useRef, useEffect } from "react";
import Button from "../../common/button";
import Input from "../../common/input";
import UploadImage from "../../../assets/images/create-letter-manual-upload.png";
import BackImage from "../../../assets/images/template-back.png";
import SendImage from "../../../assets/images/table-download-icon.png";
import ScheduleImage from "../../../assets/images/template-schedule.png";
import SaveImage from "../../../assets/images/template-save.png";
import FileCloseIcon from "../../../assets/images/create-letter-file-close.png";
import {
  setIsCanTempPopupOpen,
  setIsSaveTempPopupOpen,
  setIsScheduleMailPopupOpen,
} from "../../../store/reducer/templateSlice";
import { useDispatch, useSelector } from "react-redux";
import CancelTemplatePopup from "../popup/cancel-template-popup";
import SaveTemplatePopup from "../popup/save-template-popup";
import ScheduleMailPopup from "../popup/schedule-mail-popup";
import { handleRemoveSignFile } from "../common/signature-file-functions";
import SignFileList from "../common/signature-file-list";
import RlTemplateButtons from "../common/rl-template-buttons";
import AttachFileList from "../common/attach-file-list";
import { handleDownloadPdf } from "../common/download-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { handleCopyTemplate } from "../common/copy-template";
import SgpTemplateHeader from "./sgp-template-header";
import { setIsDownloadPopupOpen } from "../../../store/reducer/templateSlice";
import RlTemplatePopups from "../common/rl-template-popups";
import RlTooltip from "../common/rl-tooltip";

const EmailSection = () => {
  const dispatch = useDispatch();
  const isCanTempPopupOpen = useSelector(
    (state) => state.template.isCanTempPopupOpen
  );
  const isSaveTempPopupOpen = useSelector(
    (state) => state.template.isSaveTempPopupOpen
  );
  const isScheduleMailPopupOpen = useSelector(
    (state) => state.template.isScheduleMailPopupOpen
  );
  const inputReduxValues = useSelector(
    (state) => state.template.inputReduxValues
  );
  const isDownloadPopupOpen = useSelector(
    (state) => state.template.isDownloadPopupOpen
  );
  const caseTemplate = useSelector((state) => state.createLetter.caseTemplate);
  const markReduxFiles = useSelector((state) => state.template.markReduxFiles);
  const [imageUrl, setImageUrl] = useState(null);
  const contentRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDiscard = () => {
    handleScrollToTop();
    dispatch(setIsCanTempPopupOpen(true));
  };
  const handleClose = () => {
    dispatch(setIsCanTempPopupOpen(false));
  };
  const handleSaveClose = () => {
    dispatch(setIsSaveTempPopupOpen(false));
  };
  const handleScheduleClose = () => {
    dispatch(setIsScheduleMailPopupOpen(false));
  };
  const handleDownloadPopupClose = () => {
    dispatch(setIsDownloadPopupOpen(false));
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const fields = [
    {
      label: "Mark",
      value: inputReduxValues?.mark ? inputReduxValues.mark : "{Matter.Title}",
    },
    {
      label: "IR No.",
      value: inputReduxValues?.irNo
        ? inputReduxValues.irNo
        : "{Matter.PatentNum}",
    },
    {
      label: "Registration Date",
      value: inputReduxValues?.registrationDate
        ? inputReduxValues.registrationDate
        : "{Matter.IssueDate(MMMM d, YYYY)}",
    },
    {
      label: "Our File No.",
      value: inputReduxValues?.fileNo
        ? inputReduxValues.fileNo
        : "{Matter.AttorneyRef}",
    },
  ];
  const [attachFiles, setAttachFiles] = useState([]);
  const [attachSignFile, setAttachSignFile] = useState([]);
  const [attachSignError, setAttachSignError] = useState("");
  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validFiles = [];

    filesArray.forEach((file) => {
      validFiles.push(file);
    });
    setAttachFiles(validFiles);
  };

  let invalidSignFiles = [];

  const handleSignatureFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validSignFiles = [];
    filesArray.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "rtf" || fileExtension === "txt") {
        validSignFiles.push(file);
      } else {
        invalidSignFiles.push(file);
      }
    });
    if (invalidSignFiles.length > 0) {
      document.getElementById("template-attach-signature-file").value = "";
      alert("Please upload a .rtf or .txt file.");
      setAttachSignError("Please upload a .rtf or .txt file.");
      return;
    }
    setAttachSignFile(validSignFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = attachFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("template-attach-file").value = "";
    setAttachFiles(updatedFiles);
  };

  useEffect(() => {
    if (markReduxFiles.length > 0) {
      const url = URL.createObjectURL(markReduxFiles[0]);
      setImageUrl(url);
      return () => URL.revokeObjectURL(url);
    }
    if (markReduxFiles.length === 0) {
      setImageUrl(null);
    }
  }, [markReduxFiles]);

  return (
    <>
      <div
        className="d-flex flex-column email-container px-3 py-3 section-scroll"
        ref={contentRef}
      >
        <RlTooltip contentRef={contentRef} buttonRef={buttonRef} />
        <SgpTemplateHeader
          inputReduxValues={inputReduxValues}
          handleCopyTemplate={handleCopyTemplate}
          title={`Statement of Grant of Protection;`}
          contentRef={contentRef}
          imageUrl={imageUrl}
        />
        <br></br>
        <span className="d-flex flex-row re-section align-items-stretch">
          <span className="capital-re">RE:</span>
          <span className="d-flex flex-column cert-regis-section ps-1">
            <span className="cert-regis-title">
              STATEMENT OF GRANT OF PROTECTION
            </span>
            {fields.map((field, index) => (
              <span key={index} className="d-flex flex-row mt-1">
                <br></br>
                <span className="field-title pt-1 ps-1">{field.label}:</span>
                {field.label === "Mark" && markReduxFiles.length > 0 ? (
                  <img
                    src={imageUrl}
                    alt={field.label}
                    style={{ width: "80px", height: "80px" }}
                  />
                ) : (
                  <span className="mx-1 field-span ps-3 pt-1 highlight-field-class">
                    {field.value}
                  </span>
                )}
                <br></br>
              </span>
            ))}
          </span>
        </span>
        <br></br>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">Dear</span>
          <span
            className="email-content-span contact-name-span ms-1 highlight-field-class"
            style={{ marginLeft: "5px" }}
          >
            {inputReduxValues?.clientName
              ? inputReduxValues?.clientName
              : "{Matter.ContactName}"}
          </span>
          <span>,</span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            Please see the attached Certificate of Registration from the WIPO
            for your
            <span
              className="email-content-span ms-1 ps-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {`International Registration No. ${
                inputReduxValues?.irNo
                  ? inputReduxValues?.irNo
                  : "{Matter.PatentNum}"
              }`}
            </span>
            <span>,</span>
            <span className="ms-1">
              issued by the Intellectual Property Office of
            </span>
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.country
                ? inputReduxValues?.country
                : "{Matter.Country}"}
            </span>
          </span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            The Intellectual Property Office of
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.country
                ? inputReduxValues?.country
                : "{Matter.Country}"}
            </span>
            <span className="ms-1">
              has completed all its procedures and the decision is as follows:
            </span>
          </span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            <span className="email-content-span ms-1 pe-1 highlight-field-class">
              {inputReduxValues?.type
                ? inputReduxValues?.type
                : "-Partial/Total Protection"}
            </span>
            <span className="ms-1">
              is granted to Good(s) and Service(s) of
            </span>
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.className
                ? inputReduxValues?.className
                : "{Matter.Class}"}
            </span>
          </span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <div className="content-text text-only-size">
            The trademark registration will remain in force for 10 years until
            <span
              className="email-content-span date-num-span-2 pt-1 px-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {"{Matter.IssueDate(+120m MMMM d, yyyy)}"}
            </span>
            , at which time it may be renewed for additional 10 years. We have
            entered this date in our docket system and will bring this matter to
            your attention at the appropriate time. However, we suggest that you
            make note of this date as well.
          </div>
        </div>
        <div className="d-flex flex-column email-content-section align-items-start last-text-section">
          <div className="content-text text-only-size">
            We will keep you informed of further developments. If you have any
            questions or concerns, please contact us at your convenience.
          </div>
          {attachFiles.length > 0 && (
            <AttachFileList
              attachFiles={attachFiles}
              FileCloseIcon={FileCloseIcon}
              handleRemoveFile={handleRemoveFile}
            />
          )}
          {attachSignFile.length > 0 && (
            <SignFileList
              handleRemoveSignFile={handleRemoveSignFile}
              FileCloseIcon={FileCloseIcon}
              attachSignFile={attachSignFile}
              setAttachSignFile={setAttachSignFile}
            />
          )}
        </div>
        <RlTemplateButtons
          handleDiscard={handleDiscard}
          BackImage={BackImage}
          UploadImage={UploadImage}
          handleSignatureFileChange={handleSignatureFileChange}
          handleFileChange={handleFileChange}
          handleDownloadPdf={handleDownloadPdf}
          SendImage={SendImage}
          SaveImage={SaveImage}
          contentRef={contentRef}
        />
      </div>
      <RlTemplatePopups
        isCanTempPopupOpen={isCanTempPopupOpen}
        isSaveTempPopupOpen={isSaveTempPopupOpen}
        isScheduleMailPopupOpen={isScheduleMailPopupOpen}
        isDownloadPopupOpen={isDownloadPopupOpen}
        handleClose={handleClose}
        handleSaveClose={handleSaveClose}
        handleScheduleClose={handleScheduleClose}
        handleDownloadPopupClose={handleDownloadPopupClose}
        contentRef={contentRef}
        caseTemplate={caseTemplate}
      />
    </>
  );
};

export default EmailSection;
