import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const referencesApi = createApi({
  reducerPath: "referencesApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    createReference: builder.mutation({
      query: (data) => ({
        url: 'references',
        method: 'POST',
        body: data,
      }),
    }),
    getSavedReferences: builder.query({
      query: () => `references`,
    }),
    getSavedRefByUser: builder.query({
      query: ({ userId, num }) => `references/saved-refs/${userId}/${num}`,
      keepUnusedDataFor: 0
    }),
    deleteSavedRefByUser: builder.mutation({
      query: ({ userId, num }) => ({
        url: `references/saved-refs-delete/${userId}/${num}`,
        method: 'DELETE',
      })
    })
  }),
});

export const { useCreateReferenceMutation, useGetSavedReferencesQuery, useGetSavedRefByUserQuery, useDeleteSavedRefByUserMutation } = referencesApi;
export default referencesApi;

