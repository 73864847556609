import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import TableEdit from "../../assets/images/table-edit-icon.png";
import TableDownload from "../../assets/images/table-download-icon.png";
import DashboardShare from "../../assets/images/dashboard-share-icon.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectIdsToggle,
  setSharePopupToggle,
  setEditDashboardTable,
  setShareToEmailNumber,
} from "../../store/reducer/adminSlice";
import { useEditAdminMutation } from "../../store/action/adminApi";
import {
  setPopupNumber,
  setPopupIdsType,
  setPopupDocketNumber,
} from "../../store/reducer/searchSlice";

const Table = ({ headers, data, renderAsHtml }) => {
  const dispatch = useDispatch();
  const editDashboardTable = useSelector(
    (state) => state.admin.editDashboardTable
  );
  const [editDashboard] = useEditAdminMutation();
  const headerToFieldObject = {
    "Patent Family": "family_mem",
    "Attorney Docket No.": "docket_num",
    Type: "ids_type",
    Date: "curr_date",
    Status: "status",
  };

  const familyCount = {};

  const handleEdit = async (rowId, header, value) => {
    const convertedHeader = headerToFieldObject[header];
    if (header === "Date") {
      const editedDate = new Date(value).toISOString();
      const editedDateObject = { [convertedHeader]: editedDate };
      await editDashboard({ id: rowId, data: editedDateObject });
      return;
    }
    const editObject = { [convertedHeader]: value };
    await editDashboard({ id: rowId, data: editObject });
  };
  const handleDownload = async (uid, pubNumber) => {
    try {
      const response = await fetch(
        `api/v1/admin/download-pdf/${uid}/${pubNumber}`,
        {
          method: "GET",
          headers: {
            Accept: "application/pdf",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Generated_IDS_${uid}-${pubNumber}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  return (
    <div className="table-container dashboard-table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            const patentFamily = row["Patent Family"];
            if (patentFamily) {
              familyCount[patentFamily] = (familyCount[patentFamily] || 0) + 1;
              if (familyCount[patentFamily] > 1) {
                row["Patent Family"] = `${patentFamily} (V${
                  familyCount[patentFamily] - 1
                })`;
              }
            }

            return (
              <tr key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    contentEditable={`${
                      editDashboardTable === rowIndex &&
                      header !== "Sr.No" &&
                      header !== "Action"
                        ? "true"
                        : "false"
                    }`}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      const updatedValue = event.target.innerText;
                      handleEdit(row["Id_num"], header, updatedValue);
                    }}
                  >
                    {header === "Action" ? (
                      <div className="d-flex flex-row align-items-center justify-content-center">
                        <img
                          src={TableEdit}
                          alt="Edit"
                          title="Edit the Existing or New IDS for this Case."
                          className="action-icon click-icon"
                          onClick={() => {
                            dispatch(setPopupNumber(row["Id_num"]));
                            dispatch(setPopupIdsType(row["Type"]));
                            dispatch(
                              setPopupDocketNumber(row["Attorney Docket No."])
                            );
                            dispatch(setSelectIdsToggle(true));
                          }}
                        />
                        <img
                          src={DashboardShare}
                          alt="Share"
                          title="Share the IDS Form PDF over Email."
                          className={`ms-2 ${
                            row.Status.props.children === "COMPLETED"
                              ? "click-icon"
                              : "disable-share-download"
                          }`}
                          onClick={() => {
                            dispatch(setSharePopupToggle(true));
                            dispatch(setShareToEmailNumber(row["Id_num"]));
                          }}
                        />
                        <img
                          src={TableDownload}
                          alt="Download"
                          title="Download the IDS Form PDF."
                          className={`ms-2 ${
                            row.Status.props.children === "COMPLETED"
                              ? "click-icon"
                              : "disable-share-download"
                          }`}
                          onClick={() => {
                            const userNumber = parseInt(
                              sessionStorage.getItem("userId"),
                              10
                            );
                            handleDownload(userNumber, row["Id_num"]);
                          }}
                        />
                      </div>
                    ) : header === "Patent Family" ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: row[header] }}
                        onClick={() => {
                          if (header === "Patent Family") {
                            // dispatch(setSelectIdsToggle(true));
                          }
                        }}
                      />
                    ) : (
                      row[header]
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
