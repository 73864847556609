// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rl-tooltip-container {
  background-color: #fff;
  box-shadow: 0px 6px 20px -4px rgba(64, 87, 109, 0.3);
  border-radius: 12px;
  border: 1px solid transparent;
  height: 40px;
  width: auto;
}
.rl-tooltip-container .font-size-container {
  border: 1px solid rgba(53, 71, 90, 0.2);
  border-radius: 8px;
  font-size: 12px;
}
.rl-tooltip-container .font-size-container .font-label {
  color: black;
}
.rl-tooltip-container .font-size-container .rl-tooltip-font-button {
  height: 15px;
  border: 0;
  background-color: #fff;
}
.rl-tooltip-container .font-family-container {
  border: 1px solid rgba(53, 71, 90, 0.2);
  border-radius: 8px;
  font-size: 12px;
}
.rl-tooltip-container .font-family-container .rl-tooltip-font-family-button {
  height: 15px;
  border: 0;
  background-color: #fff;
}
.rl-tooltip-container .font-family-container .font-family-label {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/RL/common/rl-tooltip.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,oDAAA;EACA,mBAAA;EACA,6BAAA;EACA,YAAA;EACA,WAAA;AACF;AAAE;EACE,uCAAA;EACA,kBAAA;EACA,eAAA;AAEJ;AADI;EACE,YAAA;AAGN;AADI;EACE,YAAA;EACA,SAAA;EACA,sBAAA;AAGN;AAAE;EACE,uCAAA;EACA,kBAAA;EACA,eAAA;AAEJ;AADI;EACE,YAAA;EACA,SAAA;EACA,sBAAA;AAGN;AADI;EACE,YAAA;AAGN","sourcesContent":[".rl-tooltip-container {\n  background-color: #fff;\n  box-shadow: 0px 6px 20px -4px rgba(64, 87, 109, 0.3);\n  border-radius: 12px;\n  border: 1px solid transparent;\n  height: 40px;\n  width: auto;\n  .font-size-container {\n    border: 1px solid rgba(53, 71, 90, 0.2);\n    border-radius: 8px;\n    font-size: 12px;\n    .font-label {\n      color: black;\n    }\n    .rl-tooltip-font-button {\n      height: 15px;\n      border: 0;\n      background-color: #fff;\n    }\n  }\n  .font-family-container {\n    border: 1px solid rgba(53, 71, 90, 0.2);\n    border-radius: 8px;\n    font-size: 12px;\n    .rl-tooltip-font-family-button {\n      height: 15px;\n      border: 0;\n      background-color: #fff;\n    }\n    .font-family-label {\n      color: black;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
