import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./nafa.scss";
import NafaParamSection from "../../../components/RL/nafa-sections/nafa-param-section";
import NafaEmailSection from "../../../components/RL/nafa-sections/nafa-email-section";

const NAFA = () => {
  return (
    <div className="nafa-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">New Application Filing Agent</div>
        <div className="d-flex flex-row">
          <NafaParamSection />
          <NafaEmailSection />
        </div>
      </div>
    </div>
  );
};

export default NAFA;
