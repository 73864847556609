import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  setSelectedNplData,
  setCanAddRow,
} from "../../store/reducer/searchSlice";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";

const Table = ({
  headers,
  formattedData,
  renderAsHtml,
  activeTab,
  handleUserRowData,
  handleUpdateRowData,
}) => {
  const [data, setData] = useState(formattedData);
  const [selectAll, setSelectAll] = useState(false);
  const selectedNplData = useSelector((state) => state.search.selectedNplData);
  const activeReduxTab = useSelector((state) => state.search.activeReduxTab);
  const isNPLFileUploaded = useSelector(
    (state) => state.search.isNPLFileUploaded
  );
  const canEdit = useSelector((state) => state.search.canEdit);
  const canAddRow = useSelector((state) => state.search.canAddRow);
  const dispatch = useDispatch();
  useEffect(() => {
    setData(formattedData);
  }, [formattedData]);

  const setCursorPosition = (rowIndex, header) => {
    if (canEdit) {
      var element = document.getElementById(
        `editable_span_${rowIndex}_${header}`
      );
      if (element.childNodes.length === 0) {
        const newInput = document.createElement("input");
        newInput.className = "editable-input";
        newInput.textContent = "";
        element.appendChild(newInput);
        element.focus();
      }
    }
  };
  const handleCellChange = (rowIndex, header, event) => {
    const newValue = event.target.textContent;
    const updatedData =
      selectedNplData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, [header]: newValue } : row
          )
        : selectedNplData.map((row, index) =>
            index === rowIndex ? { ...row, [header]: newValue } : row
          );

    setData(updatedData);
    handleUpdateRowData(updatedData);
    handleUserRowData([]);
    if (selectedNplData.length !== 0) {
      dispatch(setSelectedNplData(updatedData));
    }
    return;
  };
  const addBlankRow = () => {
    const newRow = headers.reduce(
      (acc, header) => {
        acc[header] = "";
        return acc;
      },
      { Selected: false }
    );

    setData((prevData) => [...prevData, newRow]);
    handleUserRowData((prevData) => [...prevData, newRow]);
    if (selectedNplData.length !== 0) {
      newRow["Sr.No"] = `${selectedNplData.length + 1}`;
      dispatch(setSelectedNplData([...selectedNplData, newRow]));
    }
    dispatch(setCanAddRow(""));
  };
  useEffect(() => {
    if (canAddRow === "add-new-npl") {
      addBlankRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddRow]);
  const onSelectAll = (selectAll) => {
    const updatedData =
      selectedNplData.length === 0
        ? data.map((row) => ({
            ...row,
            Selected: selectAll,
          }))
        : selectedNplData.map((row) => ({
            ...row,
            Selected: selectAll,
          }));
    setData(updatedData);
    dispatch(setSelectedNplData(updatedData));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    onSelectAll(!selectAll);
  };

  const handleRowSelect = (rowIndex) => {
    const updatedData =
      selectedNplData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          )
        : selectedNplData.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          );
    const allSelected = updatedData.every((row) => row.Selected === true);
    if (allSelected === true) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setData(updatedData);
    dispatch(setSelectedNplData(updatedData));
  };
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {activeReduxTab === "references" && (
              <th>
                <input
                  type="checkbox"
                  className="checkbox-height"
                  checked={
                    selectedNplData.length !== 0 &&
                    selectedNplData.filter((item) => item.Selected === true)
                      .length === selectedNplData.length
                      ? true
                      : selectAll
                  }
                  onChange={handleSelectAll}
                />
              </th>
            )}
            {headers.map((header, index) => (
              <th
                className={`${activeReduxTab === "sb08" ? "sb08-td-th" : ""}`}
                key={index}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedNplData.length === 0 &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {activeReduxTab === "references" && (
                  <td>
                    <input
                      type="checkbox"
                      checked={row.Selected}
                      className="checkbox-height"
                      onChange={() => handleRowSelect(rowIndex)}
                    />
                  </td>
                )}
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    style={header === "References" ? { textAlign: "left" } : {}}
                    spellCheck="false"
                    id={`editable_span_npl_${rowIndex}_${header}`}
                    contentEditable={canEdit}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
          {selectedNplData.length !== 0 &&
            activeReduxTab === "references" &&
            selectedNplData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {activeReduxTab === "references" && (
                  <td>
                    <input
                      type="checkbox"
                      checked={row.Selected}
                      className="checkbox-height"
                      onChange={() => handleRowSelect(rowIndex)}
                    />
                  </td>
                )}
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    style={header === "References" ? { textAlign: "left" } : {}}
                    spellCheck="false"
                    id={`editable_span_npl_${rowIndex}_${header}`}
                    contentEditable={canEdit}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
          {selectedNplData.length !== 0 &&
            activeReduxTab === "sb08" &&
            selectedNplData
              .filter((item) => item.Selected === true)
              .map((item, index) => ({
                ...item,
                "Sr.No": index + 1,
              }))
              .map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      dangerouslySetInnerHTML={
                        renderAsHtml && header === "Link"
                          ? { __html: row[header] }
                          : undefined
                      }
                      style={
                        header === "References" ? { textAlign: "left" } : {}
                      }
                      spellCheck="false"
                      id={`editable_span_npl_${rowIndex}_${header}`}
                      contentEditable={canEdit}
                      onFocus={() => setCursorPosition(rowIndex, header)}
                      suppressContentEditableWarning={true}
                      onBlur={(event) => {
                        if (canEdit) handleCellChange(rowIndex, header, event);
                      }}
                    >
                      {!renderAsHtml || header !== "Link" ? row[header] : null}
                    </td>
                  ))}
                </tr>
              ))}
          {selectedNplData.length !== 0 &&
            activeReduxTab === "sb08" &&
            selectedNplData.filter((item) => item.Selected === false).length ===
              data.length &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    style={header === "References" ? { textAlign: "left" } : {}}
                    spellCheck="false"
                    id={`editable_span_npl_${rowIndex}_${header}`}
                    contentEditable={canEdit}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
