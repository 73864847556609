import { React, useState } from "react";
import "./select-ids.scss";
import Button from "../common/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setPopupNewIdsNumber,
  setPopupOpenClick,
} from "../../store/reducer/searchSlice";
import { setSelectIdsToggle } from "../../store/reducer/adminSlice";
import {
  setSearchParameters,
  setDocketNumber,
} from "../../store/reducer/searchSlice";
import { useDeleteSavedFamilyByUserMutation } from "../../store/action/familyBiblioApi";
import { useDeleteSavedRefByUserMutation } from "../../store/action/referencesApi";
import { useDeleteSavedInitialRefByUserMutation } from "../../store/action/initialReferencesApi";
import { useDeleteSavedTopContentByUserMutation } from "../../store/action/topContentApi";
const Popup = ({ onClose }) => {
  const [selectedValue, setSelectedValue] = useState("Existing IDS");
  const navigate = useNavigate();
  const [deleteSavedFamilyByUser] = useDeleteSavedFamilyByUserMutation();
  const [deleteSavedRefByUser] = useDeleteSavedRefByUserMutation();
  const [deleteSavedInitialRefByUser] =
    useDeleteSavedInitialRefByUserMutation();
  const [deleteSavedTopContentByUser] =
    useDeleteSavedTopContentByUserMutation();
  const popupNumber = useSelector((state) => state.search.popupNumber);
  const popupDocketNumber = useSelector(
    (state) => state.search.popupDocketNumber
  );
  const popupIdsType = useSelector((state) => state.search.popupIdsType);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div className="popup-overlay">
      <div className="select-ids-popup">
        <div className="message">
          <span>Select IDS</span>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div className="d-flex submessage py-4">
          <select onChange={handleChange}>
            <option value="Existing IDS"> Previously Filed IDS</option>
            <option value="New IDS">Prepare New IDS</option>
          </select>
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center py-4">
          <Button
            className={"select-ids-popup-btn mx-2"}
            children="Open"
            onClick={async () => {
              dispatch(setPopupOpenClick(true));
              if (selectedValue === "New IDS") {
                const userNumber = sessionStorage.getItem("userId");
                await deleteSavedInitialRefByUser({
                  userId: userNumber,
                  num: popupNumber,
                }).unwrap();
                await deleteSavedFamilyByUser({
                  userId: userNumber,
                  num: popupNumber,
                }).unwrap();
                await deleteSavedRefByUser({
                  userId: userNumber,
                  num: popupNumber,
                }).unwrap();
                await deleteSavedTopContentByUser({
                  userId: userNumber,
                  num: popupNumber,
                }).unwrap();
                dispatch(setPopupNewIdsNumber(popupNumber));
                if (popupIdsType === "Initial IDS") {
                  dispatch(setDocketNumber(popupDocketNumber));
                }
                dispatch(setSelectIdsToggle(false));
                dispatch(
                  setSearchParameters({
                    type: "publication",
                    number: popupNumber,
                    radioOption:
                      popupIdsType === "Initial IDS" ? "option1" : "option2",
                  })
                );
                navigate("/ids", {
                  state: {
                    type: "publication",
                    number: popupNumber,
                    radioOption:
                      popupIdsType === "Initial IDS" ? "option1" : "option2",
                  },
                });
              } else {
                dispatch(setPopupNewIdsNumber(popupNumber));
                if (popupIdsType === "Initial IDS") {
                  dispatch(setDocketNumber(popupDocketNumber));
                }
                dispatch(setSelectIdsToggle(false));
                dispatch(
                  setSearchParameters({
                    type: "publication",
                    number: popupNumber,
                    radioOption:
                      popupIdsType === "Initial IDS" ? "option1" : "option2",
                  })
                );
                navigate("/ids", {
                  state: {
                    type: "publication",
                    number: popupNumber,
                    radioOption:
                      popupIdsType === "Initial IDS" ? "option1" : "option2",
                  },
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
