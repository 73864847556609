import React, { useState, useRef, useEffect } from "react";
import Button from "../../common/button";
import Input from "../../common/input";
import UploadImage from "../../../assets/images/create-letter-manual-upload.png";
import BackImage from "../../../assets/images/template-back.png";
import SendImage from "../../../assets/images/table-download-icon.png";
import ScheduleImage from "../../../assets/images/template-schedule.png";
import SaveImage from "../../../assets/images/template-save.png";
import FileCloseIcon from "../../../assets/images/create-letter-file-close.png";
import {
  setIsCanTempPopupOpen,
  setIsSaveTempPopupOpen,
  setIsScheduleMailPopupOpen,
} from "../../../store/reducer/templateSlice";
import { useDispatch, useSelector } from "react-redux";
import CancelTemplatePopup from "../popup/cancel-template-popup";
import SaveTemplatePopup from "../popup/save-template-popup";
import ScheduleMailPopup from "../popup/schedule-mail-popup";
import { handleRemoveSignFile } from "../common/signature-file-functions";
import SignFileList from "../common/signature-file-list";
import RlTemplateButtons from "../common/rl-template-buttons";
import AttachFileList from "../common/attach-file-list";
import { handleDownloadPdf } from "../common/download-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { handleCopyTemplate } from "../common/copy-template";
import NafTemplateHeader from "./naf-template-header";
import { setIsDownloadPopupOpen } from "../../../store/reducer/templateSlice";
import RlTemplatePopups from "../common/rl-template-popups";
import RlTooltip from "../common/rl-tooltip";

const EmailSection = () => {
  const dispatch = useDispatch();
  const isCanTempPopupOpen = useSelector(
    (state) => state.template.isCanTempPopupOpen
  );
  const isSaveTempPopupOpen = useSelector(
    (state) => state.template.isSaveTempPopupOpen
  );
  const isScheduleMailPopupOpen = useSelector(
    (state) => state.template.isScheduleMailPopupOpen
  );
  const inputReduxValues = useSelector(
    (state) => state.template.inputReduxValues
  );
  const isDownloadPopupOpen = useSelector(
    (state) => state.template.isDownloadPopupOpen
  );
  const caseTemplate = useSelector((state) => state.createLetter.caseTemplate);
  const markReduxFiles = useSelector((state) => state.template.markReduxFiles);
  const [imageUrl, setImageUrl] = useState(null);
  const contentRef = useRef(null);
  const buttonRef = useRef(null);
  const handleDiscard = () => {
    handleScrollToTop();
    dispatch(setIsCanTempPopupOpen(true));
  };
  const handleClose = () => {
    dispatch(setIsCanTempPopupOpen(false));
  };
  const handleSaveClose = () => {
    dispatch(setIsSaveTempPopupOpen(false));
  };
  const handleScheduleClose = () => {
    dispatch(setIsScheduleMailPopupOpen(false));
  };
  const handleDownloadPopupClose = () => {
    dispatch(setIsDownloadPopupOpen(false));
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const fields = [
    {
      label: "Mark",
      value: inputReduxValues?.mark ? inputReduxValues.mark : "{Matter.Title}",
    },
    {
      label: "Serial No.",
      value: inputReduxValues?.applicationNum
        ? inputReduxValues.applicationNum
        : "{Matter.ApplicationNum}",
    },
    {
      label: "Our File No.",
      value: inputReduxValues?.fileNo
        ? inputReduxValues.fileNo
        : "{Matter.AttorneyRef}",
    },
  ];
  const filingParticularFields = [
    {
      label: "Mark",
      value: inputReduxValues?.mark ? inputReduxValues.mark : "{Matter.Title}",
    },
    {
      label: "Applicant",
      value: inputReduxValues?.applicant
        ? inputReduxValues.applicant
        : "{Matter.Applicant<n>}",
    },
    {
      label: "Application Number",
      value: inputReduxValues?.applicationNum
        ? inputReduxValues.applicationNum
        : "{Matter.ApplicationNum}",
    },
    {
      label: "Class(es)",
      value: inputReduxValues?.className
        ? inputReduxValues.className
        : "{Matter.Class}",
    },
    {
      label: "Application Date",
      value: inputReduxValues?.applicationDate
        ? inputReduxValues.applicationDate
        : "{Matter.FilingDate}",
    },
    {
      label: "Priority Filing deadline",
      value: inputReduxValues?.priorDeadline
        ? inputReduxValues.priorDeadline
        : "{Matter.FilingDate(+6m MMMM d, YYYY)}",
    },
  ];
  const [attachFiles, setAttachFiles] = useState([]);
  const [attachSignFile, setAttachSignFile] = useState([]);
  const [attachSignError, setAttachSignError] = useState("");
  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validFiles = [];

    filesArray.forEach((file) => {
      validFiles.push(file);
    });
    setAttachFiles(validFiles);
  };

  let invalidSignFiles = [];

  const handleSignatureFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validSignFiles = [];
    filesArray.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "rtf" || fileExtension === "txt") {
        validSignFiles.push(file);
      } else {
        invalidSignFiles.push(file);
      }
    });
    if (invalidSignFiles.length > 0) {
      document.getElementById("template-attach-signature-file").value = "";
      alert("Please upload a .rtf or .txt file.");
      setAttachSignError("Please upload a .rtf or .txt file.");
      return;
    }
    setAttachSignFile(validSignFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = attachFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("template-attach-file").value = "";
    setAttachFiles(updatedFiles);
  };

  useEffect(() => {
    if (markReduxFiles.length > 0) {
      const url = URL.createObjectURL(markReduxFiles[0]);
      setImageUrl(url);
      return () => URL.revokeObjectURL(url);
    }
    if (markReduxFiles.length === 0) {
      setImageUrl(null);
    }
  }, [markReduxFiles]);

  return (
    <>
      <div
        className="d-flex flex-column email-container px-3 py-3 section-scroll"
        ref={contentRef}
      >
        <RlTooltip contentRef={contentRef} buttonRef={buttonRef} />
        <NafTemplateHeader
          inputReduxValues={inputReduxValues}
          handleCopyTemplate={handleCopyTemplate}
          title={`New Application Filing;`}
          contentRef={contentRef}
          imageUrl={imageUrl}
        />
        <br></br>
        <span className="d-flex flex-row re-section align-items-stretch">
          <span className="capital-re">RE:</span>
          <span className="d-flex flex-column cert-regis-section ps-1">
            <span className="cert-regis-title">NEW APPLICATION FILING</span>
            {fields.map((field, index) => (
              <span key={index} className="d-flex flex-row mt-1">
                <br></br>
                <span className="field-title pt-1 ps-1">{field.label}:</span>
                {field.label === "Mark" && markReduxFiles.length > 0 ? (
                  <img
                    src={imageUrl}
                    alt={field.label}
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  <span className="mx-1 field-span ps-3 pt-1 highlight-field-class">
                    {field.value}
                  </span>
                )}
                <br></br>
              </span>
            ))}
          </span>
        </span>
        <br></br>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">Dear</span>
          <span
            className="email-content-span contact-name-span ms-1 highlight-field-class"
            style={{ marginLeft: "5px" }}
          >
            {inputReduxValues?.clientName
              ? inputReduxValues?.clientName
              : "{Matter.ContactName}"}
          </span>
          <span>,</span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            In accordance with your instructions, we prepared and filed a U.S.
            trademark application on behalf of
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.applicant
                ? inputReduxValues?.applicant
                : "{Matter.Applicant<n>}"}
            </span>
            <span className="ms-1">
              for the above-identified mark. A copy of the filing receipt is
              enclosed for your records.
            </span>
          </span>
        </div>
        <div className="d-flex flex-row re-section mt-1 align-items-stretch">
          <div className="d-flex flex-column cert-regis-section cert-regis-section-table">
            <div className="cert-regis-title cert-regis-title-table ms-1">
              Filing Particulars
            </div>
            <table
              className="table table-bordered mt-2"
              style={{ border: "1px solid #dee2e6" }}
            >
              <tbody style={{ border: "1px solid #dee2e6" }}>
                {filingParticularFields.map((field, index) => (
                  <tr key={index} style={{ border: "1px solid #dee2e6" }}>
                    <td
                      className="field-title field-title-table ps-1"
                      style={{ border: "1px solid #dee2e6" }}
                    >
                      {field.label}:
                    </td>
                    <td className="" style={{ border: "1px solid #dee2e6" }}>
                      {field.label === "Mark" && markReduxFiles.length > 0 ? (
                        <img
                          src={imageUrl}
                          alt={field.label}
                          style={{ width: "80px", height: "80px" }}
                        />
                      ) : (
                        <span
                          className={`mx-1 field-span ${
                            field.label === "Priority Filing deadline"
                              ? "field-span-large"
                              : ""
                          } highlight-field-class `}
                        >
                          {field.value}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            Please note, if you decide to expand your filing program to
            additional countries, I recommend filing on or before
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.priorDeadline
                ? inputReduxValues?.priorDeadline
                : "{Matter.FilingDate(+6m MMMM d, YYYY)}"}
            </span>
            <span className="ms-1">
              to claim the priority filing date (i.e., your rights in these
              countries will date back to
            </span>
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.applicationDate
                ? inputReduxValues?.applicationDate
                : "{Matter.FilingDate}"}
            </span>
            <span className="">
              . In most foreign countries, several weeks are needed to complete
              the necessary filing requirements. If you have any interest in
              filing any foreign applications, please advise.
            </span>
          </span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <span className="content-text">
            The USPTO estimates a minimum of six (6) months for an application
            to be assigned to an Examiner, so we will likely have further news
            in
            <span
              className="email-content-span ms-1 highlight-field-class"
              style={{ marginLeft: "5px" }}
            >
              {inputReduxValues?.deadlineMonth
                ? inputReduxValues?.deadlineMonth
                : "{Matter.PriorityFilingDeadlineMonth}"}
            </span>
          </span>
        </div>
        <div className="d-flex flex-row email-content-section align-items-center">
          <div className="content-text text-only-size">
            Please be advised that there are private companies which monitor
            USPTO records to send notices, invitations, or “invoices” for legal
            services, to monitor your mark, to register your mark with their
            register or journal, or similar.{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              These are scams.
            </span>{" "}
            If you have a concern regarding a notice, invitation or invoice
            concerning your mark, please feel free to forward it to us for
            advice.
          </div>
        </div>
        <div className="d-flex flex-column email-content-section align-items-start last-text-section">
          <div className="content-text text-only-size">
            We will advise you of all developments as they arise. If you have
            any questions, please contact us at your convenience.
          </div>
          {attachFiles.length > 0 && (
            <AttachFileList
              attachFiles={attachFiles}
              FileCloseIcon={FileCloseIcon}
              handleRemoveFile={handleRemoveFile}
            />
          )}
          {attachSignFile.length > 0 && (
            <SignFileList
              handleRemoveSignFile={handleRemoveSignFile}
              FileCloseIcon={FileCloseIcon}
              attachSignFile={attachSignFile}
              setAttachSignFile={setAttachSignFile}
            />
          )}
        </div>
        <RlTemplateButtons
          handleDiscard={handleDiscard}
          BackImage={BackImage}
          UploadImage={UploadImage}
          handleSignatureFileChange={handleSignatureFileChange}
          handleFileChange={handleFileChange}
          handleDownloadPdf={handleDownloadPdf}
          SendImage={SendImage}
          SaveImage={SaveImage}
          contentRef={contentRef}
        />
      </div>
      <RlTemplatePopups
        isCanTempPopupOpen={isCanTempPopupOpen}
        isSaveTempPopupOpen={isSaveTempPopupOpen}
        isScheduleMailPopupOpen={isScheduleMailPopupOpen}
        isDownloadPopupOpen={isDownloadPopupOpen}
        handleClose={handleClose}
        handleSaveClose={handleSaveClose}
        handleScheduleClose={handleScheduleClose}
        handleDownloadPopupClose={handleDownloadPopupClose}
        contentRef={contentRef}
        caseTemplate={caseTemplate}
      />
    </>
  );
};

export default EmailSection;
