import React from "react";
import CancelTemplatePopup from "../popup/cancel-template-popup";
import SaveTemplatePopup from "../popup/save-template-popup";
import ScheduleMailPopup from "../popup/schedule-mail-popup";
import DownloadOptionPopup from "../popup/download-option-popup";

const RlTemplatePopups = (props) => {
  const {
    isCanTempPopupOpen,
    isSaveTempPopupOpen,
    isScheduleMailPopupOpen,
    isDownloadPopupOpen,
    handleClose,
    handleSaveClose,
    handleScheduleClose,
    handleDownloadPopupClose,
    contentRef,
    caseTemplate,
  } = props;

  return (
    <>
      {isCanTempPopupOpen === true && (
        <CancelTemplatePopup onClose={handleClose} />
      )}
      {isSaveTempPopupOpen === true && (
        <SaveTemplatePopup onClose={handleSaveClose} />
      )}
      {isScheduleMailPopupOpen === true && (
        <ScheduleMailPopup onClose={handleScheduleClose} />
      )}
      {isDownloadPopupOpen === true && (
        <DownloadOptionPopup
          onClose={handleDownloadPopupClose}
          contentRef={contentRef}
          caseTemplate={caseTemplate}
        />
      )}
    </>
  );
};

export default RlTemplatePopups;
