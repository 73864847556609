import React from "react";

const UcrForeignSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <div className="content-text text-only-size">
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Foreign Registrations and Additional Coverage for Good(s) and
            Service(s) in U.S.
          </span>
          <br></br>
          This registration is only valid in the United States and a separate
          application must be filed for each country in which you would like to
          register the mark. If you are using your mark in a foreign country or
          expect to do so, please contact us so that we can discuss whether the
          mark should be registered in those countries.
          <br></br>
          If you begin (or intend to begin) using its trademark for good(s)
          other than those specified in the current registration, an additional
          application should be filed to protect the new good(s) or service(s).
          <br></br>
          Please let us know if you would like our assistance in establishing
          guidelines or reviewing materials for proper trademark use,
          preparation of a trademark license agreement, investigation of and
          response to possible infringing trademark users, filing foreign
          trademark applications or filing an additional application to protect
          the new good(s) or service(s).
          <br></br>
          The enclosed original Certificate of Registration is important
          evidence of your claim of priority and trademark ownership and should
          be carefully preserved.
          <br></br>
          Please let us know if we can be of any further assistance to you.
        </div>
      </div>
    </>
  );
};

export default UcrForeignSection;
