import jsPDF from "jspdf";
import {
  fontEncodingRegular,
  fontEncodingBold,
  fontEncodingSemiBold,
} from "../common/encoded-font";
import { calibriBold, calibriRegular } from "./encoded-calibri-font";

export const handleDownloadPdf = (contentRef, caseTemplate) => {
  const element = contentRef.current;

  const highlightedElements = element.querySelectorAll(
    ".highlight-field-class"
  );

  element.classList.remove("section-scroll");

  const tableElements = element.querySelectorAll(".table");

  highlightedElements.forEach((el) => {
    el.classList.remove("highlight-field-class");
  });

  if (tableElements) {
    tableElements.forEach((el) => {
      el.classList.remove("table");
      el.classList.remove("table-bordered");
    });
  }

  const doc = new jsPDF({
    unit: "mm",
    format: "a0",
    orientation: "landscape",
  });

  doc.addFileToVFS("Poppins-Regular.ttf", fontEncodingRegular);
  doc.addFileToVFS("Poppins-Bold.ttf", fontEncodingBold);
  doc.addFileToVFS("Poppins-SemiBold.ttf", fontEncodingSemiBold);
  doc.addFileToVFS("Calibri-Regular.ttf", calibriRegular);
  doc.addFileToVFS("Calibri-Bold.ttf", calibriBold);

  doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
  doc.addFont("Poppins-Bold.ttf", "Poppins", "bold");
  doc.addFont("Poppins-SemiBold.ttf", "Poppins", "semibold");
  doc.addFont("Calibri-Regular.ttf", "Calibri", "normal");
  doc.addFont("Calibri-Bold.ttf", "Calibri", "bold");
  doc.setFont("Calibri");
  doc.setFontSize(11);

  const elements = element.querySelectorAll("*");

  elements.forEach((el) => {
    el.style.fontFamily = "Calibri";
  });

  const options = {
    margin: caseTemplate === "Renewal due_Service Mark Registration" ? 25 : 10,
    callback: (doc) => {
      doc.save("Template.pdf");
    },
    html2canvas: {
      ignoreElements: (element) => {
        return (
          element.classList.contains("exclude-from-pdf") ||
          element.classList.contains("copy-icon-container") ||
          element.classList.contains("exclude-from-pdf-files")
        );
      },
    },
    width: 1280,
    windowWidth: 1280,
  };

  doc.html(element, options);

  setTimeout(() => {
    highlightedElements.forEach((el) => {
      el.classList.add("highlight-field-class");
    });
    element.classList.add("section-scroll");
    elements.forEach((el) => {
      el.style.fontFamily = "";
    });
    if (tableElements) {
      tableElements.forEach((el) => {
        el.classList.add("table");
        el.classList.add("table-bordered");
      });
    }
  }, 2000);
};
