const uploadToV1Server = async (number) => {
    try {
        const userNumber = parseInt(sessionStorage.getItem("userId"), 10);
        const response = await fetch(`/api/v2/download/${userNumber}/${number}`, {
            method: "GET",
            headers: {
                Accept: "application/pdf", // Accept PDF response
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const pdfBlob = await response.blob();
        const formData = new FormData();
        formData.append(
            "file",
            pdfBlob,
            `Generated_IDS_${userNumber}-${number}.pdf`
        ); // Append the blob
        const uploadResponse = await fetch("/api/v1/auth/upload-pdf", {
            method: "POST",
            body: formData,
        });
        if (!uploadResponse.ok) {
            throw new Error("Network response was not ok");
        }
        const result = await uploadResponse.json();
        console.log("Success:", result);
    } catch (error) {
        console.error("Error:", error);
    }
};
export default uploadToV1Server;