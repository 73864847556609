import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/header/Header";
import Login from "./pages/Login/login";
import Searchpage from "./pages/Search/search";
import IdsForm from "./pages/IDSForm/idsform";
import PatentView from "./pages/PatentView/patent-view";
import Dashboard from "./pages/Dashboard/dashboard";
import PrivateRoutes from "./PrivateRoutes";

const App = () => {
  const isAuthenticate = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken") ? true : false;
  return (
    <Router>
      <div className="app">
        <Header isAuthenticate={isAuthenticate} userName="John Doe" />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/search" element={<Searchpage />} />
            <Route path="/idsform" element={<IdsForm />} />
            <Route path="/history" element={<></>} />
            <Route path="/ids" element={<PatentView />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path="*" element={<Navigate to={isAuthenticate ? "/search" : "/login"} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
