import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const topContentApi = createApi({
    reducerPath: "topContentApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        createTopContent: builder.mutation({
            query: (data) => ({
                url: 'top-content',
                method: 'POST',
                body: data,
            }),
        }),
        editTopContent: builder.mutation({
            query: ({ id, data, userId }) => ({
                url: `top-content/edit/${userId}/${id}`,
                method: 'PATCH',
                body: data,
            }),
        }),
        getSavedTopContentDataByUser: builder.query({
            query: ({ userId, id }) => `top-content/saved-data/${userId}/${id}`,
            keepUnusedDataFor: 0
        }),
        deleteSavedTopContentByUser: builder.mutation({
            query: ({ userId, num }) => ({
                url: `top-content/saved-top-content-delete/${userId}/${num}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { useCreateTopContentMutation, useEditTopContentMutation, useGetSavedTopContentDataByUserQuery, useDeleteSavedTopContentByUserMutation } = topContentApi;
export default topContentApi;
