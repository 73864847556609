import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./save-template-popup.scss";
import CloseIcon from "../../../assets/images/create-letter-close.png";
import CancelPopupIcon from "../../../assets/images/cancel-template-popup-icon.png";
import Button from "../../common/button";
import {
  setIsCanTempPopupOpen,
  revertTemplate,
} from "../../../store/reducer/templateSlice";
import {
  setLetterRadioOption,
  setSelectOptions,
} from "../../../store/reducer/createLetterSlice";
const SaveTemplatePopup = ({ onClose }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIsCanTempPopupOpen(false));
    dispatch(setLetterRadioOption("manual"));
    dispatch(setSelectOptions(false));
    dispatch(revertTemplate());
    navigate("/rl-dashboard");
  };

  return (
    <div className="save-template-popup-overlay">
      <div className="save-template-popup d-flex flex-column align-items-center justify-content-center">
        <div className="message">
          <span>Save Template</span>
        </div>
        <div className="d-flex submessage py-2 flex-column align-items-center">
          <img src={CancelPopupIcon} className="cancel-icon" />
          <div className="save-temp-text py-3">
            Do you want to save the template?
          </div>
          <div className="d-flex flex-row save-temp-btn-container mt-3 justify-content-center py-2">
            <Button
              className={`save-temp-btn-close-style mx-2`}
              onClick={handleClick}
            >
              Yes
            </Button>
            <Button
              className={`save-temp-btn-cancel-style mx-2`}
              onClick={handleClick}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveTemplatePopup;
