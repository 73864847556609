import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./cir.scss";
import CirParamSection from "../../../components/RL/cir-sections/cir-param-section";
import CirEmailSection from "../../../components/RL/cir-sections/cir-email-section";

const CIR = () => {
  return (
    <div className="cir-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">
          Certificate of International Registration
        </div>
        <div className="d-flex flex-row">
          <CirParamSection />
          <CirEmailSection />
        </div>
      </div>
    </div>
  );
};

export default CIR;
