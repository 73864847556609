import { createSlice, createAction } from "@reduxjs/toolkit";

export const revertTemplate = createAction("REVERT_TEMPLATE");

const initialState = {
    isParamEdit: false,
    inputReduxValues: {},
    markReduxFiles: [],
    templateFiles: [],
    templateSignFiles: [],
    isCanTempPopupOpen: false,
    isSaveTempPopupOpen: false,
    isScheduleMailPopupOpen: false,
    isDownloadPopupOpen: false,
    scheduledDate: ""
}

const templateSlice = createSlice({
    name: "template",
    initialState,
    extraReducers: (builder) => builder.addCase(revertTemplate, () => initialState),
    reducers: {
        setIsParamEdit: (state, action) => {
            state.isParamEdit = action.payload
        },
        setInputReduxValues: (state, action) => {
            state.inputReduxValues = action.payload
        },
        setIsCanTempPopupOpen: (state, action) => {
            state.isCanTempPopupOpen = action.payload
        },
        setIsSaveTempPopupOpen: (state, action) => {
            state.isSaveTempPopupOpen = action.payload
        },
        setIsScheduleMailPopupOpen: (state, action) => {
            state.isScheduleMailPopupOpen = action.payload
        },
        setIsDownloadPopupOpen: (state, action) => {
            state.isDownloadPopupOpen = action.payload
        },
        setScheduledDate: (state, action) => {
            state.scheduledDate = action.payload
        },
        setMarkReduxFiles: (state, action) => {
            state.markReduxFiles = action.payload
        },
        setTemplateFiles: (state, action) => {
            state.templateFiles = action.payload
        },
        setTemplateSignFiles: (state, action) => {
            state.templateSignFiles = action.payload
        }
    }
})

export const {
    setIsParamEdit,
    setInputReduxValues,
    setIsCanTempPopupOpen,
    setIsSaveTempPopupOpen,
    setIsScheduleMailPopupOpen,
    setIsDownloadPopupOpen,
    setScheduledDate,
    setMarkReduxFiles,
    setTemplateFiles,
    setTemplateSignFiles
} = templateSlice.actions;

export default templateSlice.reducer