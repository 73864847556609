import React from "react";

function UcrMaintenanceSection(props) {
  const { inputReduxValues } = props;

  const get5YearDate = (registrationDate) => {
    const date = new Date(registrationDate);
    date.setFullYear(date.getFullYear() + 5);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const get6YearDate = (registrationDate) => {
    const date = new Date(registrationDate);
    date.setFullYear(date.getFullYear() + 6);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const get10YearDate = (registrationDate) => {
    const date = new Date(registrationDate);
    date.setFullYear(date.getFullYear() + 10);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <span className="content-text">
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Maintenance
          </span>
          <br></br>
          In order to maintain this registration, you must file a renewal
          application and an affidavit of use every ten years from the date of
          registration (or sooner). Accordingly, the last day to file to renew
          this registration is:
          <br></br>
          <span className="email-content-span date-num-span-2 highlight-field-class">
            {inputReduxValues?.registrationDate
              ? get10YearDate(inputReduxValues.registrationDate)
              : "{Matter.IssueDate(+120m MMMM d, yyyy)}"}
          </span>
          <br></br>
          Further, you must also file an Affidavit of Use within the sixth year
          after registration. Accordingly, you may file the required Affidavit
          after{" "}
          <span className="email-content-span date-num-span-2 highlight-field-class">
            {inputReduxValues?.registrationDate
              ? get5YearDate(inputReduxValues.registrationDate)
              : "{Matter.IssueDate(+60m MMMM d, yyyy)}"}
          </span>{" "}
          and you must file the required Affidavit no later than:
          <br></br>
          <span className="email-content-span date-num-span-2 highlight-field-class">
            {inputReduxValues?.registrationDate
              ? get6YearDate(inputReduxValues.registrationDate)
              : "{Matter.IssueDate(+72m MMMM d, yyyy)}"}
          </span>
          <br></br>
          The affidavit must specify the nature of the trademark use with
          evidence of how the mark is then employed. We therefore suggest that
          you retain evidence of use of the mark on its products for making the
          affidavit. Dates of such evidence should also be recorded.
          <br></br>
          Failure to file both the renewal application and Affidavits prior to
          the dates set forth above will result in cancellation of this
          registration. We have docketed the mark for these dates and will
          notify you at the appropriate time. However, you should note these
          dates in your records as well, for we cannot accept responsibility for
          meeting these deadlines without acceptable instructions from you.
          <br></br>
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            To ensure our notification reaches you, notify us whenever your
            contact information changes.
          </span>
        </span>
      </div>
    </>
  );
}

export default UcrMaintenanceSection;
