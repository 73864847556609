import React from "react";

const RsrSpecimenSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <span className="content-text">
          Acceptable specimens include advertisements, promotional materials,
          brochures and the like which show not only the mark, but which also
          clearly identify the services with which the mark is being used.
        </span>
      </div>
    </>
  );
};

export default RsrSpecimenSection;
