import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../common/initial-references-table';
import { setFormattedInitialRefData } from '../../../store/reducer/searchSlice';
import { highlightText } from '../../../utils/highlight';
import { useGetSavedInitialRefByUserQuery } from '../../../store/action/initialReferencesApi';
import { formatSavedDate } from '../../../util/utils';
import { useLocation } from "react-router-dom";
const InitialReferences = ({ searchTerm, selectedColumn }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { number: pubNum } = state || {};
  const { extractedData: dataTable } = useSelector((state) => state.search);
  const number = useSelector((state) => state.search.number)
  const userNumber = sessionStorage.getItem("userId")
  const { data } = useGetSavedInitialRefByUserQuery({ userId: userNumber, num: number !== '' ? number : pubNum })
  const savedInitialRefArray = data ? [...data] : []
  const [addInitialRefRowData, setAddInitialRefRowData] = useState([])
  const [updateInitialRefRowData, setUpdateInitialRefRowData] = useState([])
  const handleUpdateInitialRefRowData = (data) => {
    setUpdateInitialRefRowData(data)
  }
  const handleAddInitialRefRowData = (data) => {
    setAddInitialRefRowData(data)
  }
  // Filter out rows with 'NON-PATENT LITERATURE DOCUMENTS' type or absent type
  const filteredDataTable = useMemo(() => {
    return (dataTable || []).filter(row => {
      const type = row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell']?.['uscom:Heading'] || '';
      return type !== 'NON-PATENT LITERATURE DOCUMENTS' && type !== '';
    });
  }, [dataTable]);

  const formatCells = (cells, headers) => {
    return headers.reduce((acc, header, index) => {
      const cell = cells[index] || {};
      const text = cell['uscom:TextInput']?.['__text'] || cell['uscom:TextInput'] || '';
      return { ...acc, [header]: text };
    }, {});
  };

  const formattedData = useMemo(() => {
    return filteredDataTable.flatMap((row, index) => {
      const tableHeader = row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell']?.['uscom:Heading'] || '';
      const tableHeaders = row?.['uscom:TableRow'][1]?.['uscom:TableHeaderCell'] || [];
      const tableDataCells = row?.['uscom:TableRow'].slice(2) || [];

      return tableDataCells.map((dataCell) => {
        const cells = dataCell['uscom:TableDataCell'] || [];
        const data = formatCells(cells, tableHeaders);

        switch (tableHeader) {
          case 'FOREIGN PATENT DOCUMENTS':
            return {
              "Publication / Application Number": cells[2]?.['uscom:TextInput'] || '',
              "Kind Code": cells[4]?.['uscom:TextInput'] || '',
              "Issue / Publication Date": cells[5]?.['uscom:TextInput'] || '',
              "Patentee": cells[6]?.['uscom:TextInput'] || '',
              "Type": tableHeader,
            };
          case 'U.S.PATENTS':
            return {
              "Publication / Application Number": cells[2] ? `US${cells[2]['uscom:TextInput'] || ''}` : '',
              "Kind Code": cells[3]?.['uscom:TextInput'] || '',
              "Issue / Publication Date": cells[4]?.['uscom:TextInput'] || '',
              "Patentee": cells[5]?.['uscom:TextInput'] || '',
              "Type": tableHeader,
            };
          default:
            return {
              "Publication / Application Number": data["Publication Number"] ? `US${data["Publication Number"]}` : '',
              "Kind Code": data["Kind Code"] || '',
              "Issue / Publication Date": data["Publication Date"] || '',
              "Patentee": data["Name of Patentee or Applicant of cited Document"] || '',
              "Type": tableHeader,
            };
        }
      });
    });
  }, [filteredDataTable]);

  useEffect(() => {
    dispatch(setFormattedInitialRefData(formattedData));
  }, [formattedData, dispatch]);

  const headers = [
    "Sr.No",
    "Publication / Application Number",
    "Kind Code",
    "Issue / Publication Date",
    "Patentee",
    "Type",
  ];
  const formattedSavedInitialRefData = savedInitialRefArray.map((member) => {
    return {
      "Publication / Application Number": member.pdf_ref_num !== "PlaceholderRefNum" ? member.pdf_ref_num : "",
      "Kind Code": member.pdf_kind_code !== "-" ? member.pdf_kind_code : "",
      "Issue / Publication Date": member.pdf_ip_date !== null ? formatSavedDate(member.pdf_ip_date) : "",
      "Patentee": member.pdf_patentee_name !== "-" ? member.pdf_patentee_name : "",
      "Type": member.pdf_type,
    };
  })

  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const defaultData = Array.from({ length: 10 }, () => defaultRow);
  const combinedData = updateInitialRefRowData.length > 0 ? [...updateInitialRefRowData] : formattedSavedInitialRefData.length > 0 ? [...formattedSavedInitialRefData, ...addInitialRefRowData] : [...formattedData, ...addInitialRefRowData]
  const highlightedData = combinedData.map((row, index) => ({
    ...row,
    "Sr.No": index + 1,
    "Publication / Application Number": highlightText(row["Publication / Application Number"], searchTerm, selectedColumn, 'Serial #'),
  }));

  return (
    <div className="initial-ref">
      <Table headers={headers} data={highlightedData.length > 0 ? highlightedData : defaultData} handleUpdateInitialRefRowData={handleUpdateInitialRefRowData} handleAddInitialRefRowData={handleAddInitialRefRowData} />
    </div>
  );
}

export default InitialReferences;