import { React, useState } from "react";
import TableEdit from "../../assets/images/table-edit-icon.png";
import TableDownload from "../../assets/images/table-download-icon.png";
import DashboardShare from "../../assets/images/dashboard-share-icon.png";
import DashboardDelete from "../../assets/images/dashboard-delete.png";
import PropTypes from "prop-types";
import "./index.scss";

const Table = ({ headers, data }) => {
  return (
    <div className="table-container dashboard-table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <td key={colIndex}>
                    {header === "Action" ? (
                      <div className="d-flex flex-row align-items-center justify-content-center">
                        <img
                          src={TableEdit}
                          alt="Edit"
                          title="Edit RL"
                          className="action-icon click-icon disable-share-download"
                        />
                        <img
                          src={DashboardShare}
                          alt="Share"
                          title="Share RL"
                          className="ms-2 click-icon disable-share-download"
                        />
                        <img
                          src={TableDownload}
                          alt="Download"
                          title="Download RL"
                          className="ms-2 click-icon disable-share-download"
                        />
                        <img
                          src={DashboardDelete}
                          alt="Delete"
                          title="Delete RL"
                          className="delete-record disable-share-download"
                        />
                      </div>
                    ) : (
                      row[header]
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
