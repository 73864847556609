import React, { useEffect, useState } from "react";
import Input from "../../common/input";
import InputIcon from "../../../assets/images/template-edit-icon.png";
import TickIcon from "../../../assets/images/tick-new-icon.png";
import CrossIcon from "../../../assets/images/cross-new-icon.png";
import MarkUpload from "../../../assets/images/mark-upload-icon.png";
import MarkEdit from "../../../assets/images/mark-edit-icon.png";
import FileCloseIcon from "../../../assets/images/create-letter-file-close.png";
import Button from "../../common/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputReduxValues,
  setMarkReduxFiles,
} from "../../../store/reducer/templateSlice";
import { useUploadRlPdfMutation } from "../../../store/action/uploadRlPdfApi";
import RlTemplateMarkFilesList from "../common/rl-template-mark-files-list";
import RLTemplateParamButtons from "../common/rl-template-param-buttons";
import RlTemplateMarkButton from "../common/rl-template-mark-button";
import { countWords } from "../../../util/utils";

const ParamSection = () => {
  const dispatch = useDispatch();
  const [markFiles, setMarkFiles] = useState([]);
  const [markError, setMarkError] = useState(
    "Can’t extract the text, Please upload the image."
  );
  const caseNumberType = useSelector(
    (state) => state.createLetter.caseNumberType
  );
  const caseNumber = useSelector((state) => state.createLetter.caseNumber);
  const letterRadioOption = useSelector(
    (state) => state.createLetter.letterRadioOption
  );
  const manualInputFiles = useSelector(
    (state) => state.createLetter.manualInputFiles
  );
  const uploadInputFiles = useSelector(
    (state) => state.createLetter.uploadInputFiles
  );
  const manualFileTypes = useSelector(
    (state) => state.createLetter.manualFileTypes
  );
  const uploadFileTypes = useSelector(
    (state) => state.createLetter.uploadFileTypes
  );

  const [uploadRlPdf, { isLoading, isError, isSuccess }] =
    useUploadRlPdfMutation();
  const handleMarkFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    const maxSize = 1048576;
    const validFiles = [];
    let errorMessage = "";

    filesArray.forEach((file) => {
      if (file.size > maxSize) {
        errorMessage = `File size should not exceed 1MB.`;
      } else if (!/\.(jpe?g|png|gif|bmp|svg|webp|tiff)$/i.test(file.name)) {
        errorMessage = `Invalid file type. Only image files are allowed.`;
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessage) {
      setMarkError(errorMessage);
    } else {
      setMarkError("");
      setMarkFiles(validFiles);
      dispatch(setMarkReduxFiles(validFiles));
    }
  };

  useEffect(() => {
    if (markError !== "") {
      setTimeout(() => {
        setMarkError("");
      }, [2000]);
    }
  }, [markError]);
  const handleRemoveMarkFile = (index) => {
    const updatedFiles = markFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("mark-attach-file").value = "";
    setMarkFiles(updatedFiles);
    dispatch(setMarkReduxFiles([]));
  };
  const fields = [
    { id: "mark", label: "Mark*", placeholder: "{Matter.Title}" },
    {
      id: "subjectMark",
      label: "Subject Mark*",
      placeholder: "{Matter.Title}",
    },
    { id: "irNo", label: "IR No.*", placeholder: "{Matter.PatentNum}" },
    {
      id: "registrationDate",
      label: "Registration Date*",
      placeholder: "{Matter.IssueDate(MMMM d, YYYY)}",
    },
    { id: "className", label: "Class*", placeholder: "{Matter.Class}" },
    {
      id: "fileNo",
      label: "Our File No*",
      placeholder: "{Matter.AttorneyRef}",
    },
    {
      id: "clientName",
      label: "Point Of Contact*",
      placeholder: "{Matter.ContactName}",
    },
    {
      id: "country",
      label: "Country*",
      placeholder: "{Matter.Country}",
    },
    {
      id: "type",
      label: "Type*",
      placeholder: "-Partial/Total Protection",
    },
  ];

  const [manualEdit, setManualEdit] = useState(false);

  const [inputValues, setInputValues] = useState(
    fields.reduce((acc, field) => {
      acc[field.id] = "";
      return acc;
    }, {})
  );

  const [editableFields, setEditableFields] = useState(
    fields.reduce((acc, field) => {
      acc[field.id] = false;
      return acc;
    }, {})
  );

  const [editableFocusFields, setEditableFocusFields] = useState({});

  const toggleEdit = (fieldId) => {
    if (fieldId === "mark" && markFiles.length !== 0) {
      alert("Please remove the image first to add text.");
      return;
    }
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: true,
    }));
    setEditableFocusFields((prevState) => ({
      ...prevState,
      [fieldId]: true,
    }));
  };

  const toggleDisableEdit = (fieldId, icon) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: false,
    }));
    setEditableFocusFields((prevState) => ({
      ...prevState,
      [fieldId]: false,
    }));
    if (icon === "tick") {
      setManualEdit(false);
    }
    if (icon === "cross") {
      setManualEdit(false);
      setInputValues((prevValues) => ({
        ...prevValues,
        [fieldId]: "",
      }));
      return;
    }
  };

  const handleInputChange = (fieldId, value) => {
    if (countWords(value) > 1000) {
      alert("Maximum Word Limit Reached.");
      return;
    }
    setManualEdit(true);
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value,
    }));
  };

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handleSave = () => {
    dispatch(setInputReduxValues(inputValues));
  };

  const handleFetch = () => {};

  useEffect(() => {
    if (manualEdit === false) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues, manualEdit]);

  useEffect(() => {
    Object.keys(editableFocusFields).forEach((fieldId) => {
      if (editableFocusFields[fieldId]) {
        var element = document.getElementById(`${fieldId}-input`);
        element.focus();
      }
    });
  }, [editableFocusFields]);

  return (
    <>
      <div className="d-flex flex-column param-container justify-content-between section-scroll">
        <div className="d-flex flex-column param-input-container justify-content-center px-3 py-3">
          <div className="param-title">Parameters</div>
          {fields.map((field) => (
            <div
              key={field.id}
              className="d-flex flex-column justify-content-center mt-2"
            >
              <div className="param-input-title">{field.label}</div>
              <div className="d-flex flex-row align-items-center">
                <Input
                  type="text"
                  id={`${field.id}-input`}
                  placeholder={`${field.placeholder}`}
                  className={`param-input-style ${
                    editableFields[field.id] === false &&
                    inputValues[field.id] !== ""
                      ? "param-input-text-style"
                      : ""
                  }`}
                  value={inputValues[field.id]}
                  onChange={(e) => handleInputChange(field.id, e.target.value)}
                  disabled={!editableFields[field.id]}
                  spellcheck="false"
                />
                {editableFields[field.id] === false && (
                  <RlTemplateMarkButton
                    field={field}
                    MarkEdit={MarkEdit}
                    MarkUpload={MarkUpload}
                    handleDragStart={handleDragStart}
                    handleMarkFileChange={handleMarkFileChange}
                    InputIcon={InputIcon}
                    toggleEdit={toggleEdit}
                  />
                )}
                {editableFields[field.id] === true && (
                  <div className="param-input-image mx-1 d-flex flex-row align-items-center">
                    <img
                      src={TickIcon}
                      className="param-image"
                      alt="tick-icon"
                      onClick={() => toggleDisableEdit(field.id, "tick")}
                      onDragStart={handleDragStart}
                    />
                    <img
                      src={CrossIcon}
                      className="param-image mx-1"
                      alt="cross-icon"
                      onClick={() => toggleDisableEdit(field.id, "cross")}
                      onDragStart={handleDragStart}
                    />
                  </div>
                )}
              </div>
              {field.id === "mark" && markFiles.length > 0 && (
                <RlTemplateMarkFilesList
                  markFiles={markFiles}
                  FileCloseIcon={FileCloseIcon}
                  handleRemoveMarkFile={handleRemoveMarkFile}
                />
              )}
              {field.id === "mark" && (
                <span
                  className="text-danger"
                  style={{ fontSize: "11px", height: "auto" }}
                >
                  {markError}
                </span>
              )}
            </div>
          ))}
        </div>
        <RLTemplateParamButtons
          isLoading={isLoading}
          handleFetch={handleFetch}
          handleSave={handleSave}
        />
      </div>
    </>
  );
};

export default ParamSection;
