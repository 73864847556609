import React from "react";

const UcrTrademarkSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <div className="content-text text-only-size">
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Trademark Watch Service
          </span>
          <br></br>
          In order to prevent registration of confusingly similar marks by
          others and to learn of possible infringing users, we recommend that
          you use a trademark and service mark watch service. This service can
          provide information on applications to register trademarks and marks
          similar to your mark with the U.S. Patent and Trademark Office.
          <br></br>
          Since the watch service allows you to learn of the applications prior
          to registration, you have an opportunity to oppose registration of any
          marks which you believe is too close to your mark. The fee charged by
          the watch service is relatively small (about $295 per year). Please
          let me know if you would like us to establish a watch service for your
          mark.
        </div>
      </div>
    </>
  );
};

export default UcrTrademarkSection;
