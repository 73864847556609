import React, { useEffect, useState } from "react";
import Input from "../../common/input";
import InputIcon from "../../../assets/images/template-edit-icon.png";
import TickIcon from "../../../assets/images/tick-new-icon.png";
import CrossIcon from "../../../assets/images/cross-new-icon.png";
import MarkUpload from "../../../assets/images/mark-upload-icon.png";
import MarkEdit from "../../../assets/images/mark-edit-icon.png";
import FileCloseIcon from "../../../assets/images/create-letter-file-close.png";
import Button from "../../common/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputReduxValues,
  setMarkReduxFiles,
} from "../../../store/reducer/templateSlice";
import { useUploadRlPdfMutation } from "../../../store/action/uploadRlPdfApi";
import RlTemplateMarkFilesList from "../common/rl-template-mark-files-list";
import RLTemplateParamButtons from "../common/rl-template-param-buttons";
import RlTemplateMarkButton from "../common/rl-template-mark-button";
import { countWords } from "../../../util/utils";

const ParamSection = () => {
  const dispatch = useDispatch();
  const [markFiles, setMarkFiles] = useState([]);
  const [markError, setMarkError] = useState("");
  const caseNumberType = useSelector(
    (state) => state.createLetter.caseNumberType
  );
  const caseNumber = useSelector((state) => state.createLetter.caseNumber);
  const letterRadioOption = useSelector(
    (state) => state.createLetter.letterRadioOption
  );
  const manualInputFiles = useSelector(
    (state) => state.createLetter.manualInputFiles
  );
  const uploadInputFiles = useSelector(
    (state) => state.createLetter.uploadInputFiles
  );
  const manualFileTypes = useSelector(
    (state) => state.createLetter.manualFileTypes
  );
  const uploadFileTypes = useSelector(
    (state) => state.createLetter.uploadFileTypes
  );
  const [uploadRlPdf, { isLoading, isError, isSuccess }] =
    useUploadRlPdfMutation();

  const handleMarkFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    const maxSize = 1048576;
    const validFiles = [];
    let errorMessage = "";

    filesArray.forEach((file) => {
      if (file.size > maxSize) {
        errorMessage = `File size should not exceed 1MB.`;
      } else if (!/\.(jpe?g|png|gif|bmp|svg|webp|tiff)$/i.test(file.name)) {
        errorMessage = `Invalid file type. Only image files are allowed.`;
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessage) {
      setMarkError(errorMessage);
    } else {
      setMarkError("");
      setMarkFiles(validFiles);
      dispatch(setMarkReduxFiles(validFiles));
    }
  };

  useEffect(() => {
    if (markError !== "") {
      setTimeout(() => {
        setMarkError("");
      }, [2000]);
    }
  }, [markError]);

  const handleRemoveMarkFile = (index) => {
    const updatedFiles = markFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    document.getElementById("mark-attach-file").value = "";
    setMarkFiles(updatedFiles);
    dispatch(setMarkReduxFiles([]));
    setInputValues((prevValues) => ({
      ...prevValues,
      mark: "",
    }));
  };
  const fields = [
    {
      id: "registrationDate",
      label: "Registration Date*",
      placeholder: "{Matter.IssueDate(MMMM d, yyyy)}",
    },
    { id: "mark", label: "Mark*", placeholder: "{Matter.Title}" },
    {
      id: "goods",
      label: "Goods*",
      placeholder: "{Matter.Goods}",
    },

    {
      id: "owner",
      label: "Owner*",
      placeholder: "{Matter.Applicant}",
    },
    {
      id: "refNo",
      label: "Our Ref*",
      placeholder: "{Matter.AttorneyRef}",
    },
    {
      id: "renewalDate",
      label: "Renewal Date*",
      placeholder: "{Matter.RenewalDate}",
    },
    {
      id: "clientName",
      label: "Point Of Contact*",
      placeholder: "{Matter.ContactName}",
    },
    {
      id: "regNo",
      label: "Registration No.*",
      placeholder: "{Matter.PatentNum}",
    },
  ];

  const [manualEdit, setManualEdit] = useState(false);

  const [inputValues, setInputValues] = useState(
    fields.reduce((acc, field) => {
      acc[field.id] = "";
      return acc;
    }, {})
  );

  const [editableFields, setEditableFields] = useState(
    fields.reduce((acc, field) => {
      acc[field.id] = false;
      return acc;
    }, {})
  );

  const [editableFocusFields, setEditableFocusFields] = useState({});

  const toggleEdit = (fieldId) => {
    if (fieldId === "mark" && markFiles.length !== 0) {
      alert("Please remove the image first to add text.");
      return;
    }
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: true,
    }));
    setEditableFocusFields((prevState) => ({
      ...prevState,
      [fieldId]: true,
    }));
  };

  const toggleDisableEdit = (fieldId, icon) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: false,
    }));
    setEditableFocusFields((prevState) => ({
      ...prevState,
      [fieldId]: false,
    }));
    if (icon === "tick") {
      setManualEdit(false);
    }
    if (icon === "cross") {
      setManualEdit(false);
      setInputValues((prevValues) => ({
        ...prevValues,
        [fieldId]: "",
      }));
      if (fieldId === "mark") {
        setMarkFiles([]);
        dispatch(setMarkReduxFiles([]));
      }
      return;
    }
  };

  const handleInputChange = (fieldId, value) => {
    if (countWords(value) > 1000) {
      alert("Maximum Word Limit Reached.");
      return;
    }
    setManualEdit(true);
    if (fieldId === "mark") {
      setMarkFiles([]);
      dispatch(setMarkReduxFiles([]));
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value,
    }));
  };

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handleSave = () => {
    dispatch(setInputReduxValues(inputValues));
  };

  const extractContentAfterEachForUntilInternational = (
    array,
    classNumbers
  ) => {
    let content = "";
    let isExtracting = false;
    let cleanedIndex = 0;
    array.forEach((str) => {
      if (str.includes("International")) {
        isExtracting = false;
        content += " , in Int. Class " + classNumbers[cleanedIndex++] + " ";
      }
      const indexFor = str.indexOf("For:");
      if (indexFor !== -1) {
        isExtracting = true;
        content += str.slice(indexFor + 4).trim();
      } else if (isExtracting) {
        content += " " + str.trim();
      }
    });

    return content.trim();
  };

  const formatPDFTextRtr = async (textContent) => {
    const regNoElement =
      textContent[textContent.indexOf("US Registration") + 2];
    const regNo = regNoElement?.match(/^(\d+)/)?.[0].trim();
    const registrationDate = regNoElement
      ?.match(/([A-Za-z]+\. \d{1,2}, \d{4})/)?.[0]
      .trim();
    const date = new Date(registrationDate);
    date.setFullYear(date.getFullYear() + 10);
    const options = { year: "numeric", month: "short", day: "numeric" };
    const renewalDate = new Intl.DateTimeFormat("en-US", options).format(date);
    const mark = textContent[textContent.indexOf("Elements:") + 1];
    const owner =
      textContent[
        textContent.findIndex((item) => item.includes("Owner Name"))
      ].split(":")?.[1];
    let classNumbers = [];

    for (let i = 0; i < textContent.length; i++) {
      if (textContent[i] === "Class(es):" && i + 1 < textContent.length) {
        const match = textContent[i + 1].match(/^(\d{3})/);
        if (match) {
          classNumbers.push(match[1]);
        }
      }
    }
    const goods = extractContentAfterEachForUntilInternational(
      textContent,
      classNumbers
    );
    const refNoElement =
      textContent[
        textContent.findIndex((item) => item.includes("Docket Number:"))
      ];
    const refNo =
      refNoElement.match(/Docket Number:([A-Za-z0-9\-]+)/)?.[1] || "";
    const regMarkIndex = textContent.indexOf(
      "LIVE/REGISTRATION/Issued and Active"
    );

    const fetchObject = {
      regNo: regNo,
      registrationDate: registrationDate,
      mark: regMarkIndex !== -1 ? `${mark}®` : mark,
      owner: owner,
      goods: goods,
      refNo: refNo,
      renewalDate: renewalDate,
    };
    setInputValues((prevValues) => ({
      ...prevValues,
      ...fetchObject,
    }));
  };

  const getRtrPdfData = async (index) => {
    const formDataRtr = new FormData();
    formDataRtr.append(
      "file",
      letterRadioOption === "manual"
        ? manualInputFiles[index]
        : uploadInputFiles[index]
    );
    let caseNum = caseNumber;
    if (caseNumberType === "Serial#") {
      caseNum = `sn${caseNumber}`;
    } else if (caseNumberType === "Registration #") {
      caseNum = `rn${caseNumber}`;
    } else {
      caseNum = `refnum${caseNumber}`;
    }
    formDataRtr.append("caseNum", caseNum);
    try {
      const responseTextRtr = await uploadRlPdf({
        data: formDataRtr,
      }).unwrap();
      await formatPDFTextRtr(responseTextRtr.textContent);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetch = async () => {
    try {
      if (letterRadioOption === "manual") {
        manualFileTypes.forEach(async (type, index) => {
          switch (type) {
            case "renewal_due_trademark_registration":
              await getRtrPdfData(index);
              break;
          }
        });
      } else {
        uploadFileTypes.forEach(async (type, index) => {
          switch (type) {
            case "renewal_due_trademark_registration":
              await getRtrPdfData(index);
              break;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manualEdit === false) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues, manualEdit]);

  useEffect(() => {
    Object.keys(editableFocusFields).forEach((fieldId) => {
      if (editableFocusFields[fieldId]) {
        var element = document.getElementById(`${fieldId}-input`);
        element.focus();
      }
    });
  }, [editableFocusFields]);

  return (
    <>
      <div className="d-flex flex-column param-container justify-content-between section-scroll">
        <div className="d-flex flex-column param-input-container justify-content-center px-3 py-3">
          <div className="param-title">Parameters</div>
          {fields.map((field) => (
            <div
              key={field.id}
              className="d-flex flex-column justify-content-center mt-2"
            >
              <div className="param-input-title">{field.label}</div>
              <div className="d-flex flex-row align-items-center">
                <Input
                  type="text"
                  title={inputValues[field.id]}
                  id={`${field.id}-input`}
                  placeholder={`${field.placeholder}`}
                  className={`param-input-style ${
                    editableFields[field.id] === false &&
                    inputValues[field.id] !== ""
                      ? "param-input-text-style"
                      : ""
                  }`}
                  value={inputValues[field.id]}
                  onChange={(e) => handleInputChange(field.id, e.target.value)}
                  disabled={!editableFields[field.id]}
                  spellcheck="false"
                />
                {editableFields[field.id] === false && (
                  <RlTemplateMarkButton
                    field={field}
                    MarkEdit={MarkEdit}
                    MarkUpload={MarkUpload}
                    handleDragStart={handleDragStart}
                    handleMarkFileChange={handleMarkFileChange}
                    InputIcon={InputIcon}
                    toggleEdit={toggleEdit}
                  />
                )}
                {editableFields[field.id] === true && (
                  <div className="param-input-image mx-1 d-flex flex-row align-items-center">
                    <img
                      src={TickIcon}
                      className="param-image"
                      alt="tick-icon"
                      onClick={() => toggleDisableEdit(field.id, "tick")}
                      onDragStart={handleDragStart}
                    />
                    <img
                      src={CrossIcon}
                      className="param-image mx-1"
                      alt="cross-icon"
                      onClick={() => toggleDisableEdit(field.id, "cross")}
                      onDragStart={handleDragStart}
                    />
                  </div>
                )}
              </div>
              {field.id === "mark" && markFiles.length > 0 && (
                <RlTemplateMarkFilesList
                  markFiles={markFiles}
                  FileCloseIcon={FileCloseIcon}
                  handleRemoveMarkFile={handleRemoveMarkFile}
                />
              )}
              {field.id === "mark" && (
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", height: "auto" }}
                >
                  {markError}
                </span>
              )}
            </div>
          ))}
          <div className="d-flex mt-2 flex-column align-items-center justify-content-center">
            {isLoading === true ? "Extracting Data..." : ""}
          </div>
        </div>
        <RLTemplateParamButtons
          isLoading={isLoading}
          handleFetch={handleFetch}
          handleSave={handleSave}
        />
      </div>
    </>
  );
};

export default ParamSection;
