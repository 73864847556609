import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import SuccessCheck from "../../../assets/images/success.svg";
import Loading from "../../../assets/images/loading.png";
import { setIdsGenerateForm } from "../../../store/reducer/adminSlice";
import { useUpdateStatusMutation } from "../../../store/action/adminApi";
import { useUpdateEmptyStatusMutation } from "../../../store/action/adminApi";
import { revertAll } from "../../../store/reducer/searchSlice";
import { formatSavedDate } from "../../../util/utils";
import { useUploadXmlMutation } from "../../../store/action/generateApi";
import uploadToV1Server from "../../../store/action/uploadToV1Server";
const Popup = ({ onClose }) => {
  const [uploadXml] = useUploadXmlMutation();
  const number = useSelector((state) => state.search.number);
  const plainSearchNum = useSelector((state) => state.search.plainSearchNum);
  const docketNumber = useSelector((state) => state.search.docketNumber);
  const formattedIdsData = useSelector(
    (state) => state.search.formattedIdsData
  );
  const formattedNplData = useSelector(
    (state) => state.search.formattedNplData
  );
  const selectedNplData = useSelector((state) => state.search.selectedNplData);
  const reduxTopContentData = useSelector((state) => state.topContent.data);
  const headerData = useSelector((state) => state.admin.data);
  const finalNplData =
    selectedNplData.length === 0
      ? formattedNplData
      : selectedNplData.filter((item) => item.Selected === false).length ===
        selectedNplData.length
      ? selectedNplData
      : selectedNplData.filter((item) => item.Selected === true);
  const usPatentData = formattedIdsData.filter(
    (item) => item["IDS Form Section Heading"] === "U.S.PATENTS"
  );
  const usPatentPubData = formattedIdsData.filter(
    (item) =>
      item["IDS Form Section Heading"] === "U.S.PATENT APPLICATION PUBLICATIONS"
  );
  const foreignPatentData = formattedIdsData.filter(
    (item) => item["IDS Form Section Heading"] === "FOREIGN PATENT DOCUMENTS"
  );
  const uploadedNplData = formattedIdsData.filter(
    (item) => item["IDS Form Section Heading"] === "NON-PATENT LITERATURE"
  );
  const generateXml = () => {
    let xml = `<us-ids date-produced="20050902" dtd-version="v20_EFSWeb" file="" lang="" status="">\n`;
    xml += `<us-filing-info>\n`;
    xml += `<us-application-identification-info>\n`;
    xml += `<doc-number>${
      Object.keys(reduxTopContentData).length > 0
        ? reduxTopContentData["serial_num"]
        : headerData.serial_num
    }</doc-number>\n`;
    xml += `<date>${
      Object.keys(reduxTopContentData).length > 0
        ? formatSavedDate(reduxTopContentData["filing_date"])
        : headerData?.filling_date !== "N/A"
        ? formatSavedDate(headerData?.filling_date)
        : "N/A"
    }</date>\n`;
    xml += `</us-application-identification-info>\n`;
    xml += `<file-reference-id>${
      Object.keys(reduxTopContentData).length > 0
        ? reduxTopContentData["docket_num"]
        : headerData.docket_num
    }</file-reference-id>\n`;
    xml += `<us-first-named-inventor>\n`;
    xml += `<name name-type="">${
      Object.keys(reduxTopContentData).length > 0
        ? reduxTopContentData["applicant_name"]?.replace(/\s+/g, " ")
        : headerData?.applicants?.replace(/\s+/g, " ")
    }</name>\n`;
    xml += `</us-first-named-inventor>\n`;
    xml += `</us-filing-info>\n`;
    xml += `<us-patent-cite>\n`;
    usPatentData.forEach((data, index) => {
      xml += `<us-doc-reference id="" num="" sequence="">\n`;

      xml += `<doc-number>${data["References"].slice(2)}</doc-number>\n`;

      xml += `<name name-type="">${data["Patentee Name"]}</name>\n`;

      xml += `<date>${data["Issued / Pub. Date"]}</date>\n`;

      xml += `<kind>${data["Kind Code"]}</kind>\n`;

      xml += `</us-doc-reference>\n`;
    });
    xml += `</us-patent-cite>\n`;
    xml += `<us-pub-appl-cite>\n`;
    usPatentPubData.forEach((data, index) => {
      xml += `<us-doc-reference id="" num="" sequence="">\n`;

      xml += `<doc-number>${data["References"].slice(2)}</doc-number>\n`;

      xml += `<name name-type="">${data["Patentee Name"]}</name>\n`;

      xml += `<date>${data["Issued / Pub. Date"]}</date>\n`;

      xml += `<kind>${data["Kind Code"]}</kind>\n`;

      xml += `</us-doc-reference>\n`;
    });
    xml += `</us-pub-appl-cite>\n`;
    if (foreignPatentData.length !== 0) {
      xml += `<us-foreign-document-cite>\n`;
      foreignPatentData.forEach((data, index) => {
        xml += `<us-foreign-doc-reference id="" num="" sequence="">\n`;

        xml += `<country>${data["References"].slice(0, 2)}</country>\n`;

        xml += `<doc-number>${
          data["References"].length === 13
            ? data["References"].slice(2)
            : data["References"].slice(2)
        }</doc-number>\n`;

        xml += `<name name-type="">${data["Patentee Name"]}</name>\n`;

        xml += `<date>${data["Issued / Pub. Date"]}</date>\n`;

        xml += `<kind>${data["Kind Code"]}</kind>\n`;

        xml += `</us-foreign-doc-reference>\n`;
      });
      xml += `</us-foreign-document-cite>\n`;
    }
    uploadedNplData.forEach((data, index) => {
      xml += `<us-nplcit file="" id="" medium="" num="" sequence="" translation-attached="no" type="" url="">\n`;

      xml += `<text>${data["References"]}</text>\n`;

      xml += `</us-nplcit>\n`;
    });
    finalNplData.forEach((data, index) => {
      xml += `<us-nplcit file="" id="" medium="" num="" sequence="" translation-attached="no" type="" url="">\n`;

      xml += `<text>${data["References"]}</text>\n`;

      xml += `</us-nplcit>\n`;
    });
    xml += `</us-ids>`;
    return xml;
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateStatus] = useUpdateStatusMutation();
  const [updateEmptyStatus] = useUpdateEmptyStatusMutation();
  const handleUpdateStatus = async () => {
    const updateStatusObject = { status: "COMPLETED" };
    const userUpdateNumber = sessionStorage.getItem("userId");
    if (number === "" || typeof number === "undefined") {
      await updateEmptyStatus({
        userId: userUpdateNumber,
        docketNum: docketNumber,
        data: updateStatusObject,
      });
      return;
    }
    await updateStatus({
      id: plainSearchNum?.replace(/\s+/g, "").replace(/[\/,]/g, ""),
      data: updateStatusObject,
      userId: userUpdateNumber,
    });
  };
  const uploadToGenerator = async () => {
    const xmlContent = generateXml();
    const userNumber = parseInt(sessionStorage.getItem("userId"), 10);
    await uploadXml({
      data: xmlContent,
      uid: userNumber,
      pubNumber:
        number === "" || typeof number === "undefined" ? docketNumber : number,
    });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
      dispatch(setIdsGenerateForm(true));
      handleUpdateStatus();
      uploadToGenerator();
      setTimeout(
        () =>
          uploadToV1Server(
            number === "" || typeof number === "undefined"
              ? docketNumber
              : number
          ),
        2000
      );
      alert("Your PDF has been generated, please download from dashboard");
      dispatch(revertAll());
      navigate("/dashboard");
    }, 2000);
    return () => clearTimeout(timer);
  }, [onClose, navigate]);
  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="message">
          <span>Generate successfully</span>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div className="submessage generate-message pb-2">Please wait</div>
        <div className="">
          <img src={Loading} className="loading-icon" alt="Loading" />
        </div>
      </div>
    </div>
  );
};

export default Popup;
