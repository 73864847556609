import React from "react";
import { useNavigate } from "react-router-dom";
import {
  setIsLetterPopupOpen,
  revertCreateLetter,
} from "../../../store/reducer/createLetterSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateLetterPopup from "../../../components/RL/popup/create-letter-popup";
import IDSImage from "../../../assets/images/welcome-ids.png";
import ReportImage from "../../../assets/images/welcome-reporting-letter.png";
import OfficeImage from "../../../assets/images/welcome-office-action.png";
import FilingImage from "../../../assets/images/welcome-filing-package.png";
import ProofImage from "../../../assets/images/welcome-proof-reading.png";
import PostImage from "../../../assets/images/welcome-post-allowance.png";
import DocketingImage from "../../../assets/images/welcome-patent-docketing.png";
import TrademarkImage from "../../../assets/images/welcome-trademark-docketing.png";
import "./welcome.scss";
const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLetterPopupOpen = useSelector(
    (state) => state.createLetter.isLetterPopupOpen
  );
  const dtUserName = useSelector((state) => state.rlAdmin.dtUserName);
  const handleIDSClick = () => {
    if (sessionStorage.getItem("accessToken")) {
      navigate("/dashboard");
      return;
    }
    navigate("/login");
  };
  const handleReportClick = () => {
    if (sessionStorage.getItem("accessToken")) {
      navigate("/rl-dashboard");
      return;
    }
    navigate("/login");
  };
  const onCloseLetterPopup = () => {
    dispatch(setIsLetterPopupOpen(false));
    dispatch(revertCreateLetter());
  };
  const cardData = [
    {
      title: "Information Disclosure Statement",
      imgSrc: IDSImage,
      alt: "ids",
      onClick: handleIDSClick,
      toolClass: "tool-card mb-2",
      cardClass: "col d-flex flex-row align-items-center card-welcome-content",
      cardTitleClass: "card-title ids-title",
      cardImageClass: "card-image",
    },
    {
      title: "Reporting Letter",
      imgSrc: ReportImage,
      alt: "reporting-letter",
      onClick: handleReportClick,
      toolClass: "tool-card mx-2 mb-2",
      cardClass: "col d-flex flex-row align-items-center card-welcome-content",
      cardTitleClass: "card-title",
      cardImageClass: "card-image",
    },
    {
      title: "Office Action Response",
      imgSrc: OfficeImage,
      alt: "office-action",
      onClick: () => {},
      toolClass: "tool-card disabled-tool-card mx-2 mb-2",
      cardClass: "col d-flex flex-row align-items-center card-welcome-content",
      cardTitleClass: "card-title disabled-card-title oa-title",
      cardImageClass: "card-image card-image-disabled ",
    },
    {
      title: "Filing Package",
      imgSrc: FilingImage,
      alt: "Filing Package",
      onClick: () => {},
      toolClass: "tool-card disabled-tool-card mx-2 mb-2",
      cardClass: "col d-flex flex-row align-items-center card-welcome-content",
      cardTitleClass: "card-title disabled-card-title",
      cardImageClass: "card-image card-image-disabled",
    },
    {
      title: "Patent Docketing",
      imgSrc: DocketingImage,
      alt: "Patent Docketing",
      onClick: () => {},
      toolClass: "tool-card disabled-tool-card mb-2",
      cardClass: "col d-flex flex-row align-items-center card-welcome-content",
      cardTitleClass: "card-title disabled-card-title",
      cardImageClass: "card-image card-image-disabled",
    },
    {
      title: "Post Allowance Review",
      imgSrc: PostImage,
      alt: "Post Allowance",
      onClick: () => {},
      toolClass: "tool-card disabled-tool-card mx-2 mb-2",
      cardClass: "col d-flex flex-row align-items-center card-welcome-content",
      cardTitleClass: "card-title disabled-card-title post-title",
      cardImageClass: "card-image card-image-disabled",
    },
    {
      title: "Proofreading",
      imgSrc: ProofImage,
      alt: "Proof Reading",
      onClick: () => {},
      toolClass: "tool-card disabled-tool-card mx-2 mb-2",
      cardClass: "col d-flex flex-row align-items-center card-welcome-content",
      cardTitleClass: "card-title disabled-card-title",
      cardImageClass: "card-image card-image-disabled",
    },
    {
      title: "Trademark Docketing",
      imgSrc: TrademarkImage,
      alt: "Trademark",
      onClick: () => {},
      toolClass: "tool-card disabled-tool-card mx-2 mb-2",
      cardClass: "col d-flex flex-row align-items-center card-welcome-content",
      cardTitleClass: "card-title disabled-card-title trademark-title",
      cardImageClass: "card-image card-image-disabled ",
    },
  ];

  const welcomeText = `Now, you can streamline your processes, maintain accurate records, and achieve timely compliance, all 
  in one platform. This docketing tool helps you stay organized and ensures that you never miss critical 
  deadlines. It efficiently tracks important dates, filings, and case activities, making it easier for you to 
  manage legal and intellectual property matters.`;

  return (
    <div className="welcome-container">
      <div className="d-flex flex-column align-items-start ps-5 welcome-title-section">
        <div className="d-flex flex-column welcome-header">
          <div className="welcome-title">
            Welcome{" "}
            <span className="welcome-user-title ms-2" title={dtUserName}>
              {dtUserName}
            </span>
          </div>
          <div className="welcome-text">{welcomeText}</div>
        </div>
        <div className="container py-3 card-container d-flex flex-column justify-content-center">
          <div className="row row-cols-4 d-flex flex-row justify-content-center card-row">
            {cardData.map((card, index) => (
              <div
                key={index}
                className={`card ${card.toolClass}`}
                title={`Go to ${card.title}`}
                onClick={card.onClick}
              >
                <div className={card.cardClass}>
                  <img
                    src={card.imgSrc}
                    className={card.cardImageClass}
                    alt={card.alt}
                  />
                  <div className="card-body">
                    <h5 className={card.cardTitleClass}>{card.title}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
