import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./ucr.scss";
import UcrParamSection from "../../../components/RL/ucr-sections/ucr-param-section";
import UcrEmailSection from "../../../components/RL/ucr-sections/ucr-email-section";

const UCR = () => {
  return (
    <div className="ucr-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">Certificate of US TM Registration</div>
        <div className="d-flex flex-row">
          <UcrParamSection />
          <UcrEmailSection />
        </div>
      </div>
    </div>
  );
};

export default UCR;
