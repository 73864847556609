import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./dashboard.scss";
import FormBack from "../../assets/images/dashboard-back-button.png";
import SearchInForm from "../../assets/images/search-in-from.svg";
import Table from "../../components/common/dashboard-table";
import Button from "../../components/common/button";
import { useGetSavedDataByUserQuery } from "../../store/action/adminApi";
import { formatSavedDate } from "../../util/utils";
import SelectPopup from "../../components/popup/select-ids-popup";
import SharePopup from "../../components/popup/send-on-mail-popup";
import {
  setSelectIdsToggle,
  setSharePopupToggle,
} from "../../store/reducer/adminSlice";
import { formatCurrentDate } from "../../util/utils";
const Dashboard = () => {
  const number = useSelector((state) => state.search.number);
  const selectIdsToggle = useSelector((state) => state.admin.selectIdsToggle);
  const sharePopupToggle = useSelector((state) => state.admin.sharePopupToggle);
  const userNumber = sessionStorage.getItem("userId");
  const { data } = useGetSavedDataByUserQuery({ userId: userNumber });
  const dispatch = useDispatch();
  const adminData = data ? [...data] : [];
  const headers = [
    "Sr.No",
    "Patent Family",
    "Attorney Docket No.",
    "Type",
    "Date",
    // "Email",
    "Status",
    "Action",
  ];
  const navigate = useNavigate();
  const defaultRow = headers.reduce(
    (acc, header) => ({ ...acc, [header]: "" }),
    {}
  );
  const handleClick = () => {
    navigate("/search");
  };
  const handleResumeClick = () => {
    navigate("/ids");
  };
  const handleSelectClose = () => {
    dispatch(setSelectIdsToggle(false));
  };
  const handleShareClose = () => {
    dispatch(setSharePopupToggle(false));
  };
  const formattedAdminData = adminData.map((member) => {
    return {
      Id_num: member.Id_num,
      "Patent Family": member.family_mem,
      "Attorney Docket No.": member.docket_num,
      Type: member.ids_type,
      Date: formatSavedDate(member.curr_date),
      Status: (
        <span
          className={`${
            member.status === "COMPLETED"
              ? "status-text-green"
              : "status-text-red"
          }`}
        >
          {member.status}
        </span>
      ),
    };
  });
  const formattedSrAdminData = formattedAdminData.map((item, index) => ({
    ...item,
    "Sr.No": index + 1,
  }));
  const defaultData = Array.from({ length: 12 }, () => defaultRow);
  return (
    <div className="dashboard-container">
      {selectIdsToggle === true && <SelectPopup onClose={handleSelectClose} />}
      {sharePopupToggle === true && <SharePopup onClose={handleShareClose} />}
      <div className="card card-2">
        <div className="tab-container d-flex">
          <div className="dashboard-title">Dashboard</div>
          <div className="icons">
            {number !== "" && (
              <Button
                className={"create-ids-btn"}
                children="Resume IDS"
                onClick={handleResumeClick}
                title="Resume Previous IDS"
              />
            )}
            <Button
              className={"create-ids-btn"}
              children="Create IDS"
              onClick={handleClick}
              title="Create New IDS"
            />
            <img
              src={SearchInForm}
              alt="Search In Form"
              className="icon"
              title="Search among the Cases"
            />
            {/* <img src={FormBack} alt="Form Back" className="icon" /> */}
          </div>
        </div>
        <Table headers={headers} data={formattedSrAdminData} />
      </div>
      <div className="d-flex flex-row login-stamp">
        <span className="login-stamp-text">Last Login Time stamp:</span> &nbsp;
        {formatCurrentDate()}
      </div>
    </div>
  );
};

export default Dashboard;
