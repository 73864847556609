import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    idsGenerate: false,
};

const generateSlice = createSlice({
    name: "generate",
    initialState,
    reducers: {
        setIdsGenerate: (state, action) => {
            state.idsGenerate = action.payload;
        },
    },
});
export const { setIdsGenerate } = generateSlice.actions;
export default generateSlice.reducer;
