import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./cancel-template-popup.scss";
import CloseIcon from "../../../assets/images/create-letter-close.png";
import CancelPopupIcon from "../../../assets/images/cancel-template-popup-icon.png";
import Button from "../../common/button";
import {
  setIsCanTempPopupOpen,
  setIsSaveTempPopupOpen,
  revertTemplate,
} from "../../../store/reducer/templateSlice";
import {
  setLetterRadioOption,
  setSelectOptions,
  setIsLetterPopupOpen,
  revertCreateLetter,
} from "../../../store/reducer/createLetterSlice";
const CancelTemplatePopup = ({ onClose }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIsCanTempPopupOpen(false));
    dispatch(setLetterRadioOption("manual"));
    dispatch(setSelectOptions(false));
    dispatch(revertTemplate());
    dispatch(revertCreateLetter());
    dispatch(setIsLetterPopupOpen(true));
    navigate("/welcome");
  };

  return (
    <div className="cancel-template-popup-overlay">
      <div className="cancel-template-popup d-flex flex-column align-items-center justify-content-center">
        <div className="message">
          <span>Cancel</span>
        </div>
        <div className="d-flex submessage py-2 flex-column align-items-center">
          <img src={CancelPopupIcon} className="cancel-icon" alt="Cancel" />
          <div className="cancel-temp-text py-3">
            Are You Sure You Want To Discard This Template?
          </div>
          <div className="d-flex flex-row cancel-temp-btn-container mt-3 justify-content-center py-2">
            <Button
              className={`cancel-temp-btn-cancel-style mx-2`}
              onClick={handleClick}
            >
              <span className="">Discard</span>
            </Button>
            <Button
              className={`cancel-temp-btn-close-style mx-2`}
              onClick={onClose}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelTemplatePopup;
