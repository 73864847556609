import React from "react";
import "../../../components/RL/common/rl-template-pages.scss";
import "./naf.scss";
import NafParamSection from "../../../components/RL/naf-sections/naf-param-section";
import NafEmailSection from "../../../components/RL/naf-sections/naf-email-section";

const NAF = () => {
  return (
    <div className="naf-template-container">
      <div className="d-flex flex-column ms-4">
        <div className="template-title">New Application Filing</div>
        <div className="d-flex flex-row">
          <NafParamSection />
          <NafEmailSection />
        </div>
      </div>
    </div>
  );
};

export default NAF;
