import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { familyBiblioApi } from '../action/familyBiblioApi';


export const saveFamilyData = createAsyncThunk(
  'family-biblio',
  async (data, thunkAPI) => {
    try {
      const response = await thunkAPI.dispatch(
        familyBiblioApi.endpoints.createManyFamilyBiblio.initiate(data)
      );

      return response;
    } catch (error) {
      console.error('API Call Failed:', error);
      return thunkAPI.rejectWithValue(error.message || 'Failed to save data');
    }
  }
);

const initialState = {
  data:[],
  familyBiblio: [],
  formattedFamilyData: [],
  loading: false,
  error: null,
};

const familySlice = createSlice({
  name: "family",
  initialState,
  reducers: {
    setFamilyBiblio: (state, action) => {
      state.familyBiblio = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setFormattedFamilyData: (state, action) => {
      state.formattedFamilyData = action.payload;
    },
    setClearData: (state) => {
      state.data = [];
    },
  },
});

export const { setFamilyBiblio, setLoading, setError, setFormattedFamilyData, setClearData } = familySlice.actions;

export default familySlice.reducer;
