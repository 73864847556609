import React from "react";

const UcrChangesSection = () => {
  return (
    <>
      <div className="d-flex flex-row email-content-section align-items-center">
        <div className="content-text text-only-size">
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            {" "}
            Changes in Registration and Third-Party Use by Others{" "}
          </span>
          <br></br>
          Changes in the name or form of the registered owner, or its address or
          other activity affecting title to ownership of the registration,
          should be recorded at the U.S. Patent and Trademark Office, as should
          any assignment of any right in the mark to another entity. If you
          intend to expand the use of your trademark by permitting a third party
          to use the mark, you should enter into a formal written licensing
          agreement covering the conditions of use of the mark and establishing
          your control over the use. The use of a trademark by a third party
          without a proper written license agreement can jeopardize your
          registration and trademark rights by destroying the distinctiveness of
          your mark.
          <br></br>
          Infringement by others occurs when your mark (or a confusingly similar
          mark) is used by a party which is not licensed to use the mark. It is
          deemed to occur when a party sells, distributes, or advertises good(s)
          in association with a trademark, service mark, or trade name which is
          confusingly similar to your mark. In order to preserve the
          distinctiveness of your mark, we recommend that you carefully monitor
          use of similar marks by others (for example, by reviewing trade
          literature to identify potential infringers) and take action to stop
          any infringement. If you believe that your mark may be infringed by
          another party’s use, we can review the third-party use and advise you
          about the alternative actions that may be taken.
        </div>
      </div>
    </>
  );
};

export default UcrChangesSection;
